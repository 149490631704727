import React, { useEffect, useCallback } from 'react'
import PageTitle from '@/components/common/PageTitle'
import ActivityItem from './components/ActivityItem'
import ActivityItemPC from './components/ActivityItemPC'
import { useNavigate } from 'react-router-dom'
import i18Next from '@/i18n'
import { ROLE_USER, ROLE_TYPE } from '@/utils/constants'
import { SCREEN_SIZE } from '@/utils/screenSize'
import { useMediaQuery } from 'react-responsive'
import { ROUTE } from '@/router/config'

const HomeView: React.FC = () => {
  const navigate = useNavigate()

  const activites = [
    {
      type: 'lesson',
      text: i18Next.t('home.onlineLesson'),
      isActive: true
    },
    {
      type: 'videos',
      text: i18Next.t('home.learnByVideo'),
      isActive: true
    },
    {
      type: 'test',
      text: i18Next.t('home.miniTest'),
      isActive: true
    },
    {
      type: 'virtual',
      text: i18Next.t('home.virtualSchool'),
      isActive: true
    },
    {
      type: 'mypage',
      text: i18Next.t('home.mypage'),
      isActive: true
    },
    {
      type: 'message',
      text: i18Next.t('home.message'),
      isActive: true
    }
  ]

  const activitesWeb = [
    {
      type: 'lesson',
      text: i18Next.t('home.onlineLesson'),
      description: i18Next.t('home.onlineLessonDescription'),
      isActive: true
    },
    {
      type: 'videos',
      text: i18Next.t('home.learnByVideo'),
      description: i18Next.t('home.learnByVideoDescription'),
      isActive: true
    },
    {
      type: 'test',
      text: i18Next.t('home.miniTest'),
      description: i18Next.t('home.miniTestDescription'),
      isActive: true
    },
    {
      type: 'virtual',
      text: i18Next.t('home.virtualSchool'),
      description: i18Next.t('home.virtualSchoolDescription'),
      isActive: true
    },
    {
      type: 'submenu',
      text: i18Next.t('home.menu'),
      description: i18Next.t('home.menuDescription'),
      isActive: true
    }
  ]

  let user: any
  const _user = localStorage.getItem('user')
  if (_user) {
    user = JSON.parse(_user)
  }

  const activityOnClick = useCallback(
    type => {
      switch (type) {
        case 'videos':
          navigate(ROUTE.DEPARTMENT)
          break

        case 'virtual':
          navigate(ROUTE.SCHOOL)
          break

        case 'mypage':
          navigate(ROUTE.MY_PAGE)
          break

        case 'lesson':
          navigate(ROUTE.ONLINE_LESSON_DEPARTMENT)
          break

        case 'message':
          navigate(ROUTE.BULLETIN)
          break

        case 'test':
          navigate(ROUTE.DEPARTMENT_TEST)
          break

        default:
          break
      }
    },
    [activites]
  )

  const guardianUser = user?.permissions
  const guardianActivites = [
    {
      id: 1,
      type: 'videos',
      text: i18Next.t('home.learnByVideo'),
      description: i18Next.t('home.learnByVideoDescription'),
      isActive: true,
      isHidden: guardianUser?.WATCH_VIDEO
    },
    {
      id: 2,
      type: 'submenu',
      text: i18Next.t('home.mypage'),
      description: i18Next.t('home.menuDescription'),
      isActive: true,
      isHidden: guardianUser?.MY_PAGE
    }
  ]
  const height = window.innerHeight - 140

  useEffect(() => {
    const isAuth = localStorage.getItem('access_token')
    if (isAuth && isAuth !== 'undefined') {
      navigate('/')
    }
  }, [])

  const userType = user?.role?.type
  const userRole = user?.role?.authority
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })
  if (isMobile) {
    return (
      <div className='content-wrapper background-grey' style={{ height }}>
        <PageTitle icon='home-icon' title='ホーム' />
        <div className='list-wrapper activities-list px5 mb4'>
          <div className='box'>
            {userType == ROLE_TYPE.USER
              ? activites.map((item, index) =>
                  userRole !== ROLE_USER.USER_VIRTUAL ? (
                    <ActivityItem
                      key={index}
                      type={item.type}
                      text={`${item.text}`}
                      handleClick={activityOnClick}
                      customClass={!item.isActive ? 'inactive' : ''}
                      customHidden={''}
                    />
                  ) : (
                    <ActivityItem
                      key={index}
                      type={item.type}
                      text={`${item.text}`}
                      handleClick={activityOnClick}
                      customClass={!item.isActive ? 'inactive' : ''}
                      customHidden={
                        item.type !== 'virtual' && item.type !== 'message' ? 'hidden' : ''
                      }
                    />
                  )
                )
              : userType == ROLE_TYPE.GUARDIAN
              ? guardianActivites.map((item, index) => (
                  <ActivityItem
                    key={index}
                    type={item.id == 2 ? 'mypage' : 'videos'}
                    text={`${item.text}`}
                    handleClick={activityOnClick}
                    customClass={!item.isActive ? 'inactive' : ''}
                    customHidden={item.isHidden == true ? '' : 'hidden'}
                  />
                ))
              : activites.map((item, index) => (
                  <ActivityItem
                    key={index}
                    type={item.type}
                    text={`${item.text}`}
                    handleClick={activityOnClick}
                    customClass={!item.isActive ? 'inactive' : ''}
                    customHidden={''}
                  />
                ))}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className='content-wrapper background-grey'
        style={{
          height:
            userType == ROLE_TYPE.GUARDIAN || userRole == ROLE_USER.USER_VIRTUAL
              ? 'calc(100vh + 150px)'
              : '',
          marginBottom: 70
        }}
      >
        <PageTitle icon='home-icon' title='ホーム' />
        <div className='list-wrapper activities-list px5 mb4'>
          <div className='box-web'>
            {userType == ROLE_TYPE.USER
              ? activitesWeb.map((item, index) =>
                  userRole !== ROLE_USER.USER_VIRTUAL ? (
                    <ActivityItemPC
                      key={index}
                      type={item.type}
                      text={`${item.text}`}
                      description={`${item.description}`}
                      customClass={!item.isActive ? 'inactive' : ''}
                      customHidden={''}
                    />
                  ) : (
                    <ActivityItemPC
                      key={index}
                      type={item.type}
                      text={`${item.text}`}
                      description={`${item.description}`}
                      customClass={!item.isActive ? 'inactive' : ''}
                      customHidden={
                        item.type !== 'virtual' && item.type !== 'submenu' ? 'hidden' : ''
                      }
                    />
                  )
                )
              : userType == ROLE_TYPE.GUARDIAN
              ? guardianActivites.map((item, index) => (
                  <ActivityItemPC
                    key={index}
                    type={item.type}
                    text={`${item.text}`}
                    description={`${item.description}`}
                    customClass={!item.isActive ? 'inactive' : ''}
                    customHidden={item.isHidden == true ? '' : 'hidden'}
                  />
                ))
              : activitesWeb.map((item, index) => (
                  <ActivityItemPC
                    key={index}
                    type={item.type}
                    text={`${item.text}`}
                    description={`${item.description}`}
                    customClass={!item.isActive ? 'inactive' : ''}
                    customHidden={''}
                  />
                ))}
          </div>
        </div>
      </div>
    )
  }
}

export default HomeView
