import { Form, FormInstance } from 'antd'
import React, { useEffect, useState } from 'react'
import { Answer } from '../type'
import FillBlank from './FillBlank'
import { FormItemCheckBoxQuestion } from './FormItemCheckQuestion'
import styles from './index.module.scss'
import classNames from 'classnames'

interface IQuestionProps {
  question: Answer
  keyIdx: number
  testDetailId: number
  isShow: boolean
  form: FormInstance<any>
  // backStep: () => void
  nextStep: (questionSelected?: any) => void
  notShowExplain?: boolean
  isTest?: boolean
  dataSubmit?: any
  oldAnswer?: any
}

const Question: React.FunctionComponent<IQuestionProps> = ({
  keyIdx,
  question,
  isShow,
  testDetailId,
  nextStep,
  // backStep,
  notShowExplain,
  dataSubmit,
  form,
  oldAnswer
}) => {
  const [showCorrectOption, setShowCorrectOption] = useState(false)
  const valueAnswer = Form.useWatch([`question_${testDetailId}`])
  const isSelectedAnswer = valueAnswer?.filter(Boolean)

  useEffect(() => {
    if (oldAnswer) {
      form.setFieldValue(
        Object.keys(oldAnswer),
        Object.values(oldAnswer)?.map((it: any) => it)?.[0]
      )
    }
  }, [oldAnswer, form, isShow])

  useEffect(() => {
    if (dataSubmit) {
      setShowCorrectOption(true)
    }
  }, [dataSubmit])

  if (!isShow) return <></>

  return (
    <div className={styles.questionWrap}>
      <div className='index-que'>Q{keyIdx}</div>
      <div className='overall-question mb-4'>
        {question.title && (
          <div
            className={styles.contentQuestion}
            dangerouslySetInnerHTML={{
              __html: question.title.split('\n')?.join('<br />')
            }}
          />
        )}
        {question.file &&
          (question.question_format === 'SELECT_4_OPTIONS_WITH_AUDIO' ? (
            <audio controls>
              <source src={question.file} type='audio/mpeg' />
            </audio>
          ) : (
            <img src={question.file} alt='' />
          ))}

        {question.question_format !== 'ENGLISH_TYPING_PROBLEM' && (
          <div className={styles.listAnswer}>
            {question.answers.map((item, idx) => (
              <div key={idx} style={{ display: 'flex', fontSize: '16px' }}>
                <div>{idx + 1}.&nbsp;</div>
                <div style={{ textAlign: 'left' }}>{item}</div>
              </div>
            ))}
          </div>
        )}
      </div>

      <Form.Item name={`question_${testDetailId}`} preserve>
        {question.question_format === 'ENGLISH_TYPING_PROBLEM' ? (
          <FillBlank
            question={question}
            textAnswer={question.correct_answer_length}
            showCorrectOption={showCorrectOption}
          />
        ) : (
          <FormItemCheckBoxQuestion showCorrectOption={showCorrectOption} question={question} />
        )}
      </Form.Item>

      <div className={styles.btnActions}>
        {/* {keyIdx !== 1 ? (
          <button className={styles.btn} onClick={backStep}>
            <img src={process.env.PUBLIC_URL + '/images/back.svg'} alt='' />
            <div>&nbsp;戻る</div>
          </button>
        ) : ( */}
        <div />
        {/* )} */}

        <button
          className={classNames(styles.btn, !isSelectedAnswer?.length && styles.block)}
          onClick={() => {
            // if (question.question_format === 'ENGLISH_TYPING_PROBLEM') {
            //   nextStep(question)
            //   return
            // }

            if (!notShowExplain) {
              showCorrectOption ? nextStep(question) : setShowCorrectOption(true)
            } else {
              nextStep(question)
            }
          }}
          disabled={!isSelectedAnswer?.length}
        >
          <div>{showCorrectOption ? '次' : '解答'}&nbsp;</div>
          <img src={process.env.PUBLIC_URL + '/images/next.svg'} alt='' />
        </button>
      </div>
    </div>
  )
}

export default Question
