import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import PageTitle from '@/components/common/PageTitle'
import { Button, Spin, Tooltip } from 'antd'
import { useLocation } from 'react-router-dom'
import { updateVideoView, updateFavoriteVideo } from '@/stores/videoStore'
import { updatePlayedTime } from '@/stores/videoDetailStore'
import { useAppDispatch } from '@/app/hooks'
import { StarFilled } from '@ant-design/icons'
import { UpdatePayload } from '@/interface/videoInterface'
import * as API from '@/api/videoAPI'
import _ from 'lodash'
import { API_BASE_URL, ACCESS_TOKEN } from '@/utils/constants'

let playedTime = 0
let videoTime: number | undefined = 0
let oldPlayedTime = 0
let watchedVideo = 0
const VideoDetailView: React.FC = () => {
  const location = useLocation()
  const videoId = location.state.videoId
  const dispatch = useAppDispatch()
  const playerRef = useRef<ReactPlayer>(null)

  const [firstLoad, setFirstLoad] = useState(true)
  const [videoDetail, setVideoDetail] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const onReady = useCallback(() => {
    if (videoDetail?.id == videoId && firstLoad) {
      setFirstLoad(false)
      playerRef?.current?.seekTo(videoDetail?.played_time, 'seconds')
      videoTime = playerRef.current?.getDuration()
      watchedVideo = videoDetail?.is_viewed
    }
  }, [videoDetail?.played_time, firstLoad])

  const getDetail = async () => {
    setLoading(true)
    const res = await API.getVideoDetail(videoId)
    setVideoDetail(res)
    setLoading(false)
  }

  const savePlayedTime = () => {
    if (playedTime && videoTime && videoId && oldPlayedTime != playedTime) {
      let watched = watchedVideo
      const processPercent = Math.round((playedTime / videoTime) * 100)
      if (!watched && processPercent >= 50) watched = 1
      const data = {
        id: videoId,
        data: {
          playedTime: Math.round(playedTime),
          processPercent,
          watched
        }
      }
      oldPlayedTime = playedTime
      dispatch(updatePlayedTime(data))
    }
  }

  const downloadAttachment = item => {
    const token = localStorage.getItem(ACCESS_TOKEN)
    const baseURLString = API_BASE_URL + '/api'
    fetch(`${baseURLString}/download/${item.id}}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`
      })
    }).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob)
        // Setting various property values
        const alink = document.createElement('a')
        alink.href = fileURL
        alink.download = item.name
        alink.click()
        alink?.parentNode?.removeChild(alink)
      })
    })
  }

  const resetValue = () => {
    playedTime = 0
    videoTime = 0
    oldPlayedTime = 0
    watchedVideo = 0
  }

  const updateViewWhenPlay = () => {
    if ((videoTime && Math.floor(playedTime) == Math.floor(videoTime)) || playedTime == 0) {
      dispatch(updateVideoView(videoId))
    }
  }

  const onLikeBtnClick = e => {
    e.stopPropagation()
    let favoriteLoading = false
    if (favoriteLoading) return
    try {
      const favoriteData: UpdatePayload = {
        id: videoDetail.id,
        data: {
          isFavorite: videoDetail.is_favorited ? 0 : 1
        }
      }
      dispatch(updateFavoriteVideo(favoriteData)).then(() => {
        favoriteLoading = true
        const newData: any = _.cloneDeep(videoDetail)
        // eslint-disable-next-line camelcase
        newData.is_favorited = newData.is_favorited ? 0 : 1

        if (videoDetail?.is_favorited) {
          if (newData.favorite_count) newData.favorite_count--
        } else {
          newData.favorite_count++
        }
        setVideoDetail(newData)
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const progressPlayedTime = progress => {
    playedTime = progress.playedSeconds
    if (!watchedVideo && videoTime) {
      const processPercent = Math.round((playedTime / videoTime) * 100)
      if (processPercent >= 50) {
        const data = {
          id: videoId,
          data: {
            playedTime: Math.round(playedTime),
            processPercent,
            watched: 1
          }
        }
        oldPlayedTime = playedTime
        dispatch(updatePlayedTime(data)).then(() => (watchedVideo = 1))
      }
    }
  }

  useEffect(() => {
    resetValue()
    getDetail()
    // save played time each 10 second
    const interval = setInterval(() => savePlayedTime(), 10000)
    return function () {
      resetValue()
      clearInterval(interval)
    }
  }, [])

  return (
    <div className='content-wrapper background-pink' style={{ height: '100%' }}>
      <PageTitle icon='videos-icon' title='動画で学ぶ' />
      <Spin spinning={loading}>
        <div className='video-detail-wrapper mt2'>
          <div className='box'>
            <div style={{ width: '100%', opacity: videoDetail?.id == videoId ? 1 : 0 }}>
              <ReactPlayer
                key={videoDetail?.id}
                ref={playerRef}
                url={videoDetail?.url}
                width='100%'
                height='100%'
                config={{ file: { attributes: { controlsList: 'nodownload', playsInline: true } } }}
                controls={true}
                onProgress={progress => progressPlayedTime(progress)}
                onReady={onReady}
                onPlay={updateViewWhenPlay}
              />
            </div>
            <div className='actions'>
              <Tooltip placement='leftTop' title={`いいね ${videoDetail?.favorite_count}`}>
                <StarFilled
                  className={videoDetail?.is_favorited ? 'active' : ''}
                  style={{ fontSize: 17 }}
                  onClick={e => onLikeBtnClick(e)}
                />
              </Tooltip>
              <div className={`eye-icon viewed ${videoDetail?.is_viewed ? 'active' : ''}`} />
            </div>
            {videoDetail?.chapter_name && (
              <div className='department-name'>
                {`${videoDetail.chapter_name} ${videoDetail.order}`}
              </div>
            )}
            <div className='video-name'>{videoDetail?.title}</div>
            <div className='document-list'>
              {videoDetail?.attachments.map((item, index) => (
                <div key={index} className='doc-item'>
                  <span className='txt-name'>{item.name}</span>
                  <Button onClick={() => downloadAttachment(item)}>ダウンロード</Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default VideoDetailView
