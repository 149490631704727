import React, { useCallback } from 'react'
import PageTitle from '@/components/common/PageTitle'
import LessonEnTitle from '@/components/common/LessonTitle/LessonEn'
import i18Next from '@/i18n'
import { Spin } from 'antd'
import { useAppSelector } from '@/app/hooks'

export const OnlineLessonEnView: React.FC = () => {
  const { loading } = useAppSelector(state => state.subject)
  const height = window.innerHeight - 140

  const activity = [
    {
      type: 'online-lesson-en',
      text: i18Next.t('lesson.onlineLessonEn'),
      isActive: true
    }
  ]

  const lessonOnClick = useCallback(() => window.open('https://member.class-live.com/login'), [])

  return (
    <div className='content-wrapper background-blue' style={{ height }}>
      <PageTitle icon='lesson-icon' title='オンラインレッスン' />
      <Spin spinning={loading}>
        <div className='lesson-wrapper lesson-list px5'>
          <div className='box'>
            {activity.map((item, index) => (
              <LessonEnTitle
                key={index}
                type={item.type}
                text={`${item.text}`}
                handleClick={lessonOnClick}
              />
            ))}
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default OnlineLessonEnView
