import React, { useEffect, useState } from 'react'
// import PageTitle from '@/components/common/PageTitle'
import ListItemWeb from '@/components/common/ListItemWeb'
import { useNavigate } from 'react-router-dom'
import { getDepartments } from '@/stores/departmentStore'
import { getSubjects } from '@/stores/subjectStore'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { Spin } from 'antd'

const VideoDepartments: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { departmentList, loading } = useAppSelector(state => state.department)
  const { subjectsList } = useAppSelector(state => state.subject)
  const type = 'videos'
  const [active, setActive] = useState(0)
  const departmenItemOnClick = id => {
    navigate('/', {
      state: {
        departmentId: id
      }
    })
    setActive(id)
    dispatch(getSubjects(''))
  }

  useEffect(() => {
    dispatch(getDepartments())
  }, [])

  const subjectItemOnclick = id => {
    navigate('/chapters', {
      state: {
        subjectId: id
      }
    })
  }

  return (
    <>
      {active == 0 && (
        <Spin spinning={loading}>
          <div className='sub-activities-list-wrapper px5'>
            <div className='box-web scroll'>
              {departmentList.map((item, index) => (
                <ListItemWeb
                  key={index}
                  type={type}
                  item={item}
                  schoolName={departmentList?.length == 1 ? '' : item?.school?.name}
                  handleClick={departmenItemOnClick}
                  customClass=''
                  list={getSubjects.length}
                />
              ))}
            </div>
          </div>
        </Spin>
      )}
      {active !== 0 && (
        <Spin spinning={loading}>
          <div className='sub-activities-list-wrapper px5'>
            {/* <div
              className={`box-web ${
                subjectsList?.filter(s => s.department_id == active).length > 3 ? 'scroll' : ''
              }`}
            > */}
            <div
              className={`box-web scroll
              `}
            >
              {subjectsList
                ?.filter(s => s.department_id == active)
                .map((item, index) => (
                  <ListItemWeb
                    key={index}
                    type={type}
                    item={item}
                    // schoolName={item?.school?.name}
                    handleClick={subjectItemOnclick}
                    hideName={true}
                    customClass='img-custom'
                    list={subjectsList.length}
                  />
                ))}
            </div>
          </div>
        </Spin>
      )}
    </>
  )
}

export default VideoDepartments
