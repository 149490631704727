import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  openModal: false
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    changeOpenModal: (state, action) => {
      state.openModal = action.payload
    }
  }
})

export const { changeOpenModal } = commonSlice.actions

export default commonSlice.reducer
