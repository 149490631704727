import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { userRole } from '@/components/common/Permission/WithPermission'
import { ROLE_USER, ROLE_TYPE } from '@/utils/constants'
import { ROUTE } from '@/router/config'

const BottomMenu: React.FC = () => {
  const [activeItem, setActiveItem] = useState('home')
  const navigate = useNavigate()
  const location = useLocation()

  let user: any
  const _user = localStorage.getItem('user')
  if (_user) {
    user = JSON.parse(_user)
  }
  const data = userRole()

  const onClickItem = url => {
    navigate(url)
  }
  useEffect(() => {
    if (location) {
      if (location.pathname == '/') setActiveItem('home')
      const videoStack = ['/departments', '/subjects', '/videos', '/video-detail']
      const pathNameTest = [
        ROUTE.MINI_TEST,
        ROUTE.VIDEO_TEST,
        ROUTE.CHAPTER_TEST,
        ROUTE.SUBJECT_TEST,
        ROUTE.DEPARTMENT_TEST
      ]

      if (pathNameTest.includes(location.pathname)) setActiveItem('mini-test')
      if (videoStack.includes(location.pathname)) setActiveItem('videos')
      if (location.pathname == '/mypage') setActiveItem('mypage')
      if (location.pathname == '/contact') setActiveItem('contact')

      const lessonStack = [
        '/online-lesson-departments',
        '/online-lesson',
        '/online-lesson-participation'
      ]
      if (lessonStack.includes(location.pathname)) setActiveItem('lesson')

      const lessonArchiveStack = ['/online-lesson', '/online-lesson-participation']
      if (lessonArchiveStack.includes(location.pathname)) setActiveItem('lesson')
    }
  })

  const userType = user?.role?.type
  return userType == ROLE_TYPE.USER ? (
    data !== ROLE_USER.USER_VIRTUAL ? (
      userType == ROLE_TYPE.STAFF ? (
        <div className='bottom-menu-wrapper'>
          <div className='icon-wrapper' onClick={() => onClickItem('/')}>
            <span className={`home-icon menu-icon ${activeItem == 'home' ? 'active' : ''}`} />
            <div className='text'>ホーム</div>
          </div>
          <div className='icon-wrapper' onClick={() => navigate(ROUTE.DEPARTMENT_TEST)}>
            <span
              className={`mini-test-icon menu-icon ${activeItem == 'mini-test' ? 'active' : ''}`}
            />
            <div className='text'>小テスト</div>
          </div>
          <div className='icon-wrapper' onClick={() => onClickItem('/departments')}>
            <span className={`videos-icon menu-icon ${activeItem == 'videos' ? 'active' : ''}`} />
            <div className='text'>動画で学ぶ</div>
          </div>
          <div className='icon-wrapper'>
            <span
              className={`mypage-icon menu-icon ${activeItem == 'mypage' ? 'active' : ''}`}
              onClick={() => onClickItem('/mypage')}
            />
            <div className='text'>マイページ</div>
          </div>
        </div>
      ) : (
        <div className='bottom-menu-wrapper'>
          <div className='icon-wrapper' onClick={() => onClickItem('/')}>
            <span className={`home-icon menu-icon ${activeItem == 'home' ? 'active' : ''}`} />
            <div className='text'>ホーム</div>
          </div>
          <div className='icon-wrapper' onClick={() => navigate(ROUTE.DEPARTMENT_TEST)}>
            <span
              className={`mini-test-icon menu-icon ${activeItem == 'mini-test' ? 'active' : ''}`}
            />
            <div className='text'>小テスト</div>
          </div>
          <div className='icon-wrapper' onClick={() => onClickItem('/departments')}>
            <span className={`videos-icon menu-icon ${activeItem == 'videos' ? 'active' : ''}`} />
            <div className='text'>動画で学ぶ</div>
          </div>
          <div className='icon-wrapper'>
            <span
              className={`mypage-icon menu-icon ${activeItem == 'mypage' ? 'active' : ''}`}
              onClick={() => onClickItem('/mypage')}
            />
            <div className='text'>マイページ</div>
          </div>

          {/* Chat */}
          <div className='icon-wrapper'>
            <span
              className={`contact-chat-icon menu-icon ${activeItem == 'contact' ? 'active' : ''}`}
              onClick={() => onClickItem('/contact')}
            />
            <div className='text'>連絡帳</div>
          </div>
        </div>
      )
    ) : (
      <div className='bottom-menu-wrapper'>
        <div className='icon-wrapper' onClick={() => onClickItem('/')}>
          <span className={`home-icon menu-icon ${activeItem == 'home' ? 'active' : ''}`} />
          <div className='text'>ホーム</div>
        </div>
        <div className='icon-wrapper'>
          <span
            className={`contact-chat-icon menu-icon ${activeItem == 'contact' ? 'active' : ''}`}
            onClick={() => onClickItem('/contact')}
          />
          <div className='text'>連絡帳</div>
        </div>
      </div>
    )
  ) : userType == ROLE_TYPE.GUARDIAN ? (
    <div className='bottom-menu-wrapper'>
      <div className='icon-wrapper' onClick={() => onClickItem('/')}>
        <span className={`home-icon menu-icon ${activeItem == 'home' ? 'active' : ''}`} />
        <div className='text'>ホーム</div>
      </div>
      {user?.permissions?.WATCH_VIDEO == true ? (
        <div className='icon-wrapper' onClick={() => onClickItem('/departments')}>
          <span className={`videos-icon menu-icon ${activeItem == 'videos' ? 'active' : ''}`} />
          <div className='text'>動画で学ぶ</div>
        </div>
      ) : (
        <></>
      )}
      {user?.permissions?.MY_PAGE == true ? (
        <div className='icon-wrapper'>
          <span
            className={`mypage-icon menu-icon ${activeItem == 'mypage' ? 'active' : ''}`}
            onClick={() => onClickItem('/mypage')}
          />
          <div className='text'>マイページ</div>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : userType !== ROLE_TYPE.STAFF ? (
    <div className='bottom-menu-wrapper'>
      <div className='icon-wrapper' onClick={() => onClickItem('/')}>
        <span className={`home-icon menu-icon ${activeItem == 'home' ? 'active' : ''}`} />
        <div className='text'>ホーム</div>
      </div>
      <div className='icon-wrapper' onClick={() => navigate(ROUTE.DEPARTMENT_TEST)}>
        <span className={`mini-test-icon menu-icon ${activeItem == 'mini-test' ? 'active' : ''}`} />
        <div className='text'>小テスト</div>
      </div>
      <div className='icon-wrapper' onClick={() => onClickItem('/departments')}>
        <span className={`videos-icon menu-icon ${activeItem == 'videos' ? 'active' : ''}`} />
        <div className='text'>動画で学ぶ</div>
      </div>
      <div className='icon-wrapper'>
        <span
          className={`mypage-icon menu-icon ${activeItem == 'mypage' ? 'active' : ''}`}
          onClick={() => onClickItem('/mypage')}
        />
        <div className='text'>マイページ</div>
      </div>
      {/* <div className='icon-wrapper'>
        <span
          className={`lesson-icon menu-icon ${activeItem == 'lesson' ? 'active' : ''}`}
          onClick={() => onClickItem('/online-lesson-departments')}
        />
        <div className='text'>オンラインレッスン</div>
      </div> */}
      <div className='icon-wrapper'>
        <span
          className={`contact-chat-icon menu-icon ${activeItem == 'contact' ? 'active' : ''}`}
          onClick={() => onClickItem('/contact')}
        />
        <div className='text'>連絡帳</div>
      </div>
    </div>
  ) : (
    <div className='bottom-menu-wrapper'>
      <div className='icon-wrapper' onClick={() => onClickItem('/')}>
        <span className={`home-icon menu-icon ${activeItem == 'home' ? 'active' : ''}`} />
        <div className='text'>ホーム</div>
      </div>
      <div className='icon-wrapper' onClick={() => navigate(ROUTE.DEPARTMENT_TEST)}>
        <span className={`mini-test-icon menu-icon ${activeItem == 'mini-test' ? 'active' : ''}`} />
        <div className='text'>小テスト</div>
      </div>
      <div className='icon-wrapper' onClick={() => onClickItem('/departments')}>
        <span className={`videos-icon menu-icon ${activeItem == 'videos' ? 'active' : ''}`} />
        <div className='text'>動画で学ぶ</div>
      </div>
      <div className='icon-wrapper'>
        <span
          className={`mypage-icon menu-icon ${activeItem == 'mypage' ? 'active' : ''}`}
          onClick={() => onClickItem('/mypage')}
        />
        <div className='text'>マイページ</div>
      </div>
    </div>
  )
}

export default BottomMenu
