import request from '@/api/config/request'
import type { ResponseList } from '@/interface/commonInterface'

export const getOnlineLessons = (onlineLesson) => {
  const url = '/online-lessons'
  return request<ResponseList, ResponseList>({
    url,
    method: 'GET',
    params: {
      type_route: onlineLesson.type_route,
      'filters[2][key]': onlineLesson.key,
      'filters[2][data]': onlineLesson.data
    }
  })
}
