import BoxMessage from './components/BoxMessage'
import messageIcon from '@/assets/imgs/message.png'
import { getBulletinData } from '@/stores/bulletinStore'
import { useEffect } from 'react'
import { BulletinWrapper } from './styled'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { Spin } from 'antd'

const BulletinMobile = () => {
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector(state => state.bulletin)

  useEffect(() => {
    dispatch(getBulletinData({ perPage: 1 }))
  }, [])

  return (
    <Spin spinning={loading}>
      <BulletinWrapper>
        <img src={messageIcon} alt='' width={30} />
        <p>掲示板</p>
        <BoxMessage />
      </BulletinWrapper>
    </Spin>
  )
}

export default BulletinMobile
