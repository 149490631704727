import React, { useEffect, useState } from 'react'
import PageTitle from '@/components/common/PageTitle'
import ListItemWeb from '@/components/common/ListItemWeb'
import ListItem from '@/components/common/ListItem'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd'
import { getSettingVirtuals, getVirtuals } from '@/stores/virtualStore'
import { useAppDispatch } from '@/app/hooks'
import { ROUTE } from '@/router/config'
import { SCREEN_SIZE } from '@/utils/screenSize'
import { useMediaQuery } from 'react-responsive'

const schoolList = [
  { id: 1, name: 'ENTRANCE', icon: 'entrance' },
  { id: 2, name: '大講堂', icon: 'presentation' },
  { id: 3, name: 'クラスルーム', icon: 'classroom' }
]
const SchoolView: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const virtualItemOnClick = id => {
    switch (id) {
      case 1:
      case 2:
        dispatch(getSettingVirtuals(id === 1 ? 'ENTRANCE_URL' : 'LECTURE_HALL_URL')).then(
          response => {
            const data = response?.payload
            window.open(data.value, '_blank')
          }
        )
        break

      default:
        dispatch(getVirtuals()).then(response => {
          const data = response
          console.log(data)
        })
        navigate(ROUTE.VIRTUAL)
        break
    }
  }
  const type = 'school'
  const height = window.innerHeight - 140

  useEffect(() => {
    dispatch(getVirtuals())
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 100)
  }, [])

  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })
  if (!isMobile) {
    return (
      <Spin spinning={loading}>
        <div className='sub-activities-list-wrapper px5'>
          <div className='box-web'>
            {schoolList.map((item, index) => (
              <ListItemWeb
                key={index}
                type={type}
                item={item}
                handleClick={virtualItemOnClick}
                iconSvg={item.icon}
              />
            ))}
          </div>
        </div>
      </Spin>
    )
  } else {
    return (
      <div className='content-wrapper background-grey' style={{ height }}>
        <PageTitle icon='virtual-school-icon' title='バーチャルスクール' />
        <Spin spinning={loading}>
          <div
            className='list-wrapper department-list school px5'
            style={{ backgroundColor: isMobile ? '#f7f7f7' : '' }}
          >
            <div className='box'>
              {schoolList.map((item, index) => (
                <ListItem
                  key={index}
                  item={item}
                  handleClick={virtualItemOnClick}
                  iconSvg={item.icon}
                />
              ))}
            </div>
          </div>
        </Spin>
      </div>
    )
  }
}
export default SchoolView
