import React, { useEffect } from 'react'
import PageTitle from '@/components/common/PageTitle'
import LessonListItem from '@/components/common/LessonListItem'
import { useNavigate } from 'react-router-dom'
import { getDepartments } from '@/stores/departmentStore'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { Spin } from 'antd'

const OnlineLessonDepartmentsView: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { departmentList, loading } = useAppSelector(state => state.department)
  const departmenItemOnClick = id => {
    const departmentType = departmentList.filter(t => t.id == id)
    if (departmentType[0].type == 'DEFAULT') {
      navigate('/online-lesson', {
        state: {
          departmentId: id
        }
      })
    } else {
      navigate('/online-lesson-en')
    }
  }
  const height = window.innerHeight - 140

  useEffect(() => {
    dispatch(getDepartments())
  }, [])

  return (
    <div className='content-wrapper background-blue' style={{ height }}>
      <PageTitle icon='lesson-icon' title='オンラインレッスン' />
      <Spin spinning={loading}>
        <div className='list-wrapper lesson-list px5' style={{ backgroundColor: '#edf9f7' }}>
          <div className='box'>
            {departmentList.map((item, index) => (
              <LessonListItem
                key={index}
                item={item}
                schoolName={item.school.name}
                handleClick={departmenItemOnClick}
                customClass=''
              />
            ))}
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default OnlineLessonDepartmentsView
