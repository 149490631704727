import Layout from '@/components/common/Layout'
import {
  BulletinView,
  ChaptersView,
  ContactView,
  DepartmentsView,
  FirstPasswordChangeView,
  HomeView,
  HomeFireBaseView,
  LoginView,
  LogoutView,
  MessageDetailMobile,
  MyPageView,
  OnlineLessonArchiveView,
  OnlineLessonDepartmentsView,
  OnlineLessonEnView,
  OnlineLessonParticipationView,
  OnlineLessonView,
  PasswordChangeView,
  PasswordForgotView,
  PasswordResetView,
  QuestionTest,
  RoomView,
  SchoolView,
  SubjectsView,
  VideoDetailView,
  VideoView,
  VirtualView,
  PageNotFoundDomain
} from '@/pages'
import PageNotFound from '@/pages/PageNotFound/PageNotFound'
import { createBrowserRouter } from 'react-router-dom'
import AuthGuard from './AuthGuard'
import { ROUTE } from './config'

// const QuestionTest = React.lazy(() => import('src/pages/QuestionTest'))
import { reverseString } from '@/utils/helpers'

const domain = window.location.hostname
const code = reverseString(domain)
const router = createBrowserRouter([
  {
    element: <AuthGuard />,
    errorElement: <PageNotFound />,
    children: [
      {
        element: <Layout />,
        children: [
          {
            path: ROUTE.HOME,
            element: <HomeView />
          },
          {
            path: ROUTE.HOMEFIREBASE,
            element: <HomeFireBaseView />
          },
          {
            path: ROUTE.DEPARTMENT,
            element: <DepartmentsView />
          },
          {
            path: ROUTE.DEPARTMENT_TEST,
            element: <DepartmentsView />
          },

          {
            path: ROUTE.SUBJECT,
            element: <SubjectsView />
          },
          {
            path: ROUTE.SUBJECT_TEST,
            element: <SubjectsView />
          },
          {
            path: ROUTE.CHAPTER,
            element: <ChaptersView />
          },
          {
            path: ROUTE.CHAPTER_TEST,
            element: <ChaptersView />
          },
          {
            path: ROUTE.VIDEO,
            element: <VideoView />
          },
          {
            path: ROUTE.VIDEO_TEST,
            element: <VideoView />
          },
          {
            path: ROUTE.VIDEO_DETAIL,
            element: <VideoDetailView />
          },
          {
            path: ROUTE.SCHOOL,
            element: <SchoolView />
          },
          {
            path: ROUTE.ROOM,
            element: <RoomView />
          },
          {
            path: ROUTE.VIRTUAL,
            element: <VirtualView />
          },
          {
            path: ROUTE.MY_PAGE,
            element: <MyPageView />
          },
          {
            path: ROUTE.CONTACT,
            element: <ContactView />
          },
          {
            path: ROUTE.ONLINE_LESSON,
            element: <OnlineLessonView />
          },
          {
            path: ROUTE.ONLINE_LESSON_EN,
            element: <OnlineLessonEnView />
          },
          {
            path: ROUTE.ONLINE_LESSON_PARTICIPATION,
            element: <OnlineLessonParticipationView />
          },
          {
            path: ROUTE.ONLINE_LESSON_ARCHIVE,
            element: <OnlineLessonArchiveView />
          },
          {
            path: ROUTE.ONLINE_LESSON_DEPARTMENT,
            element: <OnlineLessonDepartmentsView />
          },
          {
            path: ROUTE.MINI_TEST,
            element: <QuestionTest />
          },
          {
            path: ROUTE.BULLETIN,
            element: <BulletinView />
          },
          {
            path: ROUTE.BULLETIN_DETAIL,
            element: <MessageDetailMobile />
          },
          {
            path: ROUTE.LOG_OUT,
            element: <LogoutView />
          }
        ]
      }
    ]
  },
  {
    path: '/' + code,
    element: <LoginView />
  },
  {
    path: '/login',
    element: <LoginView />
  },
  {
    path: '/change-password',
    element: <PasswordChangeView />
  },
  {
    path: '/change',
    element: <FirstPasswordChangeView />
  },
  {
    path: '/reset-password',
    element: <PasswordResetView />
  },
  {
    path: '/forgot-password',
    element: <PasswordForgotView />
  },
  {
    path: '/not-found',
    element: <PageNotFoundDomain />
  }
])

export default router
