import React, { useEffect, useState, useMemo } from 'react'
import { Col, Row, Button, Form, Input, Image, Upload, Card, Modal } from 'antd'
import { SCREEN_SIZE } from '@/utils/screenSize'
import { useMediaQuery } from 'react-responsive'
import { getRooms, getMessages, createMessage, uploadFile } from '@/stores/contactStore'
import { getInfo } from '@/stores/userStore'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { CreateMessagePayload, UploadFilePayload } from '@/interface/contactInterface'
import { AVATAR_DEFAULT } from '@/assets'
import { FILE_ACCEPT_CHAT } from '@/utils/constants'
import { getDateMessage } from '@/utils/helpers'
import { PopupNoti } from '@/components/common/Popup/PopupNoti'
import {
  ICON_ATACHMENT,
  ICON_SEND,
  ICON_SENT_ATACHMENT,
  ICON_FILE_BACKGROUND
} from '@/assets/svg/icon'
import { DownloadMessageFile } from '@/components/common/Download/DownloadMessageFile'
import i18Next from '@/i18n'

const getFileExtension = filename => {
  return filename.split('.').pop()
}

const ContactView: React.FC = () => {
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })
  const { TextArea } = Input
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const { rooms } = useAppSelector(state => state.contact)
  const [room, setRoom] = useState()
  const [fileArray, setFileArray] = useState<any[]>([])
  const [messageList, setMessageList] = useState<any[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState('')
  const [fileName, setFilename] = useState('')

  const uploadFileProps = useMemo(
    () => ({
      name: 'attachments',
      accept: FILE_ACCEPT_CHAT
    }),
    []
  )

  const onShowFileModal = () => {
    setIsModalOpen(true)
  }

  const onCloseFileModal = () => {
    // setOpen(false)
    setIsModalOpen(false)
  }

  const handleOk = async () => {
    setIsModalOpen(false)
    // setfileConfirm(true)
    if (selectedFile !== null) {
      const contactData: UploadFilePayload = {
        data: {
          file: selectedFile,
          room_id: room == undefined ? 0 : room
        }
      }
      await dispatch(uploadFile(contactData))
      dispatch(getMessages({ roomId: room, currentPage: 1 })).then((res: any) => {
        setMessageList(res?.payload?.data)
        const data = res?.payload?.data
        return data
      })
      setFileArray([])
      return false
    }
  }

  const [showModal, setShowModal] = useState({
    isOpenModal: false,
    file: {}
  })

  const onShowModal = (isOpenModal, file) => {
    setShowModal({ isOpenModal, file })
  }
  const onCloseModal = () => {
    setShowModal({ isOpenModal: false, file: {} })
  }

  useEffect(() => {
    dispatch(getRooms()).then(() => {
      if (rooms?.id !== undefined) {
        const id = rooms?.id
        console.log(room)
        setRoom(id)
        // dispatch(getMessages({ roomId: id, currentPage: 1 }))
        dispatch(getMessages({ roomId: id, currentPage: 1 })).then((res: any) => {
          setMessageList(res?.payload?.data)
        })
      }
    })
  }, [rooms?.id])

  useEffect(() => {
    dispatch(getInfo())
  }, [])

  // Chat
  const handleBeforeUpload = async (file: any) => {
    const LIMIT_SIZE_OF_IMAGE = 5368709120 // 5gb
    const isTypeValid = FILE_ACCEPT_CHAT.includes(getFileExtension(file.name))

    const sizeCheck = file.size <= LIMIT_SIZE_OF_IMAGE ? true : false
    if (!isTypeValid) {
      PopupNoti.error(i18Next.t('contact.error'))
      return
    }

    if (!sizeCheck) {
      PopupNoti.error(i18Next.t('contact.sizeValid'))
      return
    } else {
      setSelectedFile(file)
      setFilename(file?.name)
      onShowFileModal()
    }
  }

  useEffect(() => {
    window?.Echo?.channel(`room.${room}`)?.listen('MessagePosted', data => {
      dispatch(getMessages({ roomId: room, currentPage: 1 })).then((res: any) => {
        setMessageList(res?.payload?.data)
        const data = res?.payload?.data
        return data
      })
      console.log(data)
    })
  }, [room])

  const handleSendMessage = async values => {
    if (fileArray?.length > 0) {
      const contactData: UploadFilePayload = {
        data: {
          file: values?.content,
          room_id: room == undefined ? 0 : room
        }
      }
      dispatch(uploadFile(contactData))
    } else {
      const contactData: CreateMessagePayload = {
        data: {
          content: values?.content,
          room_id: room == undefined ? 0 : room
        }
      }
      await dispatch(createMessage(contactData))
      dispatch(getMessages({ roomId: room, currentPage: 1 })).then((res: any) => {
        setMessageList(res?.payload?.data)
        const data = res?.payload?.data
        return data
      })
      form.setFieldsValue({ content: '' })
    }
  }
  // Chat end

  // const heightPC = window.innerHeight - 70
  const height = window.innerHeight - 150
  const width = window.innerWidth - 120

  if (!isMobile) {
    return (
      <>
        <div className='content-wrapper background-cornflower'>
          {/* <> */}
          <div className='card-p-2'>
            <Form.Item className='contact-title'>
              <Row className='contact-header'>
                <Col>
                  {' '}
                  <span className='contact-chat-icon' />
                </Col>
                <Col span={6}>
                  {' '}
                  <div className='title'>連絡帳</div>
                </Col>
              </Row>
            </Form.Item>

            <div
              className='card-message'
              style={{
                maxHeight: '42rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <div
                style={{
                  height: '42rem',
                  overflow: 'auto',
                  flexDirection: 'column-reverse',
                  display: 'flex'
                }}
              >
                {messageList?.map((data, i) => {
                  return (
                    <div
                      key={data?.id}
                      className={`d-flex align-items-center ${
                        data?.sender !== rooms?.student_id ? '' : 'justify-content-end'
                      }  mb-1`}
                    >
                      {data?.sender !== rooms?.student_id ? (
                        <div style={{ marginRight: 20, marginBottom: 20 }}>
                          {messageList[i]?.sender !== messageList[i + 1]?.sender ? (
                            <Image
                              preview={false}
                              style={{ borderRadius: '50%', marginRight: 20 }}
                              // src={student?.photo_card}
                              width={40}
                              height={40}
                              fallback={AVATAR_DEFAULT}
                              placeholder={
                                <Image
                                  preview={false}
                                  src={AVATAR_DEFAULT}
                                  width={40}
                                  height={40}
                                />
                              }
                            />
                          ) : (
                            <Image
                              preview={false}
                              style={{ borderRadius: '50%', display: 'none', marginRight: 20 }}
                              width={40}
                              height={40}
                              placeholder={<Image preview={false} width={40} height={40} />}
                            />
                          )}
                        </div>
                      ) : (
                        <div style={{ paddingLeft: 40 }}></div>
                      )}
                      <div id='msger-chat' className='ml-3'>
                        <div
                          className={`mb-1 d-flex ${
                            data?.sender_user?.id === rooms?.student_id
                              ? 'p-2-right user speech-bubble-right'
                              : 'p-2-left admin speech-bubble-left'
                          }`}
                        >
                          <div className='width-content'>
                            {data?.content !== null ? (
                              <div
                                className={`${
                                  data?.sender_user?.id === rooms?.student_id
                                    ? 'text-user'
                                    : 'text-admin'
                                }`}
                                dangerouslySetInnerHTML={{
                                  __html: data?.content?.split('\n')?.join('<br />')
                                }}
                              />
                            ) : (
                              <div
                                className={`${
                                  data?.sender_user?.id === rooms?.student_id
                                    ? 'text-user'
                                    : 'text-admin'
                                }`}
                                style={{ cursor: 'pointer', fontWeight: 'bold' }}
                                onClick={() => {
                                  onShowModal(true, data?.file)
                                }}
                              >
                                <Button
                                  style={{
                                    borderRadius: 30,
                                    marginRight: 10,
                                    height: 40,
                                    width: 40
                                  }}
                                  icon={<ICON_SENT_ATACHMENT />}
                                ></Button>
                                {data?.file?.file_path
                                  .substring(data?.file?.file_path.lastIndexOf('/') + 1)
                                  .split('_')
                                  .slice(1)
                                  .join('_')}
                              </div>
                            )}
                          </div>
                          <div
                            style={{
                              fontSize: 12,
                              display: 'flex',
                              alignItems: 'flex-end',
                              paddingTop: '1rem'
                            }}
                            className='ml-3'
                          >
                            {getDateMessage(data?.created_at)}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>

              <Form
                layout='vertical'
                name='message-form'
                onFinish={handleSendMessage}
                form={form}
                translate='yes'
                autoComplete='off'
                size='middle'
                validateTrigger={['onBlur', 'onChange']}
              >
                <Card style={{ borderRadius: 15 }}>
                  <Form.Item name='content' className='msger-inputarea'>
                    {/* <TextArea placeholder='メッセージを入力'></TextArea> */}
                    <TextArea
                      style={{ resize: 'none', border: 'none', paddingLeft: 15 }}
                      className='msger-input'
                      rows={4}
                      placeholder='メッセージを入力'
                    />
                  </Form.Item>
                  <Row className='justify'>
                    <div>
                      <div className='d-flex'>
                        <Upload
                          // action={baseURL + '/messages/file'}
                          onPreview={() => false}
                          beforeUpload={handleBeforeUpload}
                          className='w-auto'
                          maxCount={1}
                          fileList={fileArray}
                          {...uploadFileProps}
                        >
                          {' '}
                          <Button
                            style={{ border: 'none', background: 'none', marginLeft: 10 }}
                            icon={<ICON_ATACHMENT />}
                          />
                        </Upload>
                      </div>
                    </div>

                    <div>
                      {/* <Tooltip title='Send'> */}
                      <Button
                        style={{
                          border: 'none',
                          background: 'none',
                          marginTop: 10,
                          marginRight: 10
                        }}
                        htmlType='submit'
                        icon={<ICON_SEND />}
                      />
                      {/* </Tooltip> */}
                    </div>
                  </Row>
                </Card>
              </Form>
            </div>

            <Modal
              title={i18Next.t('modal.title')}
              open={isModalOpen}
              onOk={handleOk}
              onCancel={onCloseFileModal}
              footer={[
                <Button key='cancel' onClick={onCloseFileModal}>
                  {i18Next.t('modal.cancel')}
                </Button>,
                <Button key='ok' type='default' onClick={handleOk}>
                  {i18Next.t('modal.send')}
                </Button>
              ]}
            >
              <div className='d-flex align-items-center'>
                <div className='file d-flex align-items-center justify-content-center mr-2'>
                  <ICON_FILE_BACKGROUND />
                </div>
                <div style={{ color: '#26B7BC', maxWidth: '25rem', overflowWrap: 'break-word' }}>
                  {' '}
                  {fileName}
                </div>
              </div>
              <div
                style={{
                  color: '#ff4d4f',
                  maxWidth: '25rem',
                  marginTop: '0.5rem',
                  overflowWrap: 'break-word'
                }}
              >
                {i18Next.t('contact.note')}
              </div>
            </Modal>

            {showModal.isOpenModal && (
              <DownloadMessageFile
                isShowModal={showModal.isOpenModal}
                file={showModal.file}
                onClose={onCloseModal}
              />
            )}
          </div>
        </div>
      </>
    )
  } else {
    return (
      <div className='content-wrapper background-grey'>
        <div className='mobile-card-p-2'>
          <div className='mobile-header'>
            <span className='contact-chat-icon' />
            <div className='mobile-title'>連絡帳</div>
          </div>
          <div
            className='card-message'
            style={{
              height: height,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                height: height,
                overflow: 'auto',
                flexDirection: 'column-reverse',
                display: 'flex'
              }}
            >
              {messageList?.map((data, i) => {
                return (
                  <div
                    key={data?.id}
                    className={`d-flex align-items-center ${
                      data?.sender !== rooms?.student_id ? '' : 'justify-content-end'
                    }  mb-1`}
                  >
                    {data?.sender !== rooms?.student_id ? (
                      <div style={{ marginRight: 0, marginBottom: 20 }}>
                        {messageList[i]?.sender !== messageList[i + 1]?.sender ? (
                          <Image
                            preview={false}
                            style={{ borderRadius: '50%' }}
                            width={40}
                            height={40}
                            fallback={AVATAR_DEFAULT}
                            placeholder={
                              <Image preview={false} src={AVATAR_DEFAULT} width={40} height={40} />
                            }
                          />
                        ) : (
                          <Image
                            preview={false}
                            style={{ borderRadius: '50%', display: 'none', marginRight: 20 }}
                            // src={student?.photo_card}
                            width={40}
                            height={40}
                            placeholder={<Image preview={false} width={40} height={40} />}
                          />
                        )}
                      </div>
                    ) : (
                      <div style={{ paddingLeft: 40 }}></div>
                    )}
                    <div id='msger-chat' className='ml-3'>
                      <div
                        className={`mb-1 d-flex ${
                          data?.sender_user?.id === rooms?.student_id
                            ? 'p-2-right user speech-bubble-right'
                            : 'p-2-left admin speech-bubble-left'
                        }`}
                      >
                        <div style={{ maxWidth: '10rem' }}>
                          {data?.content !== null ? (
                            <div
                              className={`${
                                data?.sender_user?.id === rooms?.student_id
                                  ? 'text-user'
                                  : 'text-admin'
                              }`}
                              dangerouslySetInnerHTML={{
                                __html: data?.content?.split('\n')?.join('<br />')
                              }}
                            />
                          ) : (
                            <div
                              className={`${
                                data?.sender_user?.id === rooms?.student_id
                                  ? 'text-user'
                                  : 'text-admin'
                              }`}
                              style={{ cursor: 'pointer', fontWeight: 'bold' }}
                              onClick={() => {
                                onShowModal(true, data?.file)
                              }}
                            >
                              <Button
                                style={{ borderRadius: 30, marginRight: 5 }}
                                icon={<ICON_SENT_ATACHMENT />}
                              ></Button>
                              {data?.file?.file_path
                                .substring(data?.file?.file_path.lastIndexOf('/') + 1)
                                .split('_')
                                .slice(1)
                                .join('_')}
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            fontSize: 12,
                            display: 'flex',
                            alignItems: 'flex-end',
                            paddingTop: '1rem'
                          }}
                          className='ml-3'
                        >
                          {getDateMessage(data?.created_at)}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

            <Form
              layout='vertical'
              name='message-form'
              onFinish={handleSendMessage}
              form={form}
              translate='yes'
              autoComplete='off'
              size='middle'
              validateTrigger={['onBlur', 'onChange']}
            >
              <Row
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  background: '#ffffff'
                }}
              >
                <div>
                  <div className='d-flex'>
                    <Upload
                      onPreview={() => false}
                      beforeUpload={handleBeforeUpload}
                      className='w-auto'
                      maxCount={1}
                      fileList={fileArray}
                      {...uploadFileProps}
                    >
                      {' '}
                      <Button
                        style={{
                          border: 'none',
                          background: 'none',
                          marginTop: 0,
                          marginLeft: 10
                        }}
                        icon={<ICON_ATACHMENT />}
                      />
                    </Upload>
                  </div>
                </div>
                <div>
                  <Form.Item name='content'>
                    <TextArea
                      style={{
                        backgroundColor: 'rgb(247, 247, 247)',
                        borderRadius: 20,
                        resize: 'none',
                        border: 'none',
                        width: width,
                        marginTop: 20
                      }}
                      rows={1}
                      className='msger-input'
                      placeholder='メッセージを入力'
                    />
                  </Form.Item>
                </div>
                <div>
                  {/* <Tooltip title='Send'> */}
                  <Button
                    style={{
                      border: 'none',
                      background: 'none',
                      marginTop: 2,
                      marginRight: 10
                    }}
                    htmlType='submit'
                    icon={<ICON_SEND />}
                  />
                  {/* </Tooltip> */}
                </div>{' '}
              </Row>
            </Form>
          </div>

          <Modal
            title={i18Next.t('modal.title')}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={onCloseFileModal}
            footer={[
              <Button key='cancel' onClick={onCloseFileModal}>
                {i18Next.t('modal.cancel')}
              </Button>,
              <Button key='ok' type='default' onClick={handleOk}>
                {i18Next.t('modal.send')}
              </Button>
            ]}
          >
            <div className='d-flex align-items-center'>
              <div className='file d-flex align-items-center justify-content-center mr-2'>
                <ICON_FILE_BACKGROUND />
              </div>
              <div style={{ color: '#26B7BC', maxWidth: '15rem', overflowWrap: 'break-word' }}>
                {' '}
                {fileName}
              </div>
            </div>

            <div
              style={{
                color: '#ff4d4f',
                maxWidth: '25rem',
                marginTop: '0.5rem',
                overflowWrap: 'break-word'
              }}
            >
              {i18Next.t('contact.note')}
            </div>
          </Modal>

          {showModal.isOpenModal && (
            <DownloadMessageFile
              isShowModal={showModal.isOpenModal}
              file={showModal.file}
              onClose={onCloseModal}
            />
          )}
        </div>
      </div>
    )
  }
}

export default ContactView
