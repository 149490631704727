import messageIcon from '@/assets/imgs/message.png'
import { downloadAttachment } from '@/utils/helpers'
import { useLocation } from 'react-router-dom'
import { MessageDetailWrapper } from '../styled'
import { Tooltip } from 'antd'

const MessageDetail = () => {
  const location = useLocation()

  return (
    <MessageDetailWrapper>
      <img src={messageIcon} alt='' width={30} />
      <div style={{ fontSize: '14px' }}>掲示板</div>
      <div className='box-container'>
        <div className='box-tag'>
          <div className='box-circle' />
        </div>
        <Tooltip title={location.state.title}>
          <div className='message-title'>{location.state.title}</div>
        </Tooltip>

        <div
          className='content-border'
          dangerouslySetInnerHTML={{
            __html: location.state.content.split('\n')?.join('<br />')
          }}
        />

        <div className='box-container-footer'>
          <div className='box-circle' />
          {location.state.notification?.file_manage && (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                downloadAttachment(location.state.notification?.file_manage)
              }}
            >
              添付ファイル：ダウンロード
            </div>
          )}
          <div className='box-circle' />
        </div>
      </div>
    </MessageDetailWrapper>
  )
}

export default MessageDetail
