import { ACCESS_TOKEN } from '../../utils/constants'
import axios from 'axios'
// import router from '@/router'
import { PopupNoti } from '@/components/common/Popup/PopupNoti'
import { ROLE_TYPE } from '@/utils/constants'

// axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'https://emi-api-dev.1bitlab.vn/api'
// axios.defaults.baseURL =
//   'https://emi-api-dev.1bitlab.vn/api' || 'https://api-product2.emi-ko-lms.com/api'
 axios.defaults.baseURL =
  process.env.REACT_APP_API_URL + '/api'

axios.defaults.withCredentials = true

const instance = axios.create({
  baseURL: axios.defaults.baseURL
})

instance.interceptors.request.use(
  function (config: any) {
    try {
      const token = localStorage.getItem(ACCESS_TOKEN)
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
    } catch (error) {
      throw Error('')
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function (response) {
    const data = response?.data?.data || response?.data || response
    if (response?.data?.status_code === 401 || response?.data?.status_code === 403) {
      localStorage.clear()
    }

    if (response?.data?.status_code !== 200) return Promise.reject(response?.data)

    return data
  },
  function (error) {
    let user: any
    let check: any
    const _user = localStorage.getItem('user')
    if (_user) user = JSON.parse(_user)

    const userType = user?.role?.type
    if (
      userType == ROLE_TYPE.USER ||
      ROLE_TYPE.GUARDIAN ||
      userType == ROLE_TYPE.STAFF ||
      userType == ROLE_TYPE.TEACHER
    ) {
      check = true

      if (
        error?.response?.data.message === 'Unauthenticated.' ||
        error?.response?.data?.status_code === 423 ||
        (error?.response?.status === 401 && check === true)
      ) {
        if (localStorage.length !== 0) {
          PopupNoti.error('アカウントが無効です')
        }
        localStorage.clear()
        setTimeout(() => {
          localStorage.removeItem('token')
          axios.defaults.headers.common['Authorization'] = null
          window.location.href = '/login'
        }, 1000)
      }
    } else {
      if (error?.response?.data.message === 'Unauthenticated.') localStorage.clear()
      // return router.push({ name: 'login' })
    }
    if (error?.response?.data) {
      return Promise.reject(error?.response?.data)
    }
    return Promise.reject(error)
  }
)

export const setLocaleApi = (locale: string) => {
  instance.defaults.headers.common['lang'] = locale
}

export default instance
