import React from 'react'
import { OnlineLesson } from '@/interface/onlineLessonInterface'
import { Col, Row, Button } from 'antd'
import Moment from 'react-moment'
import { getWeekDay, getHours } from '@/utils/helpers'
import { SCREEN_SIZE } from '@/utils/screenSize'
import { useMediaQuery } from 'react-responsive'

type ItemListProps = {
  item: OnlineLesson
}

const OnlineLessonList: React.FC<ItemListProps> = ({ item }) => {
  const dateToFormat = new Date(item.start_date)
  const day = new Date(item.start_date).getDay()
  // const onclick = () => window.open('https://zoom.us/ja/signin#/login')
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })
  const width = window.innerWidth - 60

  if (!isMobile) {
    return (
      <div className='activity-item-web'>
        <div className='home-web-wrapper'>
          <Row className='participation-content-wrapper'>
            <Col span={8}>
              <div className='lesson-participations'>
                <div className='title'>{item.title}</div>
                <div className='list'></div>
              </div>
            </Col>
            <Col span={16}>
              <div className='data'>
                <div className='item'>
                  <Row>
                    <Col>
                      <div className='text'>
                        <span>
                          <Moment format='M月D日' date={dateToFormat} /> {'('}
                          {day === 0 || day === 6 ? (
                            <span className='weekeend'>{getWeekDay(item.start_date)}</span>
                          ) : (
                            <span>{getWeekDay(item.start_date)}</span>
                          )}
                          {')'} {''}
                          {getHours(item.start_date)}~{getHours(item.end_date)}
                        </span>
                      </div>
                    </Col>
                    <Col className='data-icon-space'>
                      <div>
                        <span className='mypage-icon' />
                      </div>
                    </Col>

                    <Col className='data-button-space'>
                      <div>
                        {/* <Button onClick={onconClick={onclick}lick} className='button-entrance'> */}
                        <Button href={item.link} className='button-entrance'>
                          <span className='button-text'>入室</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  } else {
    return (
      <Row
        style={{
          paddingTop: 20,
          paddingBottom: 40,
          marginBottom: 60,
          marginLeft: 15
        }}
        className='mobile-participation-content-wrapper'
      >
        <Col span={8}>
          <div className='mobile-data' style={{ width: width }}>
            <div className='item'>
              <Row>
                <Col>
                  <div className='mobile-lesson-participations' style={{ width: width }}>
                    <div className='title'>{item.title}</div>
                  </div>

                  <Row>
                    <Col
                      style={{
                        paddingTop: 20,
                        paddingLeft: 50
                      }}
                    ></Col>
                    <div className='text'>
                      <span>
                        <Moment format='M月D日' date={dateToFormat} /> {'('}
                        {day === 0 || day === 6 ? (
                          <span className='weekeend'>{getWeekDay(item.start_date)}</span>
                        ) : (
                          <span>{getWeekDay(item.start_date)}</span>
                        )}
                        {')'} {''}
                        {getHours(item.start_date)}~{getHours(item.end_date)}
                      </span>
                    </div>
                    <Col
                      style={{
                        paddingTop: 20,
                        paddingLeft: 40
                      }}
                    >
                      <div>
                        <span className='mypage-icon' />
                      </div>
                    </Col>
                  </Row>

                  <div>
                    <Button href={item.link} className='button-entrance'>
                      <span className='button-text'>入室</span>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default OnlineLessonList
