import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as API from '@/api/videoAPI'
import { Video, UpdatePayload, GetVideoPayload } from '@/interface/videoInterface'

const initialState = {
  loading: false,
  videosList: [] as Video[] | any,
  currentPage: 1 as number | undefined,
  totalPage: 0 as number | undefined,
  error: null as any,
  success: false
}

export const getVideos = createAsyncThunk(
  'video/get',
  async (payload: GetVideoPayload, { rejectWithValue }) => {
    try {
      const response = await API.getVideos(payload)

      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updatePlayedTime = createAsyncThunk(
  'video/updatePlayedTime',
  async (payload: UpdatePayload, { rejectWithValue }) => {
    try {
      const response = await API.updatePlayedTime(payload.id, payload.data)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateFavoriteVideo = createAsyncThunk(
  'video/updateFavoriteVideo',
  async (payload: UpdatePayload, { rejectWithValue }) => {
    try {
      const response = await API.updateFavorite(payload.id, payload.data)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateVideoView = createAsyncThunk(
  'video/updateVideoView',
  async (payload: number, { rejectWithValue }) => {
    try {
      const response = await API.updateVideoView(payload)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    refreshVideosList: (state, action) => {
      state.videosList = action.payload
    },
    clearData: state => {
      state.loading = false
      state.videosList = []
      state.error = null
      state.success = false
    }
  },
  extraReducers: builder => {
    builder.addCase(getVideos.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getVideos.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.videosList = [...state.videosList]
        .concat(payload.data)
        .sort((v1, v2) => (v1.order > v2.order ? 1 : v1.order < v2.order ? -1 : 0))
      state.currentPage = payload.current_page
      state.totalPage = payload.total_page
    })
    builder.addCase(getVideos.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })
    builder.addCase(updateFavoriteVideo.rejected, (state, { payload }) => {
      state.error = payload
    })
    builder.addCase(updateVideoView.rejected, (state, { payload }) => {
      state.error = payload
    })
  }
})
export const refreshVideosList = videoSlice.actions.refreshVideosList
export const clearData = videoSlice.actions.clearData

export default videoSlice.reducer
