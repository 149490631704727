import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as API from '@/api/onlineLessonAPI'
import type { ResponseList } from '@/interface/commonInterface'
import { GetPayload } from '@/interface/onlineLessonInterface'

const initialState = {
  loading: false,
  onlineLessonList: [] as ResponseList,
  error: null as any,
  success: false
}

export const getOnlineLessons = createAsyncThunk(
  'online-lessons/get',
  async (payload: GetPayload, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line camelcase
      const response = await API.getOnlineLessons(payload)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

const onlineLessonSlice = createSlice({
  name: 'onlineLesson',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getOnlineLessons.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getOnlineLessons.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.onlineLessonList = payload
    })
    builder.addCase(getOnlineLessons.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })
  }
})

export default onlineLessonSlice.reducer
