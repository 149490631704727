import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as API from '@/api/authAPI'

const initialState = {
  loading: false,
  error: null,
  success: false
}

export const userUpdatePassword = createAsyncThunk(
  'auth/update-password',
  async ({ mail_address, code, password }: any, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line camelcase
      const response = await API.updatePassword({
        mail_address: mail_address,
        token: code,
        password: password
      })
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


const authSlice = createSlice({
  name: 'updatePassword',
  initialState,
  reducers: {},
  extraReducers: {
    [userUpdatePassword.pending as any]: (state: any) => {
      state.loading = true
      state.error = null
    },
    [userUpdatePassword.rejected as any]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    }
  }
})

export default authSlice.reducer
