import request from '@/api/config/request'
import type { ResponseList } from '@/interface/commonInterface'

export const getVirtuals = () => {
  const url = '/virtuals'
  return request<ResponseList, ResponseList>({
    url,
    method: 'GET',
 })
}

export const getSettingVirtuals = key => {
  const url = '/settings'
  return request<any, any>({
    url,
    method: 'GET',
    params: { key: key }
  })
}
