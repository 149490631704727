export const ACCESS_TOKEN = 'access_token'
export const API_BASE_URL = process.env.REACT_APP_API_URL
export const DOMAIN_PROD = 'study.emi-ko-lms.com'

export const ROLE_USER = {
  USER_META_VERSE: 'USER_META_VERSE',
  USER_VIRTUAL: 'USER_VIRTUAL'
}

export const ROLE_TYPE = {
  USER: 'USER',
  GUARDIAN: 'GUARDIAN',
  TEACHER: 'TEACHER',
  STAFF: 'STAFF'
}

export const COLOR_PAGE = {
  GREY: '#f7f7f7',
  GREY_100: 'rgb(247, 247, 247)',
  PINK: '#f9f2f5',
  YELLOW: 'rgb(250, 248, 229)',
  YELLOW_100: 'rgb(249, 240, 234)',
  PURPLE: '#f4f0f8',
  CORNFLOWER: '#c0ecec',
  GREEN: '#edf9f7'
}

export const FILE_ACCEPT_CHAT =
  'image/png, image/jpeg, image/jpg, .docx, .xlsx, .pptx, .zip, .pdf, .png, .jpeg, .jpg'
