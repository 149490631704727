import { Form, Spin } from 'antd'
import { useEffect, useRef, useState } from 'react'

import Question from './components/Question'
import ResultTest from './components/ResultTest'

import { getTests, submitTest } from '@/api/TestsApi'
import { TestParams } from '@/interface/testInterface'
import { useLocation } from 'react-router-dom'
import ModalCloseExam from './ModalCloseExam/ModalCloseExam'
import { WrapperCustom } from './styled'
import { QuestionType } from './type'

const formatBody = (ansersList: any[]) => {
  const result = Object.keys(ansersList).map(key => [key, ansersList[key]])

  return result?.reduce((acc, it) => {
    const keys = it?.[0]
    const values = it?.[1]?.[0]
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [, questionId] = keys?.split('_')
    acc.push({
      test_detail_id: +questionId,
      answer: values
    })
    return acc
  }, [])
}

const QuestionTest = () => {
  const [form] = Form.useForm()
  const refModal: any = useRef()
  const location = useLocation()
  // const navigate = useNavigate()
  // const dispatch = useDispatch()
  const [testId, setTestId] = useState(0)
  const [loading, setLoading] = useState(false)
  const [totalPoint, setTotalPoint] = useState(0)
  const [questionData, setQuestionData] = useState<any[]>([])
  const [selectedQuestion, setSelectedQuestion] = useState(0)

  const isTestAudio = questionData.some(
    (item: QuestionType) => item.question.question_format === 'SELECT_4_OPTIONS_WITH_AUDIO'
  )

  const getQuestionTest = (e: TestParams) => {
    setLoading(true)
    getTests({ chapter_id: e.chapter_id, video_id: e.video_id })
      .then(res => {
        if (res) {
          setTestId(res.id)
          setQuestionData(res.test_detail)
        }
      })
      .catch(e => {
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getQuestionTest({
      chapter_id: location.state.chapterId,
      video_id: location.state.videoId
    })
    // dispatch(changeOpenModal(true))
    // return () => {
    //   dispatch(changeOpenModal(false))
    // }
  }, [])

  const nextStep = () => {
    if (
      isTestAudio
        ? selectedQuestion === questionData?.length + 1
        : selectedQuestion === questionData.length - 1
    ) {
      // dispatch(changeOpenModal(false))
      // refModal?.current?.setSubmit(() => {
      //   handleSubmitAnswers()
      // })
      // refModal?.current?.onOpen()
      handleSubmitAnswers()
      return
    }

    setSelectedQuestion(selectedQuestion + 1)
  }

  // const backStep = () => {
  //   setSelectedQuestion(selectedQuestion - 1)
  // }

  const handleSubmitAnswers = async () => {
    try {
      setLoading(true)
      const value = form.getFieldsValue(true)
      const body = {
        test_details: formatBody(value)
      }

      const res = await submitTest(body, testId)
      if (res) {
        setTotalPoint(res.score)
        // PopupNoti.success('成功しました。')
        setSelectedQuestion(selectedQuestion + 1)
      }
    } catch (error: any) {
      // PopupNoti.success(error.message)
    } finally {
      setLoading(false)
    }
  }

  const arrayIndexAudio: any[] = questionData.reduce((acc, item: QuestionType, idx: number) => {
    if (item.question.question_format === 'SELECT_4_OPTIONS_WITH_AUDIO') {
      acc.push(idx)
    }
    return acc
  }, [])

  return (
    <WrapperCustom className='content-wrapper'>
      <Spin spinning={false}>
        {/* {!refAnswersFormat.current?.length ? (
          <ModalCloseExam>
            <span className={'btnClose'}>
              <IconClose />
            </span>
          </ModalCloseExam>
        ) : (
          <span className={'btnClose'} onClick={() => navigate('/')}>
            <IconClose />
          </span>
        )} */}

        {isTestAudio ? (
          <>
            <Form form={form}>
              {questionData
                .slice(0, arrayIndexAudio[0])
                .map((question: QuestionType, idx: number) => (
                  <Question
                    key={question.id}
                    testDetailId={question.id}
                    isShow={idx === selectedQuestion}
                    question={question.question}
                    keyIdx={idx + 1}
                    nextStep={nextStep}
                    // backStep={backStep}
                    form={form}
                  />
                ))}
              {selectedQuestion === arrayIndexAudio[0] && (
                <div className='flex-box'>
                  <div style={{ fontSize: 16 }}>音声を聞いて5問の設問に</div>
                  <div style={{ fontSize: 16 }}>回答ください</div>
                  <audio controls style={{ margin: '24px 0' }}>
                    <source
                      src={questionData[arrayIndexAudio[0]]?.question.file}
                      type='audio/mpeg'
                    />
                  </audio>
                  <div className='flex-action'>
                    <div></div>
                    <div
                      onClick={nextStep}
                      style={{
                        display: 'flex',
                        cursor: 'pointer'
                      }}
                    >
                      <div>次&nbsp;</div>
                      <img src={process.env.PUBLIC_URL + '/images/next.svg'} alt='' />
                    </div>
                  </div>
                </div>
              )}
              {questionData
                .slice(arrayIndexAudio[0], arrayIndexAudio[arrayIndexAudio.length - 1] + 1)
                .map((question: QuestionType, idx: number) => (
                  <Question
                    key={question.id}
                    testDetailId={question.id}
                    isShow={idx + arrayIndexAudio[0] + 1 === selectedQuestion}
                    question={question.question}
                    keyIdx={idx + arrayIndexAudio[0] + 1}
                    nextStep={nextStep}
                    // backStep={backStep}
                    form={form}
                  />
                ))}
              {selectedQuestion === arrayIndexAudio[arrayIndexAudio.length - 1] + 2 && (
                <div className='flex-box'>
                  <div
                    className='contentQuestion'
                    dangerouslySetInnerHTML={{
                      __html: questionData[arrayIndexAudio[0]]?.question.scripts
                        .split('\n')
                        ?.join('<br />')
                    }}
                  />

                  <audio controls style={{ margin: '24px 0' }}>
                    <source
                      src={questionData[arrayIndexAudio[0]]?.question.file}
                      type='audio/mpeg'
                    />
                  </audio>
                  <div className='flex-action'>
                    <div></div>
                    <div
                      onClick={nextStep}
                      style={{
                        display: 'flex',
                        cursor: 'pointer'
                      }}
                    >
                      <div>次&nbsp;</div>
                      <img src={process.env.PUBLIC_URL + '/images/next.svg'} alt='' />
                    </div>
                  </div>
                </div>
              )}
              {arrayIndexAudio[arrayIndexAudio.length - 1] < questionData.length &&
                questionData
                  .slice(arrayIndexAudio[arrayIndexAudio.length - 1] + 1, questionData.length)
                  .map((question: QuestionType, idx: number) => (
                    <Question
                      key={question.id}
                      testDetailId={question.id}
                      isShow={
                        idx + arrayIndexAudio[arrayIndexAudio.length - 1] + 3 === selectedQuestion
                      }
                      question={question.question}
                      keyIdx={idx + arrayIndexAudio[arrayIndexAudio.length - 1] + 2}
                      nextStep={nextStep}
                      // backStep={backStep}
                      form={form}
                    />
                  ))}
            </Form>

            {selectedQuestion === questionData?.length + 2 && !!questionData.length && (
              <ResultTest
                totalPoint={totalPoint}
                totalQuestion={questionData.length}
                loading={loading}
              />
            )}
          </>
        ) : (
          <>
            <Form form={form}>
              {questionData?.map((question: QuestionType, idx: number) => (
                <Question
                  key={question.id}
                  testDetailId={question.id}
                  isShow={idx === selectedQuestion}
                  question={question.question}
                  keyIdx={idx + 1}
                  nextStep={nextStep}
                  // backStep={backStep}
                  form={form}
                />
              ))}

              {selectedQuestion === questionData?.length && !!questionData.length && (
                <ResultTest
                  totalPoint={totalPoint}
                  totalQuestion={questionData.length}
                  loading={loading}
                />
              )}
            </Form>
          </>
        )}
      </Spin>
      <ModalCloseExam ref={refModal} />
    </WrapperCustom>
  )
}

export default QuestionTest
