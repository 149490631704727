import { notification } from 'antd'

export const PopupNoti = {
  success: (description: string): void =>
    notification.success({
      duration: 3,
      message: '',
      description,
      placement: 'topRight'
    }),
  error: (description: string): void =>
    notification.error({
      duration: 3,
      message: '',
      description,
      placement: 'topRight'
    })
}
