import React from 'react'
import { OnlineLesson } from '@/interface/onlineLessonInterface'
import { Col, Row, Button } from 'antd'
import Moment from 'react-moment'
import { getWeekDay, getHours } from '@/utils/helpers'
import { SCREEN_SIZE } from '@/utils/screenSize'
import { useMediaQuery } from 'react-responsive'
import { PopupNoti } from '@/components/common/Popup/PopupNoti'

type ItemListProps = {
  item: OnlineLesson
}

const onCheckLink = item => {
  if (item === null) PopupNoti.error('このレッスンのアーカイブは公開されていません')
}

const OnlineLessonList: React.FC<ItemListProps> = ({ item }) => {
  const dateToFormat = new Date(item?.start_date)
  const day = new Date(item?.start_date).getDay()
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })
  const width = window.innerWidth - 60

  if (!isMobile) {
    return (
      <div className='activity-item-web'>
        <div className='home-web-wrapper'>
          <Row className='archive-content-wrapper'>
            <Col span={8}>
              <div className='lesson-archives'>
                <div className='title'>{item?.title}</div>
                <div className='list'></div>
              </div>
            </Col>
            <Col span={16}>
              <div className='data'>
                <div className='item'>
                  <Row>
                    <Col>
                      {/* <div className='text'>9月10日（火） 11:00~12:00</div> */}
                      <div className='text'>
                        <span>
                          <Moment format='M月D日' date={dateToFormat} /> {'('}
                          {day === 0 || day === 6 ? (
                            <span className='weekeend'>{getWeekDay(item?.start_date)}</span>
                          ) : (
                            <span>{getWeekDay(item?.start_date)}</span>
                          )}
                          {')'} {''}
                          {getHours(item?.start_date)}~{getHours(item?.end_date)}
                        </span>
                      </div>
                    </Col>
                    <Col className='data-icon-space'>
                      <div>
                        <span className='video-learning-icon' />
                      </div>
                    </Col>
                    <Col className='data-button-space'>
                      <div>
                        <Button
                          href={item?.link_video}
                          onClick={() => onCheckLink(item?.link_video)}
                          className='button-view'
                        >
                          <span className='button-text'>視聴</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>

                {/* </div> */}
                {/* <div className="process-list"></div> */}
              </div>
            </Col>
            <br></br>
          </Row>
        </div>
      </div>
    )
  } else {
    return (
      <Row
        style={{
          paddingTop: 20,
          paddingBottom: 40,
          marginBottom: 60,
          marginLeft: 15
        }}
        className='mobile-archive-content-wrapper'
      >
        <Col span={8}>
          <div className='mobile-data' style={{ width }}>
            <div className='item'>
              <Row>
                <Col>
                  <div className='mobile-lesson-archive' style={{ width }}>
                    <div className='title'>{item.title}</div>
                  </div>

                  <Row>
                    <Col
                      style={{
                        paddingTop: 20,
                        paddingLeft: 50
                      }}
                    ></Col>
                    <div className='text'>
                      <span>
                        <Moment format='M月D日' date={dateToFormat} /> {'('}
                        {day === 0 || day === 6 ? (
                          <span className='weekeend'>{getWeekDay(item?.start_date)}</span>
                        ) : (
                          <span>{getWeekDay(item?.start_date)}</span>
                        )}
                        {')'} {''}
                        {getHours(item?.start_date)}~{getHours(item?.end_date)}
                      </span>
                    </div>
                    <Col
                      style={{
                        paddingTop: 20,
                        paddingLeft: 40
                      }}
                    >
                      <div>
                        <span className='video-learning-icon' />
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <Button
                      href={item?.link_video}
                      onClick={() => onCheckLink(item?.link_video)}
                      className='button-view'
                    >
                      <span className='button-text'>視聴</span>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default OnlineLessonList
