import React, { useState } from 'react'
import { Menu, Dropdown, Modal, MenuProps, Button } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { LockFilled, LogoutOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { userLogout } from '@/stores/login'
import i18Next from '@/i18n'

export const menuItem = [
  {
    title: 'common.profile.changePassword',
    path: '/change-password'
  }
]

export const NavProfile = () => {
  const { loading } = useAppSelector(state => state.login)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isShowModal, setIsShowModal] = useState(false)

  const handleOk = () => {
    dispatch(userLogout()).then(() => {
      navigate('/login')
    })
  }

  const handleCancel = () => {
    setIsShowModal(false)
  }

  const handleMenuClick: MenuProps['onClick'] = e => {
    console.log('click', e)
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i} className='menu-header'>
                <Link to={el.path}>
                  <LockFilled className='icon' />
                  <span
                    className='text'
                  >
                    パスワードの変更
                  </span>
                </Link>
              </Menu.Item>
            )
          })}
        </>
      ),
      key: '1'
    },
    {
      label: (
        <Menu.Item
          className='menu-header'
          key={menuItem.length + 1}
          onClick={() => setIsShowModal(true)}
        >
          <LogoutOutlined className='icon' />
          <span className='text'>ログアウト</span>
        </Menu.Item>
      ),
      key: '2'
    }
  ]

  const menuProps = {
    items,
    onClick: handleMenuClick
  }

  return (
    <>
      <Dropdown placement='bottomRight' menu={menuProps} trigger={['click']}>
        <Menu className='d-flex align-item-center'>
          <Menu.Item key='profile'>
            <span className='mypage-icon header-icon' />
          </Menu.Item>
        </Menu>
      </Dropdown>
      <Modal
        title=''
        open={isShowModal}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        footer={[
          <Button key='cancel' onClick={handleCancel}>
            {i18Next.t('modal.cancel')}
          </Button>,
          <Button key='ok' type='default' loading={loading} onClick={handleOk}>
            OK
          </Button>
        ]}
      >
        <p>{i18Next.t('login.logoutMsg')}</p>
      </Modal>
    </>
  )
}

export default NavProfile
