import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as API from '@/api/authAPI'

const initialState = {
  loading: false,
  error: null,
  success: false
}

export const userForgotPassword = createAsyncThunk(
  'auth/forgot-password',
  async ({ mailAddress}: any, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line camelcase
      const response = await API.forgotPassword({ mail_address: mailAddress })
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


const authSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {},
  extraReducers: {
    [userForgotPassword.pending as any]: (state: any) => {
      state.loading = true
      state.error = null
    },
    [userForgotPassword.rejected as any]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    }
  }
})

export default authSlice.reducer
