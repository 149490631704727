import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as API from '@/api/contactAPI'

const initialState = {
  loading: false,
  error: null as any,
  success: false
}

export const getInfo = createAsyncThunk(
  'info',
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.getInfoMyPage()
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

const userSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {},
  extraReducers: builder => {
   
    builder.addCase(getInfo.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getInfo.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(getInfo.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })
  }
})

export default userSlice.reducer
