import React, { useEffect } from 'react'
import { userLogout } from '@/stores/login'
import { useAppDispatch } from '@/app/hooks'
import { useNavigate } from 'react-router-dom'

const LogoutView: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(userLogout())
    navigate('/login')
  }, [])

  return <></>
}

export default LogoutView
