import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as API from '@/api/videoAPI'
import { Video, UpdatePayload } from '@/interface/videoInterface'

const initialState = {
  loading: false,
  videoDetail: null as Video | any,
  error: null as any,
  success: false
}

export const getVideoDetail = createAsyncThunk(
  'videoDetail/get',
  async (payload: number, { rejectWithValue }) => {
    try {
      const response = await API.getVideoDetail(payload)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updatePlayedTime = createAsyncThunk(
  'videoDetail/updatePlayedTime',
  async (payload: UpdatePayload, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line camelcase
      const response = await API.updatePlayedTime(payload.id, payload.data)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateVideoView = createAsyncThunk(
  'videoDetail/updateVideoView',
  async (payload: number, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line camelcase
      const response = await API.updateVideoView(payload)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

const videoDetailSlice = createSlice({
  name: 'videoDetail',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getVideoDetail.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getVideoDetail.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.videoDetail = payload
    })
    builder.addCase(getVideoDetail.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })
  }
})

export default videoDetailSlice.reducer
