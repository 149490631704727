import { ROLE_USER } from '@/utils/constants'

export const userRole = () => {
  let user: any
  const _user = localStorage.getItem('user')
  if (_user) {
    user = JSON.parse(_user)
  }
  if (user?.role?.authority == ROLE_USER.USER_META_VERSE) {
    return user.role.authority
  } else {
    return ROLE_USER.USER_VIRTUAL
  }
}

