import React from 'react'
import { MessageDetailWrapper } from '../styled'
import { useAppSelector } from '@/app/hooks'
import { downloadAttachment } from '@/utils/helpers'

const MessageDetail = () => {
  const { bulletinDetail, bulletinData } = useAppSelector(state => state.bulletin)

  return (
    <MessageDetailWrapper>
      <div className='box-container'>
        <div className='box-tag'>
          <div className='box-circle' />
        </div>

        <div style={{ fontSize: '20px' }}>{bulletinData.length ? bulletinDetail.title : ''}</div>

        <div
          className='content-border'
          dangerouslySetInnerHTML={{
            __html: bulletinData.length ? bulletinDetail.content.split('\n')?.join('<br />') : ''
          }}
        />

        <div className='box-container-footer'>
          <div className='box-circle' />
          {bulletinDetail?.notification?.file_manage && (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                downloadAttachment(bulletinDetail?.notification?.file_manage)
              }}
            >
              添付ファイル：ダウンロード
            </div>
          )}
          <div className='box-circle' />
        </div>
      </div>
    </MessageDetailWrapper>
  )
}

export default MessageDetail
