import React, { useEffect } from 'react'
import { getInfo } from '@/stores/userStore'
import { useAppDispatch } from '@/app/hooks'

type LessonTitleProps = {
  id: number
  type: string
  text: string
  handleClick: (id: string | number) => void
}

export const LessonTitle: React.FC<LessonTitleProps> = ({ id, type, text, handleClick }) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getInfo())
  }, [])

  return (
    <div className={`lesson-item-list-web mb3 ${type}`} onClick={() => handleClick(type)}>
      <div className='icon-wrapper'>
        {id == 1 ? (
          <img className='lesson-archive-icon'></img>
        ) : (
          <img className='lesson-participation-icon'></img>
        )}
      </div>
      <div className='name-wrapper'>
        {id == 1 ? <span className='name-downline'>{text}</span> : <span>{text}</span>}
      </div>
    </div>
  )
}

export default LessonTitle
