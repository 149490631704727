import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { ModalCustom } from './styled'

// eslint-disable-next-line no-empty-pattern
const ModalCloseExam = ({}: any, ref: any) => {
  const [visible, setVisible] = useState(false)
  const refSubmit: any = useRef()

  useImperativeHandle(ref, () => {
    return {
      onOpen: () => setVisible(true),
      setSubmit: (func: any) => {
        refSubmit.current = func
      }
    }
  })

  const onVisible = () => {
    setVisible(!visible)
  }

  return (
    <ModalCustom
      className='modalClose'
      title=''
      open={visible}
      footer={
        <div className='btnActions'>
          <div
            className='buttonModal'
            onClick={() => {
              if (refSubmit.current) {
                refSubmit.current()
                setVisible(false)
              }
            }}
          >
            Xác nhận
          </div>
          <div className='buttonModal' onClick={onVisible}>
            Hủy
          </div>
        </div>
      }
      onCancel={onVisible}
      closeIcon={<></>}
      centered
    >
      <p>Bạn có chắc chắn muốn nộp bài</p>
    </ModalCustom>
  )
}

export default forwardRef(ModalCloseExam)
