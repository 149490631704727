import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as API from '@/api/authAPI'

const initialState = {
  loading: false,
  accountInfo: null as any,
  error: null,
  success: false
}

export const userChangePassword = createAsyncThunk(
  'auth/change-password',
  async ({ oldPassword, password }: any, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line camelcase
      const response = await API.changePassword({ old_password: oldPassword, password })
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

const authSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {},
  extraReducers: {
    [userChangePassword.pending as any]: (state: any) => {
      state.loading = true
      state.error = null
    },
    [userChangePassword.fulfilled as any]: (state: any, { payload }) => {
      state.loading = false
      state.accountInfo = payload.accountInfo
      state.accessToken = payload.accessToken
    },
    [userChangePassword.rejected as any]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    }
  }
})

export default authSlice.reducer
