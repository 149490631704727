import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '@/app/hooks'
import firebase from 'firebase/app'
import { updateToken } from '@/stores/notificationStore'

const HomeFireBaseView: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  let user: any
  const _user = localStorage.getItem('user')
  if (_user) {
    user = JSON.parse(_user)
    const messaging = firebase.messaging()
    console.log(user)
    Notification.requestPermission()
      .then(function () {
        // get the token in the form of promise
        return messaging.getToken()
      })
      .then(function (token) {
        console.log(`token: ${token}`)
        dispatch(updateToken(token))
      })
      .catch(function (err) {
        console.log('Unable to get permission to notify.', err)
      })
  }

  useEffect(() => {
    const isAuth = localStorage.getItem('access_token')
    if (isAuth && isAuth !== 'undefined') {
      navigate('/')
    }
  }, [])

  return <div></div>
}

export default HomeFireBaseView
