import firebase from 'firebase/app'
import '@firebase/messaging'

export const firebaseConfig = {
  apiKey: 'AIzaSyCBrjpBT9haZM9T9LmWKZcHCCivFZ7XmHw',
  authDomain: 'emi-test-8480d.firebaseapp.com',
  projectId: 'emi-test-8480d',
  storageBucket: 'emi-test-8480d.appspot.com',
  messagingSenderId: '419426268373',
  appId: '1:419426268373:web:02042b6e2553f336c01e5c',
  measurementId: 'G-7Z1RTVLVMM'
}

export default firebase
