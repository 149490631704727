import request from '@/api/config/request'
import type { ResponseList } from '@/interface/commonInterface'
import {
  CreateAPIPayload,
  CreateMessageAPIPayload,
  UploadFileAPIPayload,
  GetMessagePayload
} from '@/interface/contactInterface'

export const sendContact = (payload: CreateAPIPayload) => {
  const url = '/contacts'
  return request<ResponseList, ResponseList>({
    url,
    method: 'POST',
    data: {
      name: payload.name,
      phone: payload.phone,
      email: payload.email,
      message: payload.message
    }
  })
}

export const getRooms = () => {
  const url = '/messages/rooms'
  return request<ResponseList, ResponseList>({
    url,
    method: 'GET'
  })
}

export const getMessages = (payload: GetMessagePayload) => {
  const url = '/messages'
  return request<ResponseList, ResponseList>({
    url,
    method: 'GET',
    // eslint-disable-next-line camelcase
    params: { room: payload.roomId, page: payload.currentPage }
  })
}

export const createMessage = (payload: CreateMessageAPIPayload) => {
  const url = '/messages'
  return request<ResponseList, ResponseList>({
    url,
    method: 'POST',
    data: {
      content: payload.content,
      room: payload.room_id
    }
  })
}

export const uploadFile = (payload: UploadFileAPIPayload) => {
  const url = '/messages/file'
  return request<ResponseList, ResponseList>({
    url,
    method: 'POST',
    data: {
      file: payload.file,
      room: payload.room_id
    },
    headers: {
      'Content-type': 'multipart/form-data'
    }
  })
}

export const getInfoMyPage = () => {
  const url = '/my-page'
  return request<any, any>({
    url,
    method: 'GET'
  })
}
