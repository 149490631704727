import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as API from '@/api/departmentAPI'
import type { ResponseList } from '@/interface/commonInterface'

const initialState = {
  loading: false,
  departmentList: [] as ResponseList,
  error: null as any,
  success: false
}

export const getDepartments = createAsyncThunk('department/get', async (_, { rejectWithValue }) => {
  try {
    // eslint-disable-next-line camelcase
    const response = await API.getDepartments()
    return response
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

const departmentSlice = createSlice({
  name: 'departmentaas',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getDepartments.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getDepartments.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.departmentList = payload
    })
    builder.addCase(getDepartments.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })
  }
})

export default departmentSlice.reducer
