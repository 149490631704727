import React, { useEffect } from 'react'
import { Form, Row, Col, Spin } from 'antd'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { getOnlineLessons } from '@/stores/onlineLessonStore'
import OnlineLessonList from '@/components/common/OnlineLessonList/LessonArchive'
import { SCREEN_SIZE } from '@/utils/screenSize'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'
import { GetPayload } from '@/interface/onlineLessonInterface'
import { getHours } from '@/utils/helpers'

const OnlineLessonArchiveView: React.FC = () => {
  const dispatch = useAppDispatch()
  const { onlineLessonList, loading } = useAppSelector(state => state.onlineLesson)
  const typeRoute = 'ARCHIVE'
  const location = useLocation()
  const departmentId = location.state.departmentId
  // const height = window.innerHeight - 140
  useEffect(() => {
    const data: GetPayload = {
      type_route: typeRoute,
      key: 'department_id',
      data: departmentId
    }
    dispatch(getOnlineLessons(data))
  }, [])

  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })
  const listData = onlineLessonList?.data
  // sort multi key
  const results = listData
    ?.slice()
    ?.sort(
      (a, b) =>
        parseInt(b?.start_date?.substring(5, 7)) - parseInt(a?.start_date?.substring(5, 7)) ||
        parseInt(b?.start_date?.substring(8, 10)) - parseInt(a?.start_date?.substring(8, 10)) ||
        parseInt(getHours(a?.start_date)?.split(':')[0]) * 60 +
          parseInt(getHours(a?.start_date)?.split(':')[1]) -
          (parseInt(getHours(b?.start_date)?.split(':')[0]) * 60 +
            parseInt(getHours(b?.start_date)?.split(':')[1]))
    )

  const elHeight = document.getElementById('bg-height')?.clientHeight
  if (!isMobile) {
    return (
      // <div className='content-wrapper background-blue' style={{ height: '100vh' }}>
      <div
        className='content-wrapper background-blue'
        style={{
          height: ''
        }}
      >
        <Form.Item>
          <Form.Item className='lesson-archive-title'>
            <Row className='lesson-header'>
              <Col span={3} style={{ marginLeft: 10 }}>
                {' '}
                <span className='lesson-icon' />
              </Col>
              <Col span={6}>
                {' '}
                <div className='text'>アーカイブ視聴</div>
              </Col>
            </Row>
            <Row className='text-selected-lesson'>
              アーカイブの中から視聴したいビデオを選びましょう。
            </Row>
          </Form.Item>

          <Form.Item className='archive-list-wrapper scroll'>
            <Form.Item className='form-input' style={{ marginBottom: 100 }}>
              <div className='mobile-header'>
                <span className='lesson-icon menu-icon' />
                <div className='text'>オンラインレッスン</div>
              </div>
              <Spin spinning={loading}>
                <div>
                  {results?.map((item, index) => (
                    <OnlineLessonList key={index} item={item} />
                  ))}
                </div>
              </Spin>
            </Form.Item>
          </Form.Item>
        </Form.Item>
      </div>
    )
  } else {
    return (
      <div className='content-wrapper background-blue' style={{ height: elHeight }}>
        <Form.Item>
          <Form.Item className='archive-list-wrapper scroll' style={{ backgroundColor: '#edf9f7' }}>
            <Form.Item className='form-input' style={{ marginBottom: 80 }}>
              <div className='mobile-header'>
                <span className='lesson-icon menu-icon' />
                <div className='text'>オンラインレッスン</div>
              </div>
              <Spin spinning={loading}>
                <div style={{ backgroundColor: '#edf9f7' }}>
                  {results?.map((item, index) => (
                    <OnlineLessonList key={index} item={item} />
                  ))}
                </div>
              </Spin>
            </Form.Item>
          </Form.Item>
        </Form.Item>
      </div>
    )
  }
}

export default OnlineLessonArchiveView
