import request from './config/request'
// import type { UserDetail } from '@/libs/interface/userInterface'

export function login(payload: object) {
  const url = '/auth/login'
  return request<any, any>({
    url,
    method: 'POST',
    data: payload
  })
}

export function changePassword(payload: object) {
  const url = '/auth/change-password'
  return request<any, any>({
    url,
    method: 'POST',
    data: payload
  })
}

export function forgotPassword(payload: object) {
  const url = '/auth/forgot-password'
  return request<any, any>({
    url,
    method: 'POST',
    data: payload
  })
}

export function updatePassword(payload: object) {
  const url = '/auth/update-password'
  return request<any, any>({
    url,
    method: 'POST',
    data: payload
  })
}

export const logout = () => {
  const url = '/auth/logout'
  return request({
    url,
    method: 'POST'
  })
}
