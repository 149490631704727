import React from 'react'
import { ListItemParams } from '@/interface/commonInterface'
import { SCREEN_SIZE } from '@/utils/screenSize'
import { useMediaQuery } from 'react-responsive'
import { Col, Row, Form } from 'antd'
import { ROLE_TYPE } from '@/utils/constants'

type ItemListProps = {
  item: ListItemParams
  schoolName: string
  customClass?: string
  iconSvg?: string
  hideName?: boolean
  handleClick: (id: string | number) => void
  roomClick?: (item: any) => void
}
const LessonListItem: React.FC<ItemListProps> = ({
  item,
  schoolName,
  handleClick,
  roomClick,
  customClass = '',
  iconSvg = '',
  hideName = false
}) => {
  let user: any
  const _user = localStorage.getItem('user')
  if (_user) {
    user = JSON.parse(_user)
  }
  const userType = user?.role?.type
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })

  // not seen by users
  if (
    userType == ROLE_TYPE.GUARDIAN ||
    userType == ROLE_TYPE.STAFF ||
    userType == ROLE_TYPE.TEACHER
  ) {
    return !isMobile ? (
      <div
        className={`item-list-web ${customClass ? customClass : ''}`}
        onClick={() => {
          if (roomClick) roomClick(item)
          else handleClick(item.id)
        }}
      >
        <Form.Item>
          <Row gutter={[26, 26]}>
            <Col span={8} className='icon-wrapper'>
              {/* <div> */}
              {iconSvg ? (
                <span className={`${iconSvg}-icon svg-icon`} />
              ) : (
                <img src={`${item.icon_url}`} alt={item.name} />
              )}
              {/* </div> */}
            </Col>
            <Col span={16} className='name-wrapper'>
              {!hideName ? (
                // <div>
                <div className='department'>{item.name}</div>
              ) : (
                // </div>
                <></>
              )}
            </Col>
          </Row>

          <div>
            {schoolName !== '' && (
              <div className='school-name'>{` ${'(' + schoolName + ')'} `}</div>
            )}
          </div>
        </Form.Item>
      </div>
    ) : (
      <div
        className={`lesson-item-list ${customClass ? customClass : ''}`}
        onClick={() => {
          if (roomClick) roomClick(item)
          else handleClick(item.id)
        }}
      >
        <div className='icon-wrapper'>
          {iconSvg ? (
            <span className={`${iconSvg}-icon svg-icon`} />
          ) : (
            <img src={`${item.icon_url}`} alt={item.name} />
          )}
        </div>
        {!hideName ? (
          <div className='name-wrapper'>
            <div className='department'>{item.name}</div>
            <div className='school-name'>{schoolName !== '' && '(' + schoolName + ')'}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  } else {
    return !isMobile ? (
      <div
        className={`item-list-web ${customClass ? customClass : ''}`}
        onClick={() => {
          if (roomClick) roomClick(item)
          else handleClick(item.id)
        }}
      >
        <div className='icon-wrapper'>
          {iconSvg ? (
            <span className={`${iconSvg}-icon svg-icon`} />
          ) : (
            <img src={`${item.icon_url}`} alt={item.name} />
          )}
        </div>
        {!hideName ? (
          <div className='name-wrapper'>
            <span>{item.name}</span>
          </div>
        ) : (
          <></>
        )}
      </div>
    ) : (
      <div
        className={`lesson-item-list ${customClass ? customClass : ''}`}
        onClick={() => {
          if (roomClick) roomClick(item)
          else handleClick(item.id)
        }}
      >
        <div className='icon-wrapper'>
          {iconSvg ? (
            <span className={`${iconSvg}-icon svg-icon`} />
          ) : (
            <img src={`${item.icon_url}`} alt={item.name} />
          )}
        </div>
        {!hideName ? (
          <div className='name-wrapper'>
            <div className='department'>{item.name}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  }
}

export default LessonListItem
