import request from '@/api/config/request'
import type { ResponseList } from '@/interface/commonInterface'
import { UpdateAPIPayload, GetVideoPayload } from '@/interface/videoInterface'

export const getVideos = (payload: GetVideoPayload) => {
  const url = '/videos'
  return request<ResponseList, ResponseList>({
    url,
    method: 'GET',
    // eslint-disable-next-line camelcase
    params: { chapter_id: payload.chapterId, page: payload.currentPage }
  })
}

export const getVideoDetail = videoId => {
  const url = `/videos/${videoId}`
  return request<ResponseList, ResponseList>({
    url,
    method: 'GET'
  })
}

export const updatePlayedTime = (videoId, payload: UpdateAPIPayload) => {
  const url = `/videos/played-time/${videoId}`
  return request<ResponseList, ResponseList>({
    url,
    method: 'POST',
    data: {
      // eslint-disable-next-line camelcase
      played_time: payload.playedTime,
      // eslint-disable-next-line camelcase
      process_percent: payload.processPercent,
      watched: payload.watched
    }
  })
}

export const updateFavorite = (videoId, payload: UpdateAPIPayload) => {
  const url = `/videos/favorite/${videoId}`
  return request<ResponseList, ResponseList>({
    url,
    method: 'POST',
    data: {
      // eslint-disable-next-line camelcase
      is_favorite: payload.isFavorite
    }
  })
}

export const updateVideoView = videoId => {
  const url = `/videos/views/${videoId}`
  return request<ResponseList, ResponseList>({
    url,
    method: 'PUT'
  })
}
