import request from '@/api/config/request'
import { TestParams } from '@/interface/testInterface'

export const getTests = (payload: TestParams) => {
  const url = '/tests'
  return request<any, any>({
    url,
    method: 'GET',
    params: { chapter_id: payload.chapter_id, video_id: payload.video_id }
  })
}

export const submitTest = (payload: object, testId: number) => {
  const url = `/tests/${testId}`
  return request<any, any>({
    url,
    method: 'PUT',
    data: payload
  })
}
