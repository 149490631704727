import firebase from 'firebase/app'
import 'firebase/messaging'
import { firebaseConfig } from './constants'

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
} else {
  firebase.app() // if already initialized, use that one
}

let messaging

if (typeof window !== 'undefined') {
  if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging()
  }
}

export const onMessageListener = () =>
  new Promise(resolve => {
    messaging?.onMessage(payload => {
      resolve(payload)
      const notificationTitle = payload.notification.title
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon
      }
      const actionLink = new Notification(notificationTitle, notificationOptions)
      // actionLink.onclick = () => (window.location = payload.notification.click_action)
      actionLink.onclick = () => window.open(payload.notification.click_action)
    })
  })
