import React from 'react'
import { Provider } from 'react-redux'
import { store } from './app/store'
import App from './app/App'
import reportWebVitals from './reportWebVitals'
import '@/assets/scss/main.scss'
import ReactDOM from 'react-dom/client'
import './i18n'

const container = document.getElementById('root')!
const root = ReactDOM.createRoot(container)

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App')
}
