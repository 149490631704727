import { combineReducers } from '@reduxjs/toolkit'
import loginReducer from '../stores/login'
import changePasswordReducer from '../stores/changePassword'
import forgotPasswordReducer from '../stores/forgotPassword'
import commonReducer from '../stores/common'
import departmentReducer from '../stores/departmentStore'
import subjectReducer from '../stores/subjectStore'
import chapterStore from '../stores/chapterStore'
import videoStore from '../stores/videoStore'
import videoDetailStore from '../stores/videoDetailStore'
import classRoomStore from '../stores/classRoomStore'
import onlineLessonStore from '../stores/onlineLessonStore'
import virtualStore from '../stores/virtualStore'
import myPageStore from '@/stores/myPageStore'
import bulletinStore from '@/stores/bulletinStore'
import contactStore from '@/stores/contactStore'

const rootReducer = combineReducers({
  login: loginReducer,
  changePassword: changePasswordReducer,
  forgotPassword: forgotPasswordReducer,
  common: commonReducer,
  department: departmentReducer,
  subject: subjectReducer,
  chapter: chapterStore,
  video: videoStore,
  videoDetail: videoDetailStore,
  classRoom: classRoomStore,
  onlineLesson: onlineLessonStore,
  virtual: virtualStore,
  myPage: myPageStore,
  bulletin: bulletinStore,
  contact: contactStore
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
