/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import tempImg from '@/assets/imgs/avatar.png'
import { getInfoMyPage } from '@/stores/myPageStore'
import { CaretRightOutlined } from '@ant-design/icons'
import { Col, Collapse, Progress, Row, Select, Spin, Tooltip } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { MyPageWrapper } from './styled'
import StatisticTest from './components/StatisticTest'

const MyPageView: React.FC = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const dispatch = useAppDispatch()
  const [activeKeyVideo, setActiveKeyVideo] = useState<string | string[]>([])
  const { data, loading } = useAppSelector(state => state.myPage)

  useEffect(() => {
    dispatch(getInfoMyPage({ userId: user?.students?.length ? user.students[0].id : user.id }))
  }, [])

  const onChangeVideo = (key: string | string[]) => {
    setActiveKeyVideo(key)
  }

  const onChange = value => {
    dispatch(getInfoMyPage({ userId: value }))
  }

  return (
    <MyPageWrapper className='content-wrapper' style={{ height: 'auto' }}>
      {!isEmpty(data) && (
        <div className='mypage-wrapper'>
          <div className='mobile-header'>
            <span className='mypage-icon menu-icon' />
            <div className='text'>マイページ</div>
          </div>
          <div className='user-info'>
            <div className='img-wrapper'>
              <img src={tempImg} alt='tempImg' />
            </div>
            <div className='name'>
              {user?.students?.length ? (
                <Select
                  defaultValue={user.students[0].id}
                  bordered={false}
                  onChange={onChange}
                  options={user.students.map(item => {
                    return {
                      value: item.id,
                      label: `${item.first_name}`
                    }
                  })}
                />
              ) : (
                <div className='name'>{`${user?.first_name}`}</div>
              )}
            </div>
          </div>

          <Spin spinning={loading}>
            <h3 className='title'>動画視聴状況</h3>
            <Row className='statistic-wrapper'>
              <Col span={8}>
                <div className='subjects'>
                  <div className='title'>&nbsp;</div>
                  <div className='list'>
                    <Collapse
                      ghost
                      onChange={onChangeVideo}
                      activeKey={activeKeyVideo}
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                      )}
                    >
                      {data.videos.map(video => {
                        return (
                          <Collapse.Panel
                            showArrow={!!video.chapters && !!video.chapters.length}
                            header={<Tooltip title={video.name}>{video.name}</Tooltip>}
                            key={`video-${video.id}`}
                          >
                            <ol>
                              {!!video.chapters &&
                                !!video.chapters.length &&
                                video.chapters.map((item, idx) => {
                                  return (
                                    <li key={item.name}>
                                      <Tooltip title={`${idx + 1}章 ${item.name}`}>
                                        <div className='subject-name'>{`${idx + 1}章 ${
                                          item.name
                                        }`}</div>
                                      </Tooltip>
                                    </li>
                                  )
                                })}
                            </ol>
                          </Collapse.Panel>
                        )
                      })}
                    </Collapse>
                  </div>
                </div>
              </Col>
              <Col span={16} style={{ paddingLeft: 10 }}>
                <div className='data'>
                  <div className='note'>
                    <div className='note-item'>
                      <div className='text'>視聴済み</div>
                      <div className='text-gray'>未視聴</div>
                    </div>
                  </div>
                  <div className='process-list'>
                    <Collapse ghost activeKey={activeKeyVideo} expandIcon={() => <></>}>
                      {data.videos.map((item, index) => (
                        <Collapse.Panel
                          header={
                            <div className='process-item' key={index}>
                              <Progress
                                percent={Math.ceil((item.count_viewed / item.count_video) * 100)}
                                showInfo={false}
                                strokeColor={'#53D3D3'}
                                trailColor={'#B4B4B5'}
                              />
                              <div
                                className='number'
                                style={{
                                  color: '#53D3D3',
                                  left: `calc(${
                                    item.count_viewed
                                      ? Math.ceil((item.count_viewed / item.count_video) * 100)
                                      : 0
                                  }% - ${
                                    item.count_viewed === 0
                                      ? 3
                                      : item.count_viewed === item.count_video
                                      ? 30
                                      : 15
                                  }px)`
                                }}
                              >
                                {Math.ceil((item.count_viewed / item.count_video) * 100) || 0}%
                              </div>
                            </div>
                          }
                          key={`video-${item.id}`}
                        >
                          <ol>
                            {!!item.chapters &&
                              !!item.chapters.length &&
                              item.chapters.map(chapter => {
                                return (
                                  <li key={item.name}>
                                    <div className='process-item' key={chapter.name}>
                                      <Progress
                                        percent={Math.ceil(
                                          (chapter.count_viewed / chapter.count_video) * 100
                                        )}
                                        showInfo={false}
                                        strokeColor={'#53D3D3'}
                                        trailColor={'#B4B4B5'}
                                      />
                                      <div
                                        className='number'
                                        style={{
                                          color: '#53D3D3',
                                          left: `calc(${
                                            chapter.count_viewed
                                              ? Math.ceil(
                                                  (chapter.count_viewed / chapter.count_video) * 100
                                                )
                                              : 0
                                          }% - ${
                                            chapter.count_viewed === 0
                                              ? 3
                                              : chapter.count_viewed === chapter.count_video
                                              ? 30
                                              : 15
                                          }px)`
                                        }}
                                      >
                                        {Math.ceil(
                                          (chapter.count_viewed / chapter.count_video) * 100
                                        ) || 0}
                                        %
                                      </div>
                                    </div>
                                  </li>
                                )
                              })}
                          </ol>
                        </Collapse.Panel>
                      ))}
                    </Collapse>
                  </div>
                </div>
              </Col>
            </Row>

            <StatisticTest />
          </Spin>
        </div>
      )}
    </MyPageWrapper>
  )
}

export default MyPageView
