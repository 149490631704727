import { Checkbox, Col, Row } from 'antd'
import CryptoJS from 'crypto-js'
import styles from './index.module.scss'

export const FormItemCheckBoxQuestion = ({
  value = [],
  onChange,
  showCorrectOption,
  question
}: any) => {
  const encryptedAtob = atob(question.correct_answer)
  const encrypted = JSON.parse(encryptedAtob)
  const iv = CryptoJS.enc.Base64.parse(encrypted.iv)
  const stringAnswer = encrypted.value
  const secret = CryptoJS.enc.Base64.parse(process.env.REACT_APP_KEY_CRYPTO || '')
  const decryptedString = CryptoJS.AES.decrypt(stringAnswer, secret, {
    iv: iv
  })
  const decrypted = decryptedString.toString(CryptoJS.enc.Utf8)

  const onChangeVal = (val: any) => {
    const lastItem = val?.[val?.length - 1] || 0

    return onChange([lastItem])
  }

  return (
    <Checkbox.Group
      value={value}
      disabled={showCorrectOption}
      className='radio-custom'
      onChange={onChangeVal}
    >
      <Row align='middle' justify={'space-between'} className={styles.answerList}>
        {question.answers.map((item: any, idx: any) => (
          <Col key={idx} md={6} xs={12} className={styles.divCenter}>
            <div style={{ padding: '10px', width: '100%' }}>
              <Checkbox value={item}>
                <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                  <div className={styles.txtAnswer}>{idx + 1}</div>
                  {showCorrectOption && item === decrypted && (
                    <div className={styles.imgCorrect}>
                      <img
                        src={process.env.PUBLIC_URL + '/images/correct-ans.png'}
                        alt=''
                        width={24}
                        height={24}
                      />
                    </div>
                  )}
                </div>
              </Checkbox>
            </div>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  )
}
