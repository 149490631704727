export const ROUTE = {
  FORGOT_PASSWORD: {
    PATH: '/forgot-password',
    TITLE: 'forgot-password'
  },
  HOME: '/',
  HOMEFIREBASE: '/home',
  LOGIN: '/login',
  CHANGE: '/change',
  LOG_OUT: '/logout',
  DEPARTMENT: '/departments',
  SUBJECT: '/subjects',
  CHAPTER: '/chapters',
  VIDEO: '/videos',
  DEPARTMENT_TEST: '/department-test',
  SUBJECT_TEST: '/subject-test',
  CHAPTER_TEST: '/chapter-test',
  VIDEO_TEST: '/video-test',
  VIDEO_DETAIL: '/video-detail',
  SCHOOL: '/schools',
  ROOM: '/rooms',
  VIRTUAL: '/virtuals',
  MY_PAGE: '/mypage',
  CONTACT: '/contact',
  ONLINE_LESSON: '/online-lesson',
  ONLINE_LESSON_EN: '/online-lesson-en',
  ONLINE_LESSON_PARTICIPATION: '/online-lesson-participation',
  ONLINE_LESSON_ARCHIVE: '/online-lesson-archive-view',
  ONLINE_LESSON_DEPARTMENT: '/online-lesson-departments',
  MINI_TEST: '/mini-test',
  BULLETIN: '/bulletin',
  BULLETIN_DETAIL: '/bulletin/:id',
  NOT_FOUND: '/not-found'
}
