import * as API from '@/api/bulletinAPI'
import { BulletinParams } from '@/interface/type'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

interface InitialStateType {
  loading: boolean
  error: any
  bulletinData: any[]
  bulletinKey: number
  bulletinDetail: {
    title: string
    content: string
    file: string
    notification: any
  }
}

const initialState: InitialStateType = {
  loading: false,
  error: null,
  bulletinData: [],
  bulletinKey: 0,
  bulletinDetail: {
    title: '',
    content: '',
    file: '',
    notification: {}
  }
}

export const getBulletinData = createAsyncThunk(
  'bulletin/getNotifi',
  async (payload: BulletinParams, { rejectWithValue }) => {
    try {
      const response = await API.getBulletin(payload)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

const bulletinSlice = createSlice({
  name: 'bulletin',
  initialState,
  reducers: {
    changeBulletinDetail: (state, action: PayloadAction<any>) => {
      state.bulletinKey = action.payload.index
      state.bulletinDetail = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getBulletinData.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getBulletinData.fulfilled, (state, { payload }) => {
      state.loading = false
      state.bulletinData = payload
      state.bulletinDetail = payload[0]
      state.bulletinKey = 1
    })
    builder.addCase(getBulletinData.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })
  }
})

export const { changeBulletinDetail } = bulletinSlice.actions

export default bulletinSlice.reducer
