import React, { useState } from 'react'
import { Button, Form, Input, Alert, Space } from 'antd'
import { userFirstChangePassword } from '@/stores/login'
import { useAppDispatch } from '@/app/hooks'
import { useNavigate } from 'react-router-dom'
import i18Next from '@/i18n'
import { REGEX } from '@/utils/regex'

const FirstPasswordChangeView: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [err, setErr] = useState('')

  const onFinish = (values: any) => {
    dispatch(userFirstChangePassword(values))
      .unwrap()
      .then(() => {
        navigate('/')
        setErr('')
      })
      .catch(err => {
        setErr(err)
      })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <section className='full-page'>
      <div className='change-password-wrapper'>
        <div className='title'>{i18Next.t('password_change.title')}</div>
        {/* <h1 className='required-content'>{i18Next.t('password_change.requiredContent')}</h1> */}
        <h1 className='required-content'>
          {i18Next.t('password_change.requiredContent1')}
          <br></br>
          {i18Next.t('password_change.requiredContent2')}
          <br></br>
          {i18Next.t('password_change.requiredContent3')}
        </h1>
        {err && (
          <Space direction='vertical' style={{ width: '80%' }} className='mb2'>
            <Alert
              message={err}
              type='error'
              action={<Button size='small' type='text' />}
              closable
            />
          </Space>
        )}
        <Form
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          layout={'vertical'}
          className={'change-password-form'}
        >
          {/* <Form.Item
           
            name='oldPassword'
            rules={[
              () => ({
                validator(rule, value) {
                  const regex = new RegExp(REGEX.PASSWORD)
                  const regex_space = new RegExp(REGEX.NOT_WHITESPACE)
                  if (!value) {
                    return Promise.reject(i18Next.t('password_change.requiredPassword'))
                  } else if (!regex_space.test(value)) {
                    return Promise.reject(i18Next.t('password_change.passwordNotSpace'))
                  } else if (!regex.test(value)) {
                    return Promise.reject(i18Next.t('password_change.passwordValidation'))
                  }
                  return Promise.resolve()
                }
              })
            ]}
            className='change-password-input mb5'
          >
            <Input.Password className='input' placeholder='パスワード' />
          </Form.Item> */}

          <Form.Item
            name='password'
            rules={[
              () => ({
                validator(rule, value) {
                  const regex = new RegExp(REGEX.PASSWORD)
                  const regex_space = new RegExp(REGEX.NOT_WHITESPACE)
                  // if (value && getFieldValue('oldPassword') === value) {
                  //   return Promise.reject(i18Next.t('password_change.passwordNotSame'))
                  // }
                  if (!value) {
                    return Promise.reject(i18Next.t('password_change.enterNewPassword'))
                  } else if (!regex_space.test(value)) {
                    return Promise.reject(i18Next.t('password_change.reEnterpasswordNotSpace'))
                  } else if (!regex.test(value)) {
                    return Promise.reject(i18Next.t('password_change.passwordValidation'))
                  }
                  return Promise.resolve()
                }
              })
            ]}
            className='change-password-input mb5'
          >
            <Input.Password className='input' placeholder='新しいパスワード' />
          </Form.Item>

          <Form.Item
            name='passwordConfirm'
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const regex_space = new RegExp(REGEX.NOT_WHITESPACE)
                  const regex = new RegExp(REGEX.PASSWORD)
                  if (!value) {
                    return Promise.reject(i18Next.t('password_change.enterNewPasswordConfirm'))
                  } else if (!regex_space.test(value)) {
                    return Promise.reject(i18Next.t('password_change.reEnterpasswordNotSpace'))
                  } else if (getFieldValue('password') !== value) {
                    return Promise.reject(i18Next.t('password_change.passwordNotMatch'))
                  } else if (!regex.test(value)) {
                    return Promise.reject(i18Next.t('password_change.passwordValidation'))
                  }
                  return Promise.resolve()
                }
              })
            ]}
            className='change-password-input mb10'
          >
            <Input.Password className='input' placeholder='新しいパスワード（確認用）' />
          </Form.Item>

          <Button className='change-password-btn' htmlType='submit'>
            {`${i18Next.t('password_change.submit')}`}
          </Button>
        </Form>
      </div>
    </section>
  )
}

export default FirstPasswordChangeView
