import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as API from '@/api/contactAPI'
import {
  Message,
  CreateContactPayload,
  Room,
  GetMessagePayload,
  CreateMessagePayload,
  UploadFilePayload
} from '@/interface/contactInterface'

const initialState = {
  loading: false,
  rooms: {} as Room | any,
  messagesList: [] as Message[] | any,
  currentPage: 1 as number | undefined,
  totalPage: 0 as number | undefined,
  error: null as any,
  success: false
}

export const createContact = createAsyncThunk(
  'contacts',
  async (payload: CreateContactPayload, { rejectWithValue }) => {
    try {
      const response = await API.sendContact(payload.data)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getRooms = createAsyncThunk('room/get', async (_, { rejectWithValue }) => {
  try {
    const response = await API.getRooms()
    return response
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const getMessages = createAsyncThunk(
  'message/get',
  async (payload: GetMessagePayload, { rejectWithValue }) => {
    try {
      const response = await API.getMessages(payload)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const createMessage = createAsyncThunk(
  'messages',
  async (payload: CreateMessagePayload, { rejectWithValue }) => {
    try {
      const response = await API.createMessage(payload.data)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const uploadFile = createAsyncThunk(
  'files',
  async (payload: UploadFilePayload, { rejectWithValue }) => {
    try {
      const response = await API.uploadFile(payload.data)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // builder.addCase(createContact.rejected, (state, { payload }) => {
    //   state.error = payload
    // })
    builder.addCase(getMessages.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getMessages.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.messagesList = [...state.messagesList].concat(payload.data)
      state.currentPage = payload.current_page
      state.totalPage = payload.total_page
    })
    builder.addCase(getRooms.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getRooms.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.rooms = payload
    })
  }
})

export default contactSlice.reducer
