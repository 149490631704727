import request from '@/api/config/request'
import type { ResponseList } from '@/interface/commonInterface'

export const getSubjects = departmentId => {
  const url = '/subjects'
  return request<ResponseList, ResponseList>({
    url,
    method: 'GET',
    // eslint-disable-next-line camelcase
    params: { department_id: departmentId }
  })
}

export const listByUser = () => {
  const url = '/subjects/list-by-user'
  return request<ResponseList, ResponseList>({
    url,
    method: 'GET'
  })
}
