import { CaretRightOutlined } from '@ant-design/icons'
import { Col, Collapse, Progress, Row, Tooltip } from 'antd'
import { useState } from 'react'
import { StatisticTestWrapper } from '../styled'
import { useAppSelector } from '@/app/hooks'

const StatisticTest = () => {
  const [activeKeyTest, setActiveKeyTest] = useState<string | string[]>([])
  const { data } = useAppSelector(state => state.myPage)

  const onChangeTest = (key: string | string[]) => {
    setActiveKeyTest(key)
  }

  return (
    <StatisticTestWrapper>
      <h3 className='title' style={{ marginTop: '20px' }}>
        小テスト結果
      </h3>

      <Row className='statistic-wrapper'>
        <Col span={8}>
          <div className='subjects'>
            <div className='title'>&nbsp;</div>
            <div className='list'>
              <Collapse
                ghost
                onChange={onChangeTest}
                activeKey={activeKeyTest}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              >
                {data.tests.map(test => {
                  return (
                    <Collapse.Panel
                      showArrow={!!test.chapters && !!test.chapters.length}
                      header={<Tooltip title={test.name}>{test.name}</Tooltip>}
                      key={`test-${test.id}`}
                    >
                      <ol>
                        {!!test.chapters &&
                          !!test.chapters &&
                          test.chapters.map((item, idx) => {
                            return (
                              <li key={item.name}>
                                <Tooltip title={`${idx + 1}章 ${item.name}`}>
                                  <div className='subject-name'>{`${idx + 1}章 ${item.name}`}</div>
                                </Tooltip>
                              </li>
                            )
                          })}
                      </ol>
                    </Collapse.Panel>
                  )
                })}
              </Collapse>
            </div>
          </div>
        </Col>
        <Col span={16} style={{ paddingLeft: 10 }}>
          <div className='data'>
            <div className='note'>
              <div className='note-item'>
                <div className='text'>正解</div>
                <div className='text-gray'>不正解</div>
                <div className='text-black'>未出題</div>
              </div>
            </div>
            <div className='process-list'>
              <Collapse ghost activeKey={activeKeyTest} expandIcon={() => <></>}>
                {data.tests.map((item, index) => (
                  <Collapse.Panel
                    header={
                      <div className='process-item' key={index}>
                        <Progress
                          percent={
                            item.count_correct + item.count_not_correct !== 0
                              ? Math.ceil(
                                  ((item.count_correct + item.count_not_correct) /
                                    item.count_question) *
                                    100
                                )
                              : 0
                          }
                          success={{
                            percent:
                              item.count_correct !== 0
                                ? Math.ceil((item.count_correct / item.count_question) * 100)
                                : 0,
                            strokeColor: '#53D3D3'
                          }}
                          showInfo={false}
                          strokeColor={'#B4B4B5'}
                          trailColor={'#231815'}
                        />
                        {item.count_correct + item.count_not_correct !== 0 &&
                          item.count_correct + item.count_not_correct !== item.count_question && (
                            <div
                              className='correct-percent'
                              style={{
                                color: '#53d3d3',
                                left: `calc(${
                                  item.count_correct !== 0
                                    ? Math.ceil((item.count_correct / item.count_question) * 100)
                                    : 0
                                }% - 15px)`
                              }}
                            >
                              {Math.ceil((item.count_correct / item.count_question) * 100) || 0}%
                            </div>
                          )}
                        <div
                          className='number'
                          style={{
                            color: '#B4B4B5',
                            left: `calc(${
                              item.count_correct + item.count_not_correct !== 0
                                ? Math.ceil(
                                    ((item.count_correct + item.count_not_correct) /
                                      item.count_question) *
                                      100
                                  )
                                : 0
                            }% - ${
                              item.count_correct + item.count_not_correct === 0
                                ? 3
                                : item.count_correct + item.count_not_correct ===
                                  item.count_question
                                ? 20
                                : 15
                            }px)`
                          }}
                        >
                          {Math.ceil(
                            ((item.count_correct + item.count_not_correct) / item.count_question) *
                              100
                          ) || 0}
                          %
                        </div>
                      </div>
                    }
                    key={`test-${item.id}`}
                  >
                    <ol>
                      {!!item.chapters &&
                        !!item.chapters &&
                        item.chapters.map(chapter => {
                          return (
                            <li key={chapter.name}>
                              <div className='process-item' key={index}>
                                <Progress
                                  percent={
                                    chapter.count_correct + chapter.count_not_correct !== 0
                                      ? Math.ceil(
                                          ((chapter.count_correct + chapter.count_not_correct) /
                                            chapter.count_question) *
                                            100
                                        )
                                      : 0
                                  }
                                  success={{
                                    percent:
                                      chapter.count_correct !== 0
                                        ? Math.ceil(
                                            (chapter.count_correct / chapter.count_question) * 100
                                          )
                                        : 0,
                                    strokeColor: '#53D3D3'
                                  }}
                                  showInfo={false}
                                  strokeColor={'#B4B4B5'}
                                  trailColor={'#231815'}
                                />
                                {chapter.count_correct + chapter.count_not_correct !== 0 && (
                                  <div
                                    className='correct-percent'
                                    style={{
                                      color: '#53d3d3',
                                      left: `calc(${
                                        chapter.count_correct !== 0
                                          ? Math.ceil(
                                              (chapter.count_correct / chapter.count_question) * 100
                                            )
                                          : 0
                                      }% - ${
                                        chapter.count_correct === chapter.count_question ? 25 : 15
                                      }px)`
                                    }}
                                  >
                                    {Math.ceil(
                                      (chapter.count_correct / chapter.count_question) * 100
                                    ) || 0}
                                    %
                                  </div>
                                )}

                                {chapter.count_correct !== chapter.count_question && (
                                  <div
                                    className='number'
                                    style={{
                                      color: '#B4B4B5',
                                      left: `calc(${
                                        chapter.count_correct + chapter.count_not_correct !== 0
                                          ? Math.ceil(
                                              ((chapter.count_correct + chapter.count_not_correct) /
                                                chapter.count_question) *
                                                100
                                            )
                                          : 0
                                      }% - ${
                                        chapter.count_correct + chapter.count_not_correct === 0
                                          ? 3
                                          : chapter.count_correct + chapter.count_not_correct ===
                                            chapter.count_question
                                          ? 25
                                          : 15
                                      }px)`
                                    }}
                                  >
                                    {Math.ceil(
                                      ((chapter.count_correct + chapter.count_not_correct) /
                                        chapter.count_question) *
                                        100
                                    ) || 0}
                                    %
                                  </div>
                                )}
                              </div>
                            </li>
                          )
                        })}
                    </ol>
                  </Collapse.Panel>
                ))}
              </Collapse>
            </div>
          </div>
        </Col>
      </Row>

      <Row className='row-total' style={{ background: 'rgb(250, 248, 229)' }}>
        <Col span={12}>
          <div className='col-total col-total-left'>
            正解数&nbsp;<div className='total-number'>{data.count_correct}</div>&nbsp;問
          </div>
        </Col>
        <Col span={12}>
          <div className='col-total col-total-right'>
            実施回数&nbsp;<div className='total-number'>{data.count_test}</div>&nbsp;回
          </div>
        </Col>
      </Row>
    </StatisticTestWrapper>
  )
}

export default StatisticTest
