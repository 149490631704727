import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as API from '@/api/myPageAPI'
import { MyPageParams } from '@/interface/type'

interface InitialStateType {
  loading: boolean
  error: any
  data: {
    count_correct: number
    count_test: number
    tests: {
      id: number
      count_correct: number
      count_not_correct: number
      count_question: number
      count_unissued: number
      count_unwatched: number
      count_video: number
      count_viewed: number
      name: string
      chapters: {
        name: string
        count_correct: number
        count_not_correct: number
        count_question: number
        count_unissued: number
        id: number
      }[]
    }[]
    videos: {
      id: number
      count_correct: number
      count_not_correct: number
      count_question: number
      count_unissued: number
      count_unwatched: number
      count_video: number
      count_viewed: number
      name: string
      chapters: {
        name: string
        count_unwatched: number
        count_video: number
        count_viewed: number
        id: number
      }[]
    }[]
  }
}

const initialState: InitialStateType = {
  loading: false,
  error: null,
  data: {
    count_correct: 0,
    count_test: 0,
    tests: [],
    videos: []
  }
}

export const getInfoMyPage = createAsyncThunk(
  'my-page',
  async (payload: MyPageParams, { rejectWithValue }) => {
    try {
      const response = await API.getInfoMyPage(payload)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

const myPageSlice = createSlice({
  name: 'myPage',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getInfoMyPage.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getInfoMyPage.fulfilled, (state, { payload }) => {
      state.loading = false
      state.data = payload
    })
    builder.addCase(getInfoMyPage.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })
  }
})

export default myPageSlice.reducer
