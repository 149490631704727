/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import PageTitle from '@/components/common/PageTitle'
import { PopupNoti } from '@/components/common/Popup/PopupNoti'
import { ROUTE } from '@/router/config'
import { getChapters } from '@/stores/chapterStore'
import { Spin, Col, Row, Form } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ListSubItemWeb from '@/components/common/ListSubItemWeb'
import { useMediaQuery } from 'react-responsive'
import { SCREEN_SIZE } from '@/utils/screenSize'

const ChaptersView: React.FC = () => {
  const location = useLocation()
  const subjectId = location.state.subjectId
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isTest = location.pathname === ROUTE.CHAPTER_TEST
  const [carouselData, setCarouselData] = useState<any[]>([])
  const [isSliderVisible, setIsSliderVisible] = useState<boolean>(true)

  const { chapterList, loading } = useAppSelector(state => state.chapter)
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })

  const chapterItemOnclick = chapter => {
    const videoFilter = chapterList.filter(v => v.id == chapter.id)
    if (videoFilter[0]?.video_count == 0) {
      PopupNoti.error('コンテンツは準備中です')
      return
    }
    if (!isTest) {
      if (!chapter?.is_public) {
        PopupNoti.error('この動画は公開前です')
      } else {
        navigate(ROUTE.VIDEO, {
          state: {
            chapterId: chapter.id
          }
        })
      }
    } else {
      if (chapter.department.type === 'ENGLISH')
        navigate(ROUTE.VIDEO_TEST, {
          state: {
            chapterId: chapter.id
          }
        })
      else {
        if (!chapter.is_question) {
          PopupNoti.error('このテストは準備中です')
          return
        }
        if (!chapter.is_test) {
          PopupNoti.error('先に動画の視聴を完了してください')
          return
        }

        navigate(ROUTE.MINI_TEST, {
          state: {
            chapterId: chapter.id
          }
        })
      }
    }
  }

  const height = window.innerHeight - 140
  const heightPC = window.innerHeight - 70
  const heightStandardPC = ''

  useEffect(() => {
    if (chapterList.length) {
      const data: any[] = []
      let temp = 3
      let carouselItem: any[] = []
      chapterList.map((item, index) => {
        const newItem = _.cloneDeep(item)
        newItem.name =
          item?.department?.type === 'ENGLISH'
            ? `コース${index + 1} ${item.name}`
            : `${index + 1}章 ${item.name}`
        newItem.icon_url = item.icon_url
        if (index <= temp) {
          carouselItem.push(newItem)
        }
        if (index == temp || (index != temp && index == chapterList.length - 1)) {
          data.push(carouselItem)
          temp = temp + 4
          carouselItem = []
        }
      })
      setCarouselData(data)
    }
  }, [chapterList])

  useEffect(() => {
    dispatch(getChapters(subjectId))
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSliderVisible(false)
      } else {
        setIsSliderVisible(true)
      }
    }

    // add resize event listener to update slider visibility
    window.addEventListener('resize', handleResize)

    // call handleResize on initial mount
    handleResize()

    // cleanup listener on unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div
      className='content-wrapper background-pink'
      style={{
        height:
          chapterList?.length < 4 ? '100vh' :
          isMobile && chapterList?.length > 4
            ? height
            : isMobile && chapterList?.length < 9 && chapterList?.length > 4
            ? heightPC
            : heightStandardPC
      }}
    >
      <PageTitle
        icon={`${isTest ? 'mini-test-icon' : 'videos-icon'}`}
        title={`${isTest ? '小テスト' : '動画で学ぶ'}`}
      />
      <Spin spinning={loading}>
        <div className='chapters-carousel' style={{ backgroundColor: isMobile ? '#f9f2f5' : '' }}>
          <div className='box'>
            <div className='full-list'>
              {!isMobile ? (
                <Form.Item className='chapter-title'>
                  <Row className='chapter-header'>
                    <Col span={6}>
                      {' '}
                      {/* <span className='chapter-icon' /> */}
                      <img
                        className='chapter-icon'
                        src={`${chapterList[0]?.subject?.icon_url}`}
                        alt={chapterList[0]?.subject?.name}
                      />
                    </Col>
                    <Col span={6}>
                      {' '}
                      {/* <div className='title'>{chapterList[0]?.subject?.name}</div> */}
                    </Col>
                  </Row>
                  <Row className='text-selected-chapter'>{chapterList[0]?.subject?.name}</Row>
                </Form.Item>
              ) : (
                <></>
              )}

              {carouselData?.map((carousel, index) => (
                <div key={index}>
                  <div className='item-wrapper px5'>
                    {carousel?.slice(0, 4)?.map(chapter => (
                      <ListSubItemWeb
                        key={`carousel-item-${chapter.id}`}
                        item={chapter}
                        type={'chapter'}
                        roomClick={chapterItemOnclick}
                        customClass=''
                        iconSvg=''
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default ChaptersView
