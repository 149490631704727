import React, { useEffect, useState } from 'react'
import ListItemWeb from '@/components/common/ListItemWeb'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd'
import _ from 'lodash'
import { ROLE_USER, ROLE_TYPE } from '@/utils/constants'

const menu = [
  // { id: 1, name: 'マイページ', icon: 'submenu' },
  { id: 2, name: '掲示板', icon: 'message' }
]

let user: any
const _user = localStorage.getItem('user')
if (_user) {
  user = JSON.parse(_user)
}

const SchoolView: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const type = 'submenu'
  const navigate = useNavigate()
  const menuItemOnClick = id => {
    const item = _.find(menu, { id })
    switch (item?.icon) {
      case 'submenu':
        navigate('/mypage')
        break
      case 'message':
        navigate('/bulletin')
        break

      default:
        break
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 100)
  }, [])

  const auth = user?.role?.authority
  const roleType = user?.role?.type
  return (
    <Spin spinning={loading}>
      {/* // <div className='list-wrapper department-list school px5'> */}
      <div className='sub-activities-list-wrapper px5'>
        <div className='box-web scroll'>
          {menu.map((item, index) =>
            auth !== ROLE_USER.USER_VIRTUAL && roleType == ROLE_TYPE.USER ? (
              <ListItemWeb
                key={index}
                type={index == 0 ? type : 'message'}
                item={item}
                handleClick={menuItemOnClick}
                iconSvg={item.icon}
              />
            ) : roleType == ROLE_TYPE.GUARDIAN ? (
              // <ListItemWeb
              //   key={index}
              //   type={index == 0 ? type : 'message'}
              //   item={item}
              //   handleClick={menuItemOnClick}
              //   iconSvg={item.icon}
              //   customHidden={item.id !== 1 ? 'hidden' : ''}
              // />
             <></>
            ) : (
              <ListItemWeb
                key={index}
                type={'submenu'}
                item={item}
                handleClick={menuItemOnClick}
                iconSvg={item.icon}
                customHidden={item.id !== 2 ? 'hidden' : ''}
              />
            )
          )}
        </div>
      </div>
    </Spin>
  )
}

export default SchoolView
