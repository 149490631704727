import React, { useEffect } from 'react'
import PageTitle from '@/components/common/PageTitle'
import ListItem from '@/components/common/ListItem'
import { useLocation, useNavigate } from 'react-router-dom'
import { getDepartments } from '@/stores/departmentStore'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { Spin } from 'antd'
import { ROUTE } from '@/router/config'

const DepartmentsView = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isTest = location.pathname === ROUTE.DEPARTMENT_TEST
  const { departmentList, loading } = useAppSelector(state => state.department)
  const departmenItemOnClick = id => {
    navigate(!isTest ? ROUTE.SUBJECT : ROUTE.SUBJECT_TEST, {
      state: {
        departmentId: id
      }
    })
  }
  const height = window.innerHeight - 140

  useEffect(() => {
    dispatch(getDepartments())
  }, [])

  return (
    <div className='content-wrapper background-pink' style={{ height }}>
      <PageTitle
        icon={`${isTest ? 'mini-test-icon' : 'videos-icon'}`}
        title={`${isTest ? '小テスト' : '動画で学ぶ'}`}
      />
      <Spin spinning={loading}>
        <div className='list-wrapper department-list px5' style={{ backgroundColor: '#f9f2f5' }}>
          <div className='box'>
            {departmentList.map((item, index) => (
              <ListItem
                key={index}
                item={item}
                schoolName={item.school.name}
                handleClick={departmenItemOnClick}
                customClass=''
              />
            ))}
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default DepartmentsView
