import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as API from '@/api/chapterAPI'
import type { ResponseList } from '@/interface/commonInterface'

const initialState = {
  loading: false,
  chapterList: [] as ResponseList,
  error: null as any,
  success: false
}

export const getChapters = createAsyncThunk(
  'chapter/get',
  async (payload: number, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line camelcase
      const response = await API.getChapters(payload)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

const chapterSlice = createSlice({
  name: 'chapter',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getChapters.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getChapters.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.chapterList = payload
    }),
      builder.addCase(getChapters.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
  }
})

export default chapterSlice.reducer
