import { Row } from 'antd'
import { Wrapper } from './styled'

const PageNotFoundDomain = () => {
  return (
    <Row align={'middle'} justify='center'>
      <Wrapper>Coming Soon!</Wrapper>
    </Row>
  )
}

export default PageNotFoundDomain
