import { Modal } from 'antd'
import styled from 'styled-components'

export const ModalCustom = styled(Modal)`
  .ant-modal-content {
    border-radius: 30px;
    padding: 40px;
  }

  p {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
  }

  .btnActions {
    gap: 16px;
    margin: 30px 0 0;
    display: flex;
    flex-direction: row;
  }

  .buttonModal {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 30px;
    padding: 12px 16px;
    background-color: #717071;
    margin: 0 5px;
    color: white;
    flex: 1 1 0 !important;
  }
`
