import React, { useEffect } from 'react'
import PageTitle from '@/components/common/PageTitle'
import ListItem from '@/components/common/ListItem'
import { useNavigate, useLocation } from 'react-router-dom'
import { Spin } from 'antd'
import { getSubjects } from '@/stores/subjectStore'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { ROUTE } from '@/router/config'

const SubjectsView: React.FC = () => {
  const location = useLocation()
  const departmentId = location.state.departmentId
  const isTest = location.pathname === ROUTE.SUBJECT_TEST
  const dispatch = useAppDispatch()
  const height = window.innerHeight - 140
  const { subjectsList, loading } = useAppSelector(state => state.subject)
  const navigate = useNavigate()
  const subjectItemOnclick = id => {
    navigate(!isTest ? ROUTE.CHAPTER : ROUTE.CHAPTER_TEST, {
      state: {
        subjectId: id
      }
    })
  }

  useEffect(() => {
    dispatch(getSubjects(departmentId))
  }, [])

  const subjectsListData = subjectsList.filter(s => s.department_id == departmentId)
  return (
    <div className='content-wrapper background-pink' style={{ height }}>
      <PageTitle
        icon={`${isTest ? 'mini-test-icon' : 'videos-icon'}`}
        title={`${isTest ? '小テスト' : '動画で学ぶ'}`}
      />
      <Spin spinning={loading}>
        <div className='list-wrapper subject-list' style={{ backgroundColor: '#f9f2f5' }}>
          <div className='box'>
            {subjectsListData?.map((item, index) => (
              <ListItem
                key={index}
                item={item}
                handleClick={subjectItemOnclick}
                hideName={true}
                customClass=''
              />
            ))}
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default SubjectsView
