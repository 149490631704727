import styled from 'styled-components'

export const ConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-confirm {
    background: #fff;
    padding: 8px 16px;
    border-radius: 50px;
    margin: 20px 0;
  }

  .button-confirm {
    margin-top: 20px;
    background-color: #717071;
    border-radius: 50px;
    padding: 16px 24px;
    cursor: pointer;
  }

  .index-ans {
    color: #a5a072;
    font-size: 20px;
  }

  .option-ans {
    margin-left: 12px;
    background-color: #fff;
    padding: 5px 12px;
    border-radius: 50px;
  }
`

export const WrapperCustom = styled.div`
  background-color: #f7f7f7;
  min-height: 100%;
  position: relative;
  padding: 40px;
  border-radius: 40px 0;

  .contentQuestion {
    font-size: 16px;
    color: #595757;
    margin-bottom: 30px;
    // font-family: 'SourceHanSans';
  }

  .ant-form {
    font-family: 'Source Han Sans';
  }

  @media (max-width: 768px) {
    padding: 20px 10px;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  > form {
    margin: 0 auto;
    max-width: 726px;
  }

  .btnClose {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    svg {
      max-width: 22px;
    }
  }

  .flex-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* text-align: center; */
  }

  .flex-action {
    max-width: 695px;
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
`
export const FillBlankWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  input {
    width: 30px !important;
    height: 30px;
    background-color: #fff;
    margin: 4px;
    font-size: 18px;
    border-radius: 5px;
  }

  @media screen and (max-width: 768px) {
    input {
      width: 22px !important;
      height: 22px;
      background-color: #fff;
      margin: 1px;
      padding: 0 2px;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 576px) {
    input {
      width: 16px !important;
      height: 20px;
      background-color: #fff;
      margin: 1px;
      padding: 0 2px;
      font-size: 14px;
    }
  }

  .abc {
    border: none;
  }
`
