import { Button, Modal, Typography } from 'antd'
import { downloadFileAttachmentChat } from '@/utils/helpers'

export function DownloadMessageFile(props) {
  const { file, isShowModal, onClose } = props
  const data = file.file_path.substring(file.file_path.lastIndexOf('/') + 1).split('_')
  const result = data.slice(1).join('_')

  return (
    <>
      <Modal
        title={'ファイルのダウンロード'}
        visible={isShowModal}
        onCancel={onClose}
        footer={false}
        centered
      >
        <div>
          <div style={{ maxWidth: '28rem', marginBottom: '1rem' }}>
            {file ? (
              <Typography.Link>{result}</Typography.Link>
            ) : (
              <Typography.Text type='danger'>エーラ</Typography.Text>
            )}
          </div>
          <Button
            type='primary'
            // href={file?.url}
            onClick={() => {
              downloadFileAttachmentChat(file)
            }}
            target='_blank'
          >
            <span style={{ color: 'white' }}>ダウンロード</span>
          </Button>
        </div>
      </Modal>
    </>
  )
}
