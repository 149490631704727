import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as API from '@/api/onlineLessonNotiAPI'

const initialState = {
  loading: false,
  error: null as any,
  success: false
}

export const updateToken = createAsyncThunk(
  'users/fire-base',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await API.updateToken(payload)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

const contactSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(updateToken.rejected, (state, { payload }) => {
      state.error = payload
    })
  }
})

export default contactSlice.reducer
