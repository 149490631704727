import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { userRole } from '@/components/common/Permission/WithPermission'
import { ROLE_USER, ROLE_TYPE } from '@/utils/constants'
import { ROUTE } from '@/router/config'
import { SCREEN_SIZE } from '@/utils/screenSize'
import { useMediaQuery } from 'react-responsive'

const BottomMenu: React.FC = () => {
  const [activeItem, setActiveItem] = useState('home')
  const navigate = useNavigate()
  const location = useLocation()
  const onClickItem = url => {
    navigate(url)

    if (url == '/') window.location.reload()
  }
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })

  useEffect(() => {
    if (location) {
      if (location.pathname == '/') setActiveItem('home')
      const videoStack = ['/departments', '/subjects', '/videos', '/video-detail']
      if (videoStack.includes(location.pathname)) setActiveItem('videos')
      if (location.pathname == '/mypage') setActiveItem('mypage')
      if (location.pathname == '/contact') setActiveItem('contact')

      const lessonStack = [
        '/online-lesson-departments',
        '/online-lesson',
        '/online-lesson-participation'
      ]
      if (lessonStack.includes(location.pathname)) setActiveItem('lesson')

      const lessonArchiveStack = ['/online-lesson', '/online-lesson-participation']
      if (lessonArchiveStack.includes(location.pathname)) setActiveItem('lesson')

      if (!isMobile) {
        if (
          location.pathname == '/departments' ||
          location.pathname == '/online-lesson' ||
          location.pathname == '/online-lesson-departments'
        )
          navigate('/')
      }
    }
  })

  let user: any
  const _user = localStorage.getItem('user')
  if (_user) {
    user = JSON.parse(_user)
  }
  const data = userRole()
  const userType = user?.role?.type

  if (isMobile) {
    return userType == ROLE_TYPE.USER ? (
      data !== ROLE_USER.USER_VIRTUAL ? (
        <div className='bottom-menu-wrapper'>
          <div className='icon-wrapper' onClick={() => onClickItem('/')}>
            <span className={`home-icon menu-icon ${activeItem == 'home' ? 'active' : ''}`} />
            <div className='text'>ホーム</div>
          </div>
          <div className='icon-wrapper' onClick={() => navigate(ROUTE.DEPARTMENT_TEST)}>
            <span
              className={`mini-test-icon menu-icon ${activeItem == 'mini-test' ? 'active' : ''}`}
            />
            <div className='text'>小テスト</div>
          </div>
          <div className='icon-wrapper' onClick={() => onClickItem('/departments')}>
            <span className={`videos-icon menu-icon ${activeItem == 'videos' ? 'active' : ''}`} />
            <div className='text'>動画で学ぶ</div>
          </div>
          <div className='icon-wrapper'>
            <span
              className={`mypage-icon menu-icon ${activeItem == 'mypage' ? 'active' : ''}`}
              onClick={() => onClickItem('/mypage')}
            />
            <div className='text'>マイページ</div>
          </div>
          {/* <div className='icon-wrapper'>
            <span
              className={`lesson-icon menu-icon ${activeItem == 'lesson' ? 'active' : ''}`}
              onClick={() => onClickItem('/online-lesson-departments')}
            />
            <div className='text'>オンラインレッスン</div>
          </div> */}
          {/* Chat */}
          <div className='icon-wrapper'>
            {userType !== ROLE_TYPE.STAFF ? (
              <>
                <span
                  className={`contact-chat-icon menu-icon ${activeItem == 'contact' ? 'active' : ''}`}
                />
                <div className='text'>連絡帳</div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div className='bottom-menu-wrapper'>
          <div className='icon-wrapper' onClick={() => onClickItem('/')}>
            <span className={`home-icon menu-icon ${activeItem == 'home' ? 'active' : ''}`} />
            <div className='text'>ホーム</div>
          </div>
          {/* Chat */}
          <div className='icon-wrapper'>
            {userType !== ROLE_TYPE.STAFF ? (
              <>
                <span
                  className={`contact-chat-icon menu-icon ${activeItem == 'contact' ? 'active' : ''}`}
                />
                <div className='text'>連絡帳</div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      )
    ) : userType == ROLE_TYPE.GUARDIAN ? (
      <div className='bottom-menu-wrapper'>
        <div className='icon-wrapper' onClick={() => onClickItem('/')}>
          <span className={`home-icon menu-icon ${activeItem == 'home' ? 'active' : ''}`} />
          <div className='text'>ホーム</div>
        </div>
        {user?.permissions?.WATCH_VIDEO == true ? (
          <div className='icon-wrapper' onClick={() => onClickItem('/departments')}>
            <span className={`videos-icon menu-icon ${activeItem == 'videos' ? 'active' : ''}`} />
            <div className='text'>動画で学ぶ</div>
          </div>
        ) : (
          <></>
        )}
        {user?.permissions?.MY_PAGE == true ? (
          <div className='icon-wrapper'>
            <span
              className={`mypage-icon menu-icon ${activeItem == 'mypage' ? 'active' : ''}`}
              onClick={() => onClickItem('/mypage')}
            />
            <div className='text'>マイページ</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    ) : (
      <div className='bottom-menu-wrapper'>
        <div className='icon-wrapper' onClick={() => onClickItem('/')}>
          <span className={`home-icon menu-icon ${activeItem == 'home' ? 'active' : ''}`} />
          <div className='text'>ホーム</div>
        </div>
        <div className='icon-wrapper' onClick={() => navigate(ROUTE.DEPARTMENT_TEST)}>
          <span
            className={`mini-test-icon menu-icon ${activeItem == 'mini-test' ? 'active' : ''}`}
          />
          <div className='text'>小テスト</div>
        </div>
        <div className='icon-wrapper' onClick={() => onClickItem('/departments')}>
          <span className={`videos-icon menu-icon ${activeItem == 'videos' ? 'active' : ''}`} />
          <div className='text'>動画で学ぶ</div>
        </div>
        <div className='icon-wrapper'>
          <span
            className={`mypage-icon menu-icon ${activeItem == 'mypage' ? 'active' : ''}`}
            onClick={() => onClickItem('/mypage')}
          />
          <div className='text'>マイページ</div>
        </div>
        {/* <div className='icon-wrapper'>
          <span
            className={`lesson-icon menu-icon ${activeItem == 'lesson' ? 'active' : ''}`}
            onClick={() => onClickItem('/online-lesson-departments')}
          />
          <div className='text'>オンラインレッスン</div>
        </div> */}
        <div className='icon-wrapper'>
          {userType !== ROLE_TYPE.STAFF ? (
            <>
              <span
                className={`contact-chat-icon menu-icon ${activeItem == 'contact' ? 'active' : ''}`}
              />
              <div className='text'>連絡帳</div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    )
  } else {
    return userType == ROLE_TYPE.USER ? (
      data !== ROLE_USER.USER_VIRTUAL ? (
        <div className='bottom-menu-wrapper'>
          <div className='icon-wrapper' onClick={() => onClickItem('/')}>
            <span className={`home-icon menu-icon ${activeItem == 'home' ? 'active' : ''}`} />
            <div className='text'>ホーム</div>
          </div>

          <div className='icon-wrapper'>
            <span
              className={`mypage-icon menu-icon ${activeItem == 'mypage' ? 'active' : ''}`}
              onClick={() => onClickItem('/mypage')}
            />
            <div className='text'>マイページ</div>
          </div>

          <div className='icon-wrapper' onClick={() => onClickItem('/contact')}>
            {userType !== ROLE_TYPE.STAFF ? (
              <>
                <span
                  className={`contact-chat-icon menu-icon ${
                    activeItem == 'contact' ? 'active' : ''
                  }`}
                />
                <div className='text'>連絡帳</div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div className='bottom-menu-wrapper'>
          <div className='icon-wrapper' onClick={() => onClickItem('/')}>
            <span className={`home-icon menu-icon ${activeItem == 'home' ? 'active' : ''}`} />
            <div className='text'>ホーム</div>
          </div>
          <div className='icon-wrapper' onClick={() => onClickItem('/contact')}>
            <span
              className={`contact-chat-icon menu-icon ${activeItem == 'contact' ? 'active' : ''}`}
            />
            <div className='text'>連絡帳</div>
          </div>
        </div>
      )
    ) : userType == ROLE_TYPE.GUARDIAN ? (
      <div className='bottom-menu-wrapper'>
        {!user?.permissions?.MY_PAGE && !user?.permissions?.WATCH_VIDEO ? (
          <></>
        ) : (
          <div className='icon-wrapper' onClick={() => onClickItem('/')}>
            <span className={`home-icon menu-icon ${activeItem == 'home' ? 'active' : ''}`} />
            <div className='text'>ホーム</div>
          </div>
        )}
        {/* {user?.permissions?.WATCH_VIDEO == true ? (
          <div className='icon-wrapper' onClick={() => onClickItem('/departments')}>
            <span className={`videos-icon menu-icon ${activeItem == 'videos' ? 'active' : ''}`} />
            <div className='text'>動画で学ぶ</div>
          </div>
        ) : (
          <></>
        )} */}
        {user?.permissions?.MY_PAGE ? (
          <div className='icon-wrapper'>
            <span
              className={`mypage-icon menu-icon ${activeItem == 'mypage' ? 'active' : ''}`}
              onClick={() => onClickItem('/mypage')}
            />
            <div className='text'>マイページ</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    ) : (
      <div className='bottom-menu-wrapper'>
        <div className='icon-wrapper' onClick={() => onClickItem('/')}>
          <span className={`home-icon menu-icon ${activeItem == 'home' ? 'active' : ''}`} />
          <div className='text'>ホーム</div>
        </div>
        {/* <div className='icon-wrapper'>
          <span className={`mini-test-icon menu-icon ${activeItem == 'mini-test' ? 'active' : ''}`} />
          <div className='text'>小テスト</div>
        </div>
        <div className='icon-wrapper' onClick={() => onClickItem('/departments')}>
          <span className={`videos-icon menu-icon ${activeItem == 'videos' ? 'active' : ''}`} />
          <div className='text'>動画で学ぶ</div>
        </div> */}
        <div className='icon-wrapper'>
          <span
            className={`mypage-icon menu-icon ${activeItem == 'mypage' ? 'active' : ''}`}
            onClick={() => onClickItem('/mypage')}
          />
          <div className='text'>マイページ</div>
        </div>
        {/* <div className='icon-wrapper'>
          <span
            className={`lesson-icon menu-icon ${activeItem == 'lesson' ? 'active' : ''}`}
            onClick={() => onClickItem('/online-lesson-departments')}
          />
          <div className='text'>オンラインレッスン</div>
        </div> */}
        <div className='icon-wrapper' onClick={() => onClickItem('/contact')}>
          {userType !== ROLE_TYPE.STAFF ? (
            <>
              <span
                className={`contact-chat-icon menu-icon ${activeItem == 'contact' ? 'active' : ''}`}
              />
              <div className='text'>連絡帳</div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    )
  }
}

export default BottomMenu
