import messageIcon from '@/assets/imgs/message.png'
import mypageIcon from '@/assets/imgs/mypage.png'
import onlineLesson from '@/assets/imgs/online_lesson.png'
import testIcon from '@/assets/imgs/test.png'
import virtualIcon from '@/assets/imgs/vartual_school.png'
import videoIcon from '@/assets/imgs/video.png'
import Menu from '@/pages/Home/components/WebModules/Menu'
import OnlineLesson from '@/pages/Home/components/WebModules/OnlineLesson'
import Test from '@/pages/Home/components/WebModules/QuestionTest'
import Virtual from '@/pages/Home/components/WebModules/School'
import Video from '@/pages/Home/components/WebModules/Videos'
import { Col, Row } from 'antd'
import React from 'react'
// import { useAppDispatch } from '@/app/hooks'
// import { getInfo } from '@/stores/userStore'
import { ROLE_TYPE } from '@/utils/constants'

type ActivityItemProps = {
  type: string | 'message' | 'videos' | 'test' | 'lesson' | 'submenu' | 'virtual'
  text: string
  description: string
  customClass?: string
  customHidden?: string
}

const icons = {
  message: messageIcon,
  videos: videoIcon,
  test: testIcon,
  lesson: onlineLesson,
  submenu: mypageIcon,
  virtual: virtualIcon
}

const ActivityItemPC: React.FC<ActivityItemProps> = ({
  type,
  text,
  description,
  customClass,
  customHidden
}) => {
  // const dispatch = useAppDispatch()

  // useEffect(() => {
  //   dispatch(getInfo())
  // }, [])

  let user: any
  const _user = localStorage.getItem('user')
  if (_user) {
    user = JSON.parse(_user)
  }
  const roleType = user?.role?.type
  return (
    <div
      className={`activity-item-web mb3 ${type} ${customClass ? customClass : ''} ${
        customHidden ? customHidden : ''
      }`}
    >
      {roleType == ROLE_TYPE.GUARDIAN && type == 'submenu' ? (
        <></>
      ) : (
        <div className='home-web-wrapper'>
          <Row className='item-list-wrapper'>
            <Col span={8}>
              <div className='items'>
                <div className='title'>
                  <div className=''>
                    <img src={icons[type]} alt={text} className={`${type}-icon activity-icon`} />
                  </div>
                  <div className={`title-text ${type}`}>
                    <span>{text}</span>
                  </div>{' '}
                  <div className='line'></div>
                  <div className={`description-text ${type}`}>
                    <span>{description}</span>
                  </div>{' '}
                </div>
                <div className='list'></div>
              </div>
            </Col>
            <Col span={16}>
              <div className={`data ${type}`}>
                {type == 'lesson' && <OnlineLesson />}
                {type == 'videos' && <Video />}
                {type == 'test' && <Test />}
                {type == 'virtual' && <Virtual />}
                {roleType == ROLE_TYPE.GUARDIAN ? <></> : type == 'submenu' && <Menu />}
                <Row></Row>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}

export default ActivityItemPC
