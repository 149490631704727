import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { Col, Empty, Row, Tooltip } from 'antd'
import { BoxMessageWrapper } from '../styled'
import { changeBulletinDetail } from '@/stores/bulletinStore'

const BoxMessage = () => {
  const dispatch = useAppDispatch()
  const { bulletinData, bulletinKey } = useAppSelector(state => state.bulletin)
  return (
    <Row className='row-bulletin'>
      {bulletinData.length ? (
        bulletinData.map((item: any, idx: number) => {
          return (
            <Col
              onClick={() => dispatch(changeBulletinDetail({ ...item, index: idx + 1 }))}
              span={24}
              key={idx + 1}
              style={{
                marginTop: '20px'
              }}
            >
              <BoxMessageWrapper isSelected={bulletinKey === idx + 1 ? true : false}>
                <div className='box-tag'>
                  <div className='box-circle' />
                </div>
                <Tooltip title={item.title}>
                  <div className='box-content'>{item.title}</div>
                </Tooltip>
              </BoxMessageWrapper>
            </Col>
          )
        })
      ) : (
        <div style={{ width: '100%', margin: 'auto' }}>
          <Empty />
        </div>
      )}
    </Row>
  )
}

export default BoxMessage
