import React, { useCallback } from 'react'
import PageTitle from '@/components/common/PageTitle'
import LessonTitle from '@/components/common/LessonTitle/Lesson'
import { useNavigate, useLocation } from 'react-router-dom'
import i18Next from '@/i18n'
import { Spin } from 'antd'
import { useAppSelector } from '@/app/hooks'

export const OnlineLessonView: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { loading } = useAppSelector(state => state.subject)
  const departmentId = location.state.departmentId
  const height = window.innerHeight - 140

  const activites = [
    {
      id: 1,
      type: 'online-lesson-participation',
      text: i18Next.t('lesson.onlineLessonParticipation'),
      isActive: true
    },
    {
      id: 2,
      type: 'online-lesson-archive-view',
      text: i18Next.t('lesson.onlineLessonArchive'),
      isActive: true
    }
  ]
  const lessonOnClick = useCallback(
    type => {
      switch (type) {
        case 'online-lesson-participation':
          navigate('/online-lesson-participation', {
            state: {
              departmentId: departmentId
            }
          })
          break
        case 'online-lesson-archive-view':
          navigate('/online-lesson-archive-view', {
            state: {
              departmentId: departmentId
            }
          })
          break

        default:
          break
      }
    },
    [activites]
  )
  return (
    <div className='content-wrapper background-blue' style={{ height }}>
      <PageTitle icon='lesson-icon' title='オンラインレッスン' />
      <Spin spinning={loading}>
        <div className='lesson-wrapper lesson-list px5'>
          <div className='box'>
            {activites.map((item, index) => (
              <LessonTitle
                key={index}
                id={index}
                type={item.type}
                text={`${item.text}`}
                handleClick={lessonOnClick}
              />
            ))}
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default OnlineLessonView
