import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as API from '@/api/virtualAPI'
import type { ResponseList } from '@/interface/commonInterface'

const initialState = {
  loading: false,
  virtualList: [] as ResponseList,
  error: null as any,
  success: false
}

export const getSettingVirtuals = createAsyncThunk(
  'settings/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line camelcase
      const response = await API.getSettingVirtuals(payload)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getVirtuals = createAsyncThunk('virtuals/get', async (_, { rejectWithValue }) => {
  try {
    // eslint-disable-next-line camelcase
    const response = await API.getVirtuals()
    return response
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

const virtualSlice = createSlice({
  name: 'getSettingVirtuals',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSettingVirtuals.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getSettingVirtuals.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.virtualList = payload
    }),
    builder.addCase(getSettingVirtuals.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    }),
      
    builder.addCase(getVirtuals.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getVirtuals.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.virtualList = payload
    }),
    builder.addCase(getVirtuals.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })
  }
})

export default virtualSlice.reducer
