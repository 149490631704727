import React from 'react'
import { ListItemParams } from '@/interface/commonInterface'
import { SCREEN_SIZE } from '@/utils/screenSize'
import { useMediaQuery } from 'react-responsive'

type ItemListProps = {
  item: ListItemParams
  schoolName?: string
  customClass?: string
  iconSvg?: string
  hideName?: boolean
  handleClick: (id: string | number) => void
  roomClick?: (item: any) => void
}
const ListItem: React.FC<ItemListProps> = ({
  item,
  schoolName,
  handleClick,
  roomClick,
  customClass = '',
  iconSvg = '',
  hideName = false
}) => {
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })
  return !isMobile ? (
    <div
      className={`item-list-web ${customClass ? customClass : ''}`}
      onClick={() => {
        if (roomClick) roomClick(item)
        else handleClick(item.id)
      }}
    >
      <div className='icon-wrapper'>
        {iconSvg ? (
          <span className={`${iconSvg}-icon svg-icon`} />
        ) : (
          <img src={`${item.icon_url}`} alt={item.name} />
        )}
      </div>
      {!hideName ? (
        <div className='name-wrapper'>
          <span>{item.name}</span>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <div
      className={`item-list ${customClass ? customClass : ''}`}
      onClick={() => {
        if (roomClick) roomClick(item)
        else handleClick(item.id)
      }}
    >
      <div className='icon-wrapper'>
        {iconSvg ? (
          <span className={`${iconSvg}-icon svg-icon`} />
        ) : (
          <img src={`${item.icon_url}`} alt={item.name} />
        )}
      </div>
      {!hideName ? (
        <div className='name-wrapper'>
          <span>{item.name}</span>
          {schoolName !== '' && (
            <div className='school-name'>{` ${
              schoolName == undefined ? '' : '　(' + schoolName + ')'
            } `}</div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ListItem
