import request from '@/api/config/request'
import type { ResponseList } from '@/interface/commonInterface'

export const getClassRooms = () => {
  const url = '/class-rooms'
  return request<ResponseList, ResponseList>({
    url,
    method: 'GET'
  })
}
