import { useAppDispatch, useAppSelector } from '@/app/hooks'
import ListItem from '@/components/common/ListItem'
import PageTitle from '@/components/common/PageTitle'
import { getClassRooms } from '@/stores/classRoomStore'
import { Spin } from 'antd'
import React, { useEffect } from 'react'

const RoomView: React.FC = () => {
  const dispatch = useAppDispatch()
  const { classRoomList, loading } = useAppSelector(state => state.classRoom)
  const roomItemOnClick = item => {
    window.open(item.url, '_blank')
  }
  const height = window.innerHeight - 140

  useEffect(() => {
    dispatch(getClassRooms())
  }, [])

  return (
    <div className='content-wrapper background-pink' style={{ height }}>
      <PageTitle icon='virtual-school-icon' title='バーチャルスクール' />
      <Spin spinning={loading}>
        <div className='list-wrapper department-list school px5'>
          <div className='box'>
            {classRoomList.map((item, index) => (
              <ListItem
                key={index}
                item={item}
                roomClick={roomItemOnClick}
                handleClick={() => console.log('id')}
                customClass='room-item'
                iconSvg='presentation'
              />
            ))}
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default RoomView
