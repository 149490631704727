import { Video } from '@/interface/videoInterface'
import { StarFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React from 'react'
type VideoItemProps = {
  item: Video
  itemIndex: number
  customClass?: string
  handleClick: (id: any | undefined, index: number) => void
  handleLikeClick: (index: any, isLiked: any) => void
}

const VideoItem: React.FC<VideoItemProps> = ({
  item,
  itemIndex,
  handleClick,
  handleLikeClick,
  customClass = ''
}) => {
  const onLikeBtnClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation()
    handleLikeClick(itemIndex, item)
  }
  return (
    <div
      className={`video-item ${customClass ? customClass : ''}`}
      onClick={() => handleClick(item, itemIndex)}
    >
      <div className='thumb'>
        <video
          className={`${item.thumbnail_url == null ? 'video2' : 'video1'}`}
          width='100%'
          height='100%'
          poster={`${item.thumbnail_url}`}
        >
          <source src={`${item.url}`} type='video/mp4' />
          <source src={`${item.url}`} type='video/ogg' />
          <source src={`${item.url}`} type='video/webm' />
          <object data={`${item.url}`} width='100%' height='100%'>
            <embed src='video.swf' width='100%' height='100%' />
          </object>
        </video>
        <span className='status-line' style={{ width: `${item.process_percent}%` }} />
      </div>
      <div className='actions'>
        <Tooltip placement='leftTop' title={`いいね ${item.favorite_count}`}>
          <StarFilled
            className={item.is_favorited ? 'active' : ''}
            style={{ fontSize: 17 }}
            onClick={e => onLikeBtnClick(e)}
          />
        </Tooltip>
        <div className={`eye-icon viewed ${item.process_percent >= 50 ? 'active' : ''}`} />
      </div>
      <div className='info-wrapper'>
        <div
          className='chapter-text'
          style={{ fontSize: 16 }}
        >{`${item.chapter_name} ${item.order}`}</div>
        {/* <div className='title-text mt2'>{item.title}</div> */}
        <Tooltip title={item.title}>
          <div className='title-text mt2 box-content'>{item.title}</div>
        </Tooltip>
      </div>
    </div>
  )
}

export default VideoItem
