import request from '@/api/config/request'

export const updateToken = payload => {
  const url = '/users/fire-base'
  return request<any, any>({
    url,
    method: 'POST',
    data: {
      fcm_token: payload
    }
  })
}
