/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAppSelector } from '@/app/hooks'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { DOMAIN_PROD } from '@/utils/constants'
import { reverseString } from '@/utils/helpers'

const AuthGuard: React.FC = () => {
  const { accessToken } = useAppSelector(state => state.login)
  const auth = accessToken != null ? true : null
  // const generateRandomString = Math.random().toString(36)
  const domain = window.location.hostname
  let flag
  if (auth == null) {
    if (domain === DOMAIN_PROD) flag = true
    else flag = false
  }
  // const location = useLocation()
  const code = reverseString(domain)
  console.log('Login:', code)

  return auth ? <Outlet /> : <Navigate to='/login' />

  // !flag ? (
  //   <Navigate to='/login' />
  // ) : (
  //   <>
  //     {/* {location.pathname == '/login' ? <Navigate to='/not-found' /> : <Navigate to='/not-found' />} */}
  //     <Navigate to='/not-found' />
  //   </>
  // )
}

export default AuthGuard
