import request from '@/api/config/request'
import { BulletinParams } from '@/interface/type'

export const getBulletin = (params: BulletinParams) => {
  const url = '/notifications'
  return request<any, any>({
    url,
    method: 'GET',
    params: { per_page: params.perPage, search: params.search }
  })
}
