import { SCREEN_SIZE } from '@/utils/screenSize'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Outlet } from 'react-router-dom'
import Header from '../Layout/Header'
import MainMenu from './Menu'
import MainMenuWeb from './MenuWeb'

type Layout = {
  children: JSX.Element
}
const Layout: React.FC = () => {
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })
  if (!isMobile) {
    return (
      <>
        <Header />
        <section className='master-layout'>
          <Outlet />
        </section>
        <MainMenuWeb />
      </>
    )
  } else {
    return (
      <div style={{ overflow: 'hidden', height: '100vh' }}>
        <div className='sticky-header'>
          <Header />
        </div>
        <div id='bg-height' className='master-layout-mobile'>
          <Outlet />
        </div>
        <div className='sticky-footer'>
          <MainMenu />
        </div>
      </div>
    )
  }
}

export default Layout
