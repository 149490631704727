import { ACCESS_TOKEN, API_BASE_URL } from '@/utils/constants'

export function getWeekDay(dateString) {
  const days = ['日', '月', '火', '水', '木', '金', '土']
  const data = new Date(dateString)
  return days[data.getDay()]
}

export function padTo2Digits(num) {
  return String(num).padStart(2, '0')
}

export function getHours(dateString) {
  const data = new Date(dateString)
  return padTo2Digits(data.getHours()) + ':' + padTo2Digits(data.getMinutes())
}

export const downloadAttachment = item => {
  const token = localStorage.getItem(ACCESS_TOKEN)

  const baseURLString = API_BASE_URL + '/api'
  fetch(`${baseURLString}/download/${item.id}?type=file`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`
    })
  }).then(response => {
    response.blob().then(blob => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob)
      // Setting various property values
      const alink = document.createElement('a')
      alink.href = fileURL
      alink.download = item.name
      alink.click()
      alink?.parentNode?.removeChild(alink)
    })
  })
}

export function reverseString(str) {
  let newString = ''
  for (let i = str.length - 1; i >= 0; i--) {
    newString += str[i]
  }
  return newString
}


export const downloadFileAttachmentChat = item => {
  const token = localStorage.getItem(ACCESS_TOKEN)
  const baseURLString = API_BASE_URL + '/api'
  fetch(`${baseURLString}/download/${item.id}?type=file`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`
    })
  }).then(response => {
    response.blob().then(blob => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob)
      // Setting various property values
      const alink = document.createElement('a')
      alink.href = fileURL
      alink.download = item.file_path
        .substring(item.file_path.lastIndexOf('/') + 1)
        .split('_')
        .slice(1)
        .join('_')
      alink.click()
      alink?.parentNode?.removeChild(alink)
    })
  })
}

export function getDateMessage(dateString) {
  const currentdate = new Date(dateString)
  const seconds = Math.floor((+new Date() - +currentdate) / 1000)
  let interval = seconds / 31536000
  let data = 0

  interval = seconds / 3600
  if (interval < 24) {
    if (interval > 1 && interval < 24) {
      data = Math.floor(interval)
      return data + '時間前'
    }
    interval = seconds / 60
    if (interval > 1) {
      data = Math.floor(interval)
      return data + '分前'
    }
    data = Math.floor(interval)
    if (data > 1) {
      return data + '秒前'
    } else {
      return '0秒前'
    }
  } else {
    const date =
      '  ' +
      padTo2Digits(currentdate.getFullYear()) +
      '/' +
      padTo2Digits(currentdate.getMonth() + 1) +
      '/' +
      padTo2Digits(currentdate.getDate())
    return date
  }
}
