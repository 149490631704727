import React from 'react'
import { BoxMessageWrapper } from '../styled'
import { useNavigate } from 'react-router-dom'
import { Col, Empty, Row, Tooltip } from 'antd'
import { useAppSelector } from '@/app/hooks'

const BoxMessage = () => {
  const naigate = useNavigate()
  const { bulletinData } = useAppSelector(state => state.bulletin)
  return bulletinData.length ? (
    <Row>
      {bulletinData.map((item: any, idx: number) => {
        return (
          <Col
            span={24}
            key={idx + 1}
            style={{
              marginTop: '20px'
            }}
          >
            <BoxMessageWrapper>
              <div className='box-tag'>
                <div className='box-circle' />
              </div>
              <Tooltip title={item.title}>
                <div
                  className='box-content'
                  onClick={() => {
                    naigate(`/bulletin/${item.id}`, {
                      state: {
                        ...item
                      }
                    })
                  }}
                >
                  {item.title}
                </div>
              </Tooltip>
            </BoxMessageWrapper>
          </Col>
        )
      })}
    </Row>
  ) : (
    <div
      style={{
        width: '100%',
        margin: 'auto'
      }}
    >
      <Empty />
    </div>
  )
}

export default BoxMessage
