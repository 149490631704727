import React, { useEffect } from 'react'
import { getInfo } from '@/stores/userStore'
import { useAppDispatch } from '@/app/hooks'
import { SCREEN_SIZE } from '@/utils/screenSize'
import { useMediaQuery } from 'react-responsive'

type LessonTitleEnProps = {
  type: string
  text: string
  handleClick: (id: string | number) => void
}

export const LessonEnTitle: React.FC<LessonTitleEnProps> = ({ type, text, handleClick }) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getInfo())
  }, [])

  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })

  return (
    <div className={`lesson-item-list-web mb3 ${type}`} onClick={() => handleClick(type)}>
      <div className='icon-wrapper'>
        <span className='eng-lesson-icon'></span>
      </div>
      <div className='name-wrapper'>
        {isMobile ? (
          <span>{text}</span>
        ) : (
          <div>
            <span>
              英会話 <br></br>レッスン予約
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default LessonEnTitle
