import React from 'react'
import { Col, Row, Form } from 'antd'

type PageTitleProps = {
  icon: 'home-icon' | 'videos-icon' | 'mini-test-icon' | 'virtual-school-icon' | 'lesson-icon'
  title: string
  description: string
}

const PageTite: React.FC<PageTitleProps> = ({ title, description }) => {
  return (
    <>
      <Row>
        <Col span={10}>
          <div className='page-title-wrapper-web text-align-center px5'>
            <Form.Item className='item-title'>
              <Row className='item-header'>
                <Col span={8}>
                  {' '}
                  <span className='virtual-room-icon svg-icon' />
                </Col>
                <Col span={10}>
                  {' '}
                  <div className='title'>{title}</div>
                </Col>
              </Row>
              <Row className='text-selected-item'>{description}</Row>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default PageTite
