import request from '@/api/config/request'
import type { ResponseList } from '@/interface/commonInterface'

export const getChapters = chapterId => {
  const url = '/chapters'
  return request<ResponseList, ResponseList>({
    url,
    method: 'GET',
    // eslint-disable-next-line camelcase
    params: { subject_id: chapterId }
  })
}
