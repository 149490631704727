/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect } from 'react'
import { Button, Form, Input, Alert, Space } from 'antd'
import { userLogin } from '@/stores/login'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { useNavigate, Link } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import LoginLogo from '@/assets/imgs/logo_login.svg'
import i18Next from '@/i18n'
import { ROUTE } from '@/router/config'
import { REGEX } from '@/utils/regex'
import { DOMAIN_PROD } from '@/utils/constants'

const LoginView: React.FC = () => {
  const { loading, error } = useAppSelector(state => state.login)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onFinish = (values: any) => {
    dispatch(userLogin(values))
      .then(response => {
        const data = response?.payload?.me?.first_login_at
        if (data == null && data !== undefined) {
          navigate('/change')
        } else {
          navigate('/home')
        }
      })
      .catch(() => {
        navigate('/login')
      })
  }

  const onFinishFailed = () => {}

  useEffect(() => {
    const isAuth = localStorage.getItem('access_token')
    const domain = window.location.hostname
    // if (isAuth == null) {
    //   if (domain === DOMAIN_PROD)
    //     if (location.pathname == '/login') navigate('/not-found')
    // }
    if (isAuth && isAuth !== 'undefined') {
      navigate('/')
    }
  }, [])

  return (
    <section className='full-page section-image background-primary'>
      <div className='login-wrapper'>
        <div className='logo-wrapper mb10'>
          <img className='logo' src={LoginLogo} alt='logo' />
        </div>
        <h3>{i18Next.t('login.title')}</h3>
        {error && (
          <Space direction='vertical' style={{ width: '80%' }} className='mb2'>
            <Alert
              message={error}
              type='error'
              action={<Button size='small' type='text' />}
              closable
            />
          </Space>
        )}
        <Form
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          layout={'vertical'}
          className={'login-form'}
        >
          <Form.Item
            label={i18Next.t('login.mail')}
            name='mailAddress'
            rules={[
              {
                required: true,
                message: `${i18Next.t('login.requiredEmail')}`
              },
              {
                type: 'email',
                message: `${i18Next.t('login.messageEmailValidation')}`
              }
            ]}
            className='login-input mb5'
          >
            <Input className='input' />
          </Form.Item>
          <Form.Item
            label={i18Next.t('login.password')}
            name='password'
            rules={[
              {
                required: true,
                message: `${i18Next.t('login.requiredPassword')}`
              },
              {
                pattern: REGEX.PASSWORD,
                message: `${i18Next.t('login.messagePasswordValidation')}`
              }
            ]}
            className='login-input mb5'
          >
            <Input.Password className='input' />
          </Form.Item>
          <div className='forgot-text mb3'>
            パスワードを忘れた方は
            <Link to={ROUTE.FORGOT_PASSWORD.PATH}>こちら</Link>
          </div>

          <Button className='login-btn' htmlType='submit'>
            {loading ? <LoadingOutlined /> : `${i18Next.t('login.title')}`}
          </Button>
        </Form>
      </div>
      <div className='text-corner'>事業再構築</div>
    </section>
  )
}

export default LoginView
