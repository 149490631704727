import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as API from '@/api/classRoom'
import type { ResponseList } from '@/interface/commonInterface'

const initialState = {
  loading: false,
  classRoomList: [] as ResponseList,
  error: null as any,
  success: false
}

export const getClassRooms = createAsyncThunk('classRoom/get', async (_, { rejectWithValue }) => {
  try {
    // eslint-disable-next-line camelcase
    const response = await API.getClassRooms()
    return response
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

const classRoomSlice = createSlice({
  name: 'classRoom',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getClassRooms.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getClassRooms.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.classRoomList = payload
    }),
      builder.addCase(getClassRooms.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
  }
})

export default classRoomSlice.reducer
