import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as API from '@/api/authAPI'

const accessToken = localStorage.getItem('access_token')
  ? localStorage.getItem('access_token')
  : null

const initialState = {
  loading: false,
  accountInfo: null as any,
  accessToken,
  error: null,
  success: false
}

let oldPassword

export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ mailAddress, password }: any, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line camelcase
      oldPassword = password
      const response = await API.login({ mail_address: mailAddress, password })
      response.accessToken = response.access_token
      localStorage.setItem('access_token', response.access_token)
      localStorage.setItem('user', JSON.stringify(response.me))
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const userLogout = createAsyncThunk('auth/logout', async () => {
  try {
    const response = await API.logout()
    localStorage.removeItem('access_token')
    localStorage.removeItem('user')
    return response
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      return error.response.data.message
    } else {
      return error.message
    }
  }
})

export const userChangePassword = createAsyncThunk(
  'auth/change-password',
  async ({ password }: any, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line camelcase
      const response = await API.changePassword({ old_password: oldPassword, password })
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const userFirstChangePassword = createAsyncThunk(
  'auth/change-password',
  async ({ password }: any, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line camelcase
      const response = await API.changePassword({ old_password: oldPassword, password })
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [userLogin.pending as any]: (state: any) => {
      state.loading = true
      state.error = null
    },
    [userLogin.fulfilled as any]: (state: any, { payload }) => {
      state.loading = false
      state.accountInfo = payload.me
      state.accessToken = payload.accessToken
    },
    [userLogin.rejected as any]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [userLogout.pending as any]: (state: any) => {
      state.loading = true
      state.error = null
    },
    [userLogout.fulfilled as any]: (state: any) => {
      state.loading = false
      state.accessToken = null
      state.accountInfo = null
    },
    [userLogout.rejected as any]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [userChangePassword.pending as any]: (state: any) => {
      state.loading = true
      state.error = null
    },
    [userChangePassword.fulfilled as any]: (state: any) => {
      state.loading = false
    },
    [userChangePassword.rejected as any]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    }
  }
})

export default authSlice.reducer
