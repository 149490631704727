import request from '@/api/config/request'
import { MyPageParams } from '@/interface/type'

export const getInfoMyPage = (params: MyPageParams) => {
  const url = '/my-page'
  return request<any, any>({
    url,
    method: 'GET',
    params: { user_id: params.userId, search: params.search }
  })
}
