import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as API from '@/api/subjectApi'
import type { ResponseList } from '@/interface/commonInterface'

const initialState = {
  loading: false,
  subjectsList: [] as ResponseList,
  listByUser: [] as ResponseList,
  maxViewedCount: 0,
  error: null as any,
  success: false
}

export const getSubjects = createAsyncThunk(
  'subject/get',
  async (payload: string, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line camelcase
      const response = await API.getSubjects(payload)
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getListByUser = createAsyncThunk(
  'subject/listByUser',
  async (_, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line camelcase
      const response = await API.listByUser()
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

const subjectSlice = createSlice({
  name: 'subject',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSubjects.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getSubjects.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.subjectsList = payload
    }),
      builder.addCase(getSubjects.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })

    builder.addCase(getListByUser.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getListByUser.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      const temp = Math.max(...payload.map(obj => obj.viewed_video_count))
      state.maxViewedCount = temp
      state.listByUser = payload
    }),
      builder.addCase(getListByUser.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
  }
})

export default subjectSlice.reducer
