import { Col, Row, Spin } from 'antd'
import React, { useEffect } from 'react'
import messageIcon from '@/assets/imgs/message.png'
import { BulletinWrapper } from './styled'
import BoxMessage from './components/BoxMessage'
import MessageDetail from './components/MessageDetail'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { getBulletinData } from '@/stores/bulletinStore'

const BulletinPC = () => {
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector(state => state.bulletin)

  useEffect(() => {
    dispatch(getBulletinData({ perPage: 1 }))
  }, [])
  return (
    <Spin spinning={loading}>
      <BulletinWrapper>
        <img src={messageIcon} alt='' width={30} />
        <p>掲示板</p>
        <Row gutter={24} style={{ width: '100%', maxWidth: '1200px' }}>
          <Col span={10}>
            <BoxMessage />
          </Col>
          <Col span={14}>
            <MessageDetail />
          </Col>
        </Row>
      </BulletinWrapper>
    </Spin>
  )
}

export default BulletinPC
