import { Row } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
// import { Wrapper } from './styled'

const PageNotFound = () => {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/')
    }, 0)
  }, [])

  return (
    <Row align={'middle'} justify='center'>
      {/* <Wrapper>Coming Soon!</Wrapper> */}
    </Row>
  )
}

export default PageNotFound
