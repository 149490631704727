import OTPInput from 'react-otp-input'
import CryptoJS from 'crypto-js'
import { FillBlankWrapper } from '../styled'
import { useState } from 'react'

const FillBlank = ({ value = '', onChange, textAnswer, showCorrectOption, question }: any) => {
  const [valueOTP, setValueOTP] = useState('')

  const encryptedAtob = atob(question.correct_answer)
  const encrypted = JSON.parse(encryptedAtob)
  const iv = CryptoJS.enc.Base64.parse(encrypted.iv)
  const stringAnswer = encrypted.value
  const secret = CryptoJS.enc.Base64.parse(process.env.REACT_APP_KEY_CRYPTO || '')
  const decryptedString = CryptoJS.AES.decrypt(stringAnswer, secret, {
    iv: iv
  })
  const decrypted = decryptedString.toString(CryptoJS.enc.Utf8)

  const changeOTP = (e: any) => {
    onChange([e])
    setValueOTP(e)
  }
  return (
    <FillBlankWrapper>
      <OTPInput
        value={value}
        onChange={changeOTP}
        numInputs={textAnswer}
        renderSeparator={<span />}
        inputStyle='abc'
        shouldAutoFocus={true}
        renderInput={props => <input disabled={showCorrectOption} {...props} />}
      />
      <div>
        {showCorrectOption ? (
          valueOTP === decrypted ? (
            <img
              src={process.env.PUBLIC_URL + '/images/correct-ans.png'}
              alt=''
              width={24}
              height={24}
            />
          ) : (
            <div style={{ color: 'red', fontSize: '20px', fontWeight: 500 }}>{decrypted}</div>
          )
        ) : (
          <div style={{ width: '24px', height: '24px' }} />
        )}
      </div>
    </FillBlankWrapper>
  )
}

export default FillBlank
