import styled from 'styled-components'

interface Props {
  isSelected: boolean
}

export const BulletinWrapper = styled.div`
  background-color: #f9f0ea;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 40px 0 0 0;

  p {
    font-size: 14px;
    margin: 0;
  }

  .row-bulletin {
    overflow-y: auto;
    max-height: calc(100vh - 162px);

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: grey;
      border-radius: 10px;
    }
  }

  @media screen and (max-width: 600px) {
  }
`

export const MessageDetailWrapper = styled.div`
  background-color: #f9f0ea;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-items: center;

  .box-container {
    width: 100%;
    max-width: 600px;
    position: relative;
    background-color: #fff;
    border-radius: 25px;
    padding: 20px;
    margin: 20px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content-border {
    background-color: #eeeeef;
    padding: 20px;
    font-size: 15px;
    height: calc(100vh - 316px);
    min-height: 100px;
    width: 100%;
    overflow: auto;
    margin: 20px 0;
  }

  .box-tag {
    top: -10px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    /* z-index: 99; */
    height: 30px;
    width: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  .box-container-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    .box-container {
      max-width: 400px;
    }
  }
`

export const BoxMessageWrapper = styled.div<Props>`
  background-color: #fff;
  /* width: 308px; */
  position: relative;
  margin: 0 auto;
  border-radius: 11px;
  padding: 25px 10px 10px 14px;

  .box-tag {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    /* z-index: 99; */
    height: 30px;
    width: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  .box-content {
    background: ${({ isSelected }) =>
      isSelected ? 'linear-gradient(90deg, #faed00 0%, #e95513 100%)' : '#eeeeef'};
    font-size: 20px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
    /* background: #eeeeef; */
    :hover {
      cursor: pointer;
      background: linear-gradient(90deg, #faed00 0%, #e95513 100%);
    }
  }
`
