import React from 'react'
import { Col, Row } from 'antd'
import Logo from '@/assets/imgs/logo.svg'
import NavProfile from './NavProfile'
import { useMediaQuery } from 'react-responsive'
import { SCREEN_SIZE } from '@/utils/screenSize'
import { useLocation } from 'react-router-dom'
import { ROUTE } from '@/router/config'
import { COLOR_PAGE } from '@/utils/constants'

const Header: React.FC = () => {
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })
  const location = useLocation()

  const bgNav = pathname => {
    switch (pathname) {
      case ROUTE.HOME:
      case ROUTE.VIRTUAL:
      case ROUTE.SCHOOL:
        return COLOR_PAGE.GREY
      case ROUTE.DEPARTMENT:
      case ROUTE.SUBJECT:
      case ROUTE.CHAPTER:
      case ROUTE.VIDEO:
      case ROUTE.VIDEO_DETAIL:
      case ROUTE.DEPARTMENT_TEST:
      case ROUTE.SUBJECT_TEST:
      case ROUTE.CHAPTER_TEST:
      case ROUTE.ROOM:
      case ROUTE.VIDEO_TEST:
        return COLOR_PAGE.PINK

      case ROUTE.MY_PAGE:
        return COLOR_PAGE.YELLOW

      case ROUTE.CONTACT:
        return COLOR_PAGE.GREY

      case ROUTE.ONLINE_LESSON:
      case ROUTE.ONLINE_LESSON_PARTICIPATION:
      case ROUTE.ONLINE_LESSON_DEPARTMENT:
      case ROUTE.ONLINE_LESSON_EN:
      case ROUTE.ONLINE_LESSON_ARCHIVE:
        return COLOR_PAGE?.GREEN

      case ROUTE.MINI_TEST:
        return COLOR_PAGE?.GREY_100

      case ROUTE.BULLETIN:
      case ROUTE.BULLETIN_DETAIL:
        return COLOR_PAGE?.YELLOW_100
      default:
        return 'transparent'
    }
  }
  return (
    <>
      <header
        className='header-wrapper w-100'
        style={{ backgroundColor: isMobile ? bgNav(location.pathname) : 'transparent' }}
      >  
        <Row className='px5 w-100'>
          <Col span={14}>
            <img className='logo' src={Logo} alt='logo' />
          </Col>
          <Col span={6} />
          <Col span={4}>
            <div className='right-icon'>
              <div className='item'>
                <NavProfile />
              </div>
            </div>
          </Col>
        </Row>
      </header>
    </>
  )
}

export default Header
