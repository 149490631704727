const common = {
  home: {
    onlineLesson: 'オンライン \n レッスン',
    onlineLessonDescription: 'オンラインレッスンの \n 予約や確認ができます',
    learnByVideo: '動画で学ぶ',
    learnByVideoDescription: '動画で自分に合った 授業を選ぼう',
    mypage: 'マイページ',
    menu: 'メニュー',
    menuDescription: 'その他のメニューを見る',
    miniTest: '小テスト',
    miniTestDescription: 'これまでに学習した 内容を確認しよう',
    message: '掲示板',
    virtualSchool: 'バーチャル \n スクール',
    virtualSchoolDescription: 'バーチャル空間で講義 を受けよう'
  },
  login: {
    title: 'ログイン',
    mail: 'メールアドレス',
    password: 'パスワード',
    requiredEmail: 'メイルアドレスを入力してください',
    requiredPassword: 'パスワードを入力してください',
    messageEmailValidation: 'メールアドレスの書式のみ有効です',
    passwordNotSpace: 'メールアドレスを入力してください',
    messagePasswordValidation: 'パスワードを半角英数字、6～14 文字で入力してください',
    logoutMsg: 'ログアウトしますか？'
  },
  password_change: {
    title: 'パスワードの変更',
    submit: 'パスワードを変更',
    password: 'パスワード',
    requiredPassword: '現在のパスワードを入力してください',
    requiredCode: '確認コードを入力してください',
    requiredContent:
      '新しいパスワードを入力して再設定します。\n 半角英数字、6～14 文字で設定してください。\n※特殊文字（＆, $, @,...）は使えません。',
    requiredContent1: '新しいパスワードを入力して再設定します。',
    requiredContent2: '半角英数字、6～14 文字で設定してください。',
    requiredContent3: '※特殊文字（＆, $, @,...）は使えません。',
    enterNewPassword: '新しいパスワードを入力してください',
    enterNewPasswordConfirm: '新しいパスワード（確認用）を入力してください',
    reEnterPassword: '新しいパスワード（確認用）を入力してください',
    passwordNotSame: 'パスワードとパスワード（確認用）が一致しません',
    newPasswordConfirmNotSame: '新しいパスワードと新しいパスワード（確認用）が一致しません',
    passwordNotSpace: ' 現在のパスワードを入力してください',
    reEnterpasswordNotSpace: '新しいパスワード（確認用）を入力してください',
    passwordNotMatch: '新しいパスワードと新しいパスワード（確認用）が一致しません',
    passwordValidation: 'パスワードを半角英数字、6～14 文字で入力してください'
  },
  password_forgot: {
    title: 'パスワードの再設定',
    submit: '確認メールを送信',
    requiredEmail: 'メイルアドレスを入力してください',
    requiredPassword: 'パスワードを入力してください',
    requiredContent1: 'パスワードを再設定します。',
    requiredContent2: '登録されているメールアドレスを入力し、',
    requiredContent3: '確認メール送信ボタンを押してください。',
    email: 'メールアドレスの書式のみ有効です'
  },
  password_verification: {
    title: 'パスワードの変更', // '確認コード入力',
    submit: 'パスワードを変更',
    requiredCode: '確認コードを入力してください',
    requiredContent:
      '登録されているメールアドレスに確認コードをお送りしました。\nメールを確認し、この画面 に数字4桁の認証コードを入力し、\n新しいパスワードを入力して再設定してください。',
    requiredContent1: '登録されているメールアドレスに確認コードを',
    requiredContent2: 'お送りしました。メールを確認し、この画面に',
    requiredContent3: '数字4桁の認証コードを入力し、新しい',
    requiredContent4: 'パスワードを入力して再設定してください。',
    code: '確認コード',
    validationCode: '確認コードを数字4桁で入力してください'
  },
  modal: {
    cancel: 'キャンセル',
    send: '送信',
    title: '送信の確認'
  },
  lesson: {
    onlineLessonParticipation: 'オンラインレッスン\nに参加する ',
    onlineLessonArchive: 'アーカイブを視聴する',
    onlineLessonEn: '英会話\nレッスン予約'
  },
  contact: {
    requiredName: '氏名を入力してください',
    requiredEmail: 'メイルアドレスを入力してください',
    requiredContent: '内容を入力してください',
    requiredPhone: '電話番号を入力してください',
    messageEmailValidation: 'メールアドレスの書式のみ有効です',
    sizeValid: 'ファイルサイズ5GB以内の画像をアップロードしてください',
    error: '無効な拡張子が含まれるためアップロードに失敗しました。',
    note: '注意：アプロードするファイルの1つの最大容量は５GBです。'
  }
}

export default common
