import React from 'react'
import styles from './index.module.scss'
import { Progress, Spin } from 'antd'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

interface IResultTestProps {
  totalPoint: number
  totalQuestion: number
  loading: boolean
}

const ResultTest: React.FC<IResultTestProps> = ({ totalQuestion, totalPoint, loading }) => {
  const navigate = useNavigate()

  const percentCorrect = totalPoint / totalQuestion

  const showTitleCongrats = (percent: number) => {
    switch (true) {
      case percent === 1:
        return (
          <div className='box-title'>
            {/* <Progress
              size={200}
              type='circle'
              percent={percent * 100}
              format={() => {
                return (
                  <div className='dot-result  dot-result-congrats'>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <div className='correct-option'>{totalPoint}</div>
                      <div className='total-option'>/{totalQuestion}</div>
                    </div>
                  </div>
                )
              }}
              style={{ marginBottom: '30px' }}
            /> */}
            <div style={{ marginBottom: '30px' }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  `images/imgTest/${totalQuestion === 20 ? 'score20' : 'score10'}.png`
                }
                alt=''
              />
            </div>
            <img src={process.env.PUBLIC_URL + 'images/imgTest/perfect.png'} alt='' />
          </div>
        )

      case percent < 1 && percent > 0.7:
        return (
          <div className='box-title'>
            <Progress
              type='circle'
              size={200}
              percent={percent * 100}
              format={() => {
                return (
                  <div className='dot-result dot-result-great'>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <div className='correct-option'>{totalPoint}</div>
                      <div className='total-option'>/{totalQuestion}</div>
                    </div>
                  </div>
                )
              }}
              strokeWidth={10}
              strokeLinecap='butt'
              strokeColor={'#FF5277'}
              style={{ marginBottom: '30px' }}
            />
            <img src={process.env.PUBLIC_URL + 'images/imgTest/great.png'} alt='' />
          </div>
        )

      default:
        return (
          <div className='box-title'>
            <Progress
              type='circle'
              size={200}
              percent={percent * 100}
              format={() => {
                return (
                  <div className='dot-result  dot-result-good'>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <div className='correct-option'>{totalPoint || 0}</div>
                      <div className='total-option'>/{totalQuestion}</div>
                    </div>
                  </div>
                )
              }}
              strokeWidth={10}
              strokeLinecap='butt'
              strokeColor={'#53D3D3'}
              style={{ marginBottom: '30px' }}
            />

            <img src={process.env.PUBLIC_URL + 'images/imgTest/good.png'} alt='' />
          </div>
        )
    }
  }

  return (
    <Spin spinning={loading}>
      <div style={{ maxWidth: 695, margin: '0 auto' }}>
        <div className={styles.resultWrap}>{showTitleCongrats(percentCorrect)}</div>
        <div className={styles.btnActions}>
          <div />

          <button
            className={classNames(styles.btn)}
            onClick={() => {
              navigate(-1)
            }}
          >
            <div>{'次'}&nbsp;</div>
            <img src={process.env.PUBLIC_URL + '/images/next.svg'} alt='' />
          </button>
        </div>
      </div>
    </Spin>
  )
}

export default ResultTest
