import React from 'react'
import { ListItemParams } from '@/interface/commonInterface'

type ItemListProps = {
  item: ListItemParams
  customClass?: string
  iconSvg?: string
  type: string | 'videos' | 'test' | 'chapter' | 'room'
  roomClick?: (item: any) => void
}

const ListSubItemWeb: React.FC<ItemListProps> = ({ item, roomClick, iconSvg = '', type }) => {
  return (
    <div
      className={`item-list ${type}`}
      onClick={() => {
        if (roomClick) roomClick(item)
      }}
    >
      <div className='icon-wrapper'>
        {iconSvg ? (
          <span className={`${iconSvg}-icon svg-icon`} />
        ) : (
          <img className='img-custom' src={`${item.icon_url}`} alt={item.name} />
        )}
      </div>
      <div className='name-wrapper'>
        <span>{item.name == undefined ? item.title : item.name}</span>
      </div>
    </div>
  )
}

export default ListSubItemWeb
