import { SCREEN_SIZE } from '@/utils/screenSize'
import * as React from 'react'
import { useMediaQuery } from 'react-responsive'
import BulletinPC from '../BulletinPC'
import BulletinMobile from '../BulletinMobile'

interface IBulletinProps {}

const Bulletin: React.FunctionComponent<IBulletinProps> = () => {
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })
  return isMobile ? <BulletinMobile /> : <BulletinPC />
}

export default Bulletin
