import request from '@/api/config/request'
import type { ResponseList } from '@/interface/commonInterface'

export const getDepartments = () => {
  const url = '/departments'
  return request<ResponseList, ResponseList>({
    url,
    method: 'GET'
  })
}
