import React from 'react'
import { ListItemParams } from '@/interface/commonInterface'
import { SCREEN_SIZE } from '@/utils/screenSize'
import { useMediaQuery } from 'react-responsive'
import { Col, Row, Form } from 'antd'
import { ROLE_TYPE } from '@/utils/constants'

type ItemListProps = {
  item: ListItemParams
  schoolName?: string
  customClass?: string
  iconSvg?: string
  hideName?: boolean
  handleClick: (id: string | number) => void
  roomClick?: (item: any) => void
  type: string | 'message' | 'videos' | 'test' | 'lesson' | 'submenu' | 'virtual'
  customHidden?: string
  list?
}

const ListItemWeb: React.FC<ItemListProps> = ({
  item,
  schoolName,
  handleClick,
  roomClick,
  customClass = '',
  iconSvg = '',
  hideName = false,
  type,
  customHidden
}) => {
  let user: any
  const _user = localStorage.getItem('user')
  if (_user) {
    user = JSON.parse(_user)
  }
  const userType = user?.role?.type
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })
  // console.log(list, schoolName)

  if (
    userType == ROLE_TYPE.GUARDIAN ||
    userType == ROLE_TYPE.STAFF ||
    userType == ROLE_TYPE.TEACHER
  ) {
    {
      return !isMobile ? (
        <div
          className={`item-list-web ${type} ${customClass ? customClass : ''} ${
            customHidden ? customHidden : ''
          }`}
          onClick={() => {
            if (roomClick) roomClick(item)
            else handleClick(item.id)
          }}
        >
          {(type == 'school' || type == 'submenu' || type == 'message') && (
            <>
              <div className='icon-wrapper'>
                {iconSvg ? (
                  <span className={`${iconSvg}-icon svg-icon`} />
                ) : customClass !== 'img-custom' ? (
                  <img src={`${item.icon_url}`} alt={item.name} />
                ) : (
                  <img className='img-custom' src={`${item.icon_url}`} alt={item.name} />
                )}
              </div>

              <div>
                {!hideName ? (
                  <div className='name-wrapper'>
                    <div className='virutal-text'>
                      {item.name == undefined ? item.title : item.name}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}

          {/* Videos & MiniTest start*/}
          {type !== 'school' && type !== 'submenu' && type !== 'message' && (
            <Form.Item>
              <Row gutter={[26, 26]}>
                <Col span={8} className='icon-wrapper'>
                  {iconSvg ? (
                    <span className={`${iconSvg}-icon svg-icon`} />
                  ) : customClass !== 'img-custom' ? (
                    <img src={`${item.icon_url}`} alt={item.name} />
                  ) : (
                    <img className='img-custom' src={`${item.icon_url}`} alt={item.name} />
                  )}
                </Col>
                <Col span={16} className='name-wrapper'>
                  {!hideName ? (
                    <div className='name-wrapper'>
                      <div className='department'>
                        {item.name == undefined ? item.title : item.name}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                {schoolName !== '' && (
                  <div className='school-name'>{` ${
                    schoolName == undefined ? '' : '　(' + schoolName + ')'
                  } `}</div>
                )}
              </Row>
            </Form.Item>
          )}
          {/* Videos & MiniTest end */}
        </div>
      ) : (
        <div
          className={`lesson-item-list ${customClass ? customClass : ''}`}
          onClick={() => {
            if (roomClick) roomClick(item)
            else handleClick(item.id)
          }}
        >
          <div className='icon-wrapper'>
            {iconSvg ? (
              <span className={`${iconSvg}-icon svg-icon`} />
            ) : (
              <img src={`${item.icon_url}`} alt={item.name} />
            )}
          </div>
          {!hideName ? (
            <div className='name-wrapper'>
              <span>{item.name == undefined ? item.title : item.name}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
      )
    }
  } else {
    return !isMobile ? (
      <div
        className={`item-list-web ${type} ${customClass ? customClass : ''} ${
          customHidden ? customHidden : ''
        }`}
        onClick={() => {
          if (roomClick) roomClick(item)
          else handleClick(item.id)
        }}
      >
        <div className='icon-wrapper'>
          {iconSvg ? (
            <span className={`${iconSvg}-icon svg-icon`} />
          ) : customClass !== 'img-custom' ? (
            <img src={`${item.icon_url}`} alt={item.name} />
          ) : (
            <img className='img-custom' src={`${item.icon_url}`} alt={item.name} />
          )}
        </div>

        {/* Videos, MiniTest, Virtual start */}
        {!hideName ? (
          <div className='name-wrapper'>
            <div className='virutal-text'>{item.name == undefined ? item.title : item.name}</div>
          </div>
        ) : (
          <></>
        )}
        {/*  Videos, MiniTest, Virtual end  */}
      </div>
    ) : (
      <div
        className={`lesson-item-list ${customClass ? customClass : ''}`}
        onClick={() => {
          if (roomClick) roomClick(item)
          else handleClick(item.id)
        }}
      >
        <div className='icon-wrapper'>
          {iconSvg ? (
            <span className={`${iconSvg}-icon svg-icon`} />
          ) : (
            <img src={`${item.icon_url}`} alt={item.name} />
          )}
        </div>
        {!hideName ? (
          <div className='name-wrapper'>
            <span>{item.name == undefined ? item.title : item.name}</span>
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  }
}

export default ListItemWeb
