import React from 'react'
import messageIcon from '@/assets/imgs/message.png'
import mypageIcon from '@/assets/imgs/mypage.png'
import onlineLesson from '@/assets/imgs/online_lesson.png'
import testIcon from '@/assets/imgs/test.png'
import virtualIcon from '@/assets/imgs/vartual_school.png'
import videoIcon from '@/assets/imgs/video.png'
type ActivityItemProps = {
  type: string | 'message' | 'videos' | 'test' | 'lesson' | 'mypage' | 'virtual'
  text: string
  handleClick: (type: string) => void
  customClass?: string
  customHidden?: string
}

const icons = {
  message: messageIcon,
  videos: videoIcon,
  test: testIcon,
  lesson: onlineLesson,
  mypage: mypageIcon,
  virtual: virtualIcon
}

const ActivityItem: React.FC<ActivityItemProps> = ({
  type,
  text,
  handleClick,
  customClass,
  customHidden
}) => {
  return (
    <div
      className={`activity-item mb3 ${type} ${customClass ? customClass : ''} ${
        customHidden ? customHidden : ''
      }`}
      onClick={() => handleClick(type)}
    >
      <div className='mb4'>
        <img src={icons[type]} alt={text} className={`${type}-icon activity-icon`} />
      </div>
      <div className='title-text'>
        <span>{text}</span>
      </div>
    </div>
  )
}

export default ActivityItem
