import { useAppDispatch, useAppSelector } from '@/app/hooks'
import ListSubItemWeb from '@/components/common/ListSubItemWeb'
import PageTitle from '@/components/common/PageTitle'
import PageTitleWeb from '@/components/common/PageTitleWeb'
import { getVirtuals } from '@/stores/virtualStore'
import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { SCREEN_SIZE } from '@/utils/screenSize'
import { useMediaQuery } from 'react-responsive'

const VirtualView: React.FC = () => {
  const dispatch = useAppDispatch()
  const { virtualList, loading } = useAppSelector(state => state.virtual)
  const roomItemOnClick = item => {
    window.open(item.url, '_blank')
  }

  useEffect(() => {
    dispatch(getVirtuals())
  }, [])

  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })
  const height = window.innerHeight - 140
  const heightPC = window.innerHeight - 70
  const heightAllPC = ''
  
  return (
    <div
      className='content-wrapper background-grey'
      style={{
        height:
          isMobile && virtualList?.data?.length > 4
            ? height
            : virtualList?.data?.length < 9
            ? heightPC
            : heightAllPC
      }}
    >
      <PageTitle icon='virtual-school-icon' title='バーチャルスクール' />
      <PageTitleWeb
        icon='virtual-school-icon'
        title='バーチャルスクール'
        description='自分の教室を選んで入室しましょう。'
      />
      <Spin spinning={loading}>
        <div className='rooms-carousel' style={{ backgroundColor: isMobile ? '#f7f7f7' : '' }}>
          <div className='box'>
            <div className='full-list room-scroll'>
              <div className='item-wrapper px5'>
                {virtualList?.data?.map((item, index) => (
                  <ListSubItemWeb
                    key={index}
                    item={item}
                    type={'room'}
                    roomClick={roomItemOnClick}
                    customClass='room-item'
                    iconSvg='presentation'
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default VirtualView
