import React, { useEffect, useState, useCallback } from 'react'
import LessonListItem from '@/components/common/LessonListItem'
import { useNavigate } from 'react-router-dom'
import { getDepartments } from '@/stores/departmentStore'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import i18Next from '@/i18n'
import LessonTitle from '@/components/common/LessonTitle/Lesson'
import LessonEnTitle from '@/components/common/LessonTitle/LessonEn'

const OnlineLesson: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [active, setActive] = useState(0)
  const [department, setDepartment] = useState(0)
  const { departmentList } = useAppSelector(state => state.department)
  const departmenItemOnClick = id => {
    const departmentType = departmentList.filter(t => t.id == id)
    departmentType[0].type == 'DEFAULT' ? setActive(1) : setActive(2)
    // setActive(id)
    navigate('/', {
      state: {
        departmentId: id
      }
    })
    setDepartment(id)
  }
  useEffect(() => {
    dispatch(getDepartments())
  }, [])

  //
  const activites = [
    {
      id: 1,
      type: 'online-lesson-participation',
      text: i18Next.t('lesson.onlineLessonParticipation'),
      isActive: true
    },
    {
      id: 2,
      type: 'online-lesson-archive-view',
      text: i18Next.t('lesson.onlineLessonArchive'),
      isActive: true
    }
  ]

  const metaLessonOnClick = useCallback(
    type => {
      switch (type) {
        case 'online-lesson-participation':
          navigate('/online-lesson-participation', {
            state: {
              departmentId: department
            }
          })
          break
        case 'online-lesson-archive-view':
          navigate('/online-lesson-archive-view', {
            state: {
              departmentId: department
            }
          })
          break

        default:
          break
      }
    },
    [activites]
  )

  const activity = [
    {
      type: 'online-lesson-en',
      text: i18Next.t('lesson.onlineLessonEn'),
      isActive: true
    }
  ]
  const enLessonOnClick = useCallback(() => window.open('https://member.class-live.com/login'), [])

  return (
    // <div className='list-wrapper lesson-list px5'>
    <div className='sub-activities-list-wrapper px5'>
      <div className='box-web scroll'>
        {active == 0 &&
          departmentList.map((item, index) => (
            <LessonListItem
              key={index}
              item={item}
              schoolName={departmentList?.length == 1 ?  '' : item?.school?.name }
              handleClick={departmenItemOnClick}
              customClass=''
            />
          ))}

        {active == 1 &&
          activites.map((item, index) => (
            <LessonTitle
              key={index}
              id={index}
              type={item.type}
              text={`${item.text}`}
              handleClick={metaLessonOnClick}
            />
          ))}

        {active == 2 &&
          activity.map((item, index) => (
            <LessonEnTitle
              key={index}
              type={item.type}
              text={`${item.text}`}
              handleClick={enLessonOnClick}
            />
          ))}
      </div>
    </div>
  )
}

export default OnlineLesson
