import React from 'react'

type PageTitleProps = {
  icon: 'home-icon' | 'videos-icon' | 'mini-test-icon' | 'virtual-school-icon' | 'lesson-icon'
  title: string
}

const PageTitleWeb: React.FC<PageTitleProps> = ({ icon, title }) => {
  return (
    <div className='page-title-wrapper text-align-center mb5'>
      <div>
        <span className={`${icon} page-title-icon`} />
      </div>
      <span className='title-text'>{title}</span>
    </div>
  )
}

export default PageTitleWeb
