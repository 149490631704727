import styled from 'styled-components'

export const BulletinWrapper = styled.div`
  background-color: #f9f0ea;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 140px);
  align-items: center;
  padding: 20px 0;

  p {
    font-size: 14px;
    margin: 0;
  }
`

export const MessageDetailWrapper = styled.div`
  background-color: #f9f0ea;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  .box-container {
    width: 100%;
    max-width: 600px;
    position: relative;
    background-color: #fff;
    border-radius: 25px;
    padding: 20px;
    margin: 20px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content-border {
    background-color: #eeeeef;
    padding: 20px;
    font-size: 15px;
    height: calc(100vh - 400px);
    overflow: auto;
    margin: 20px 0;
    width: 100%;
  }

  .box-tag {
    top: -10px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    /* z-index: 99; */
    height: 30px;
    width: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  .box-container-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .message-title {
    font-size: 20px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 600px) {
    .box-container {
      max-width: unset;
      width: 80%;
    }
  }
`

export const BoxMessageWrapper = styled.div`
  background-color: #fff;
  width: 308px;
  position: relative;
  margin: 0 auto;
  border-radius: 11px;
  padding: 25px 10px 10px 14px;

  .box-tag {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    /* z-index: 99; */
    height: 30px;
    width: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  .box-content {
    font-size: 20px;
    /* display: flex;
    align-items: center; */
    height: 50px;
    background: #eeeeef;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    :hover {
      cursor: pointer;
      background: #faed00;
      background: linear-gradient(90deg, #faed00 0%, #e95513 100%);
    }
  }
`
