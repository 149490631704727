/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import PageTitle from '@/components/common/PageTitle'
import VideoItem from './components/VideoItem'
import { Video, UpdatePayload } from '@/interface/videoInterface'
import { useNavigate, useLocation } from 'react-router-dom'
import { Spin, Col, Row, Form } from 'antd'
import { getVideos, refreshVideosList, updateFavoriteVideo, clearData } from '@/stores/videoStore'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ROUTE } from '@/router/config'
import { PopupNoti } from '@/components/common/Popup/PopupNoti'
import { ROLE_TYPE } from '@/utils/constants'
import { useMediaQuery } from 'react-responsive'
import { SCREEN_SIZE } from '@/utils/screenSize'

const VideosView: React.FC = () => {
  const location = useLocation()
  const chapterId = location.state.chapterId
  const isTest = location.pathname === ROUTE.VIDEO_TEST
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { videosList, loading, totalPage, currentPage } = useAppSelector(state => state.video)
  const [hasMore, setHasMore] = useState(true)
  const height = window.innerHeight * 0.85

  let user: any
  const _user = localStorage.getItem('user')
  if (_user) {
    user = JSON.parse(_user)
  }
  const userType = user?.role?.type
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.MOBILE })

  const videoItemOnClick = (item, index) => {
    if (isTest) {
      if (!item.is_question) {
        PopupNoti.error('このテストは準備中です')
        return
      }
      if (!item.is_test) {
        PopupNoti.error('先に動画の視聴を完了してください')
        return
      }
      navigate(ROUTE.MINI_TEST, {
        state: {
          videoId: item.id,
          chapterId: chapterId
        }
      })
    } else {
      if (userType == ROLE_TYPE.USER) {
        const previousVideo = videosList[index > 0 ? index - 1 : index]
        if (!item.is_public) {
          PopupNoti.error('この動画は公開前です')
        } else if (previousVideo?.is_viewed == null && index > 0) {
          PopupNoti.error('前の動画の視聴を完了してください')
        } else {
          navigate(ROUTE.VIDEO_DETAIL, {
            state: {
              videoId: item.id,
              chapterId: chapterId
            }
          })
        }
      } else {
        navigate(ROUTE.VIDEO_DETAIL, {
          state: {
            videoId: item.id,
            chapterId: chapterId
          }
        })
      }
      if (index > 0 && !videosList[index - 1]?.is_viewed) return
    }
  }

  const videoItemNoneClick = (item, index) => {
    if (userType == ROLE_TYPE.USER) {
      const previousVideo = videosList[index > 0 ? index - 1 : index]
      if (!item.is_public) {
        PopupNoti.error('この動画は公開前です')
      } else if (previousVideo?.is_viewed == null && index > 0) {
        PopupNoti.error('前の動画の視聴を完了してください')
      } else {
        return
      }
    } else {
      return
    }
  }

  const likeVideoOnClick = (index, item) => {
    let favoriteLoading = false
    if (favoriteLoading) return
    try {
      const favoriteData: UpdatePayload = {
        id: item.id,
        data: {
          isFavorite: item.is_favorited ? 0 : 1
        }
      }
      dispatch(updateFavoriteVideo(favoriteData)).then(() => {
        favoriteLoading = true
        const newData: Video[] = _.cloneDeep(videosList)
        // eslint-disable-next-line camelcase
        newData[index].is_favorited = item.is_favorited ? 0 : 1

        if (item.is_favorited) {
          newData[index].favorite_count--
        } else {
          newData[index].favorite_count++
        }

        dispatch(refreshVideosList(newData))
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const fetchMoreData = () => {
    if (currentPage && totalPage && currentPage >= totalPage) {
      setHasMore(false)
      return
    }
    const nexPage = currentPage ? currentPage + 1 : 1
    dispatch(getVideos({ chapterId, currentPage: nexPage }))
  }

  useEffect(() => {
    dispatch(clearData())
    dispatch(getVideos({ chapterId, currentPage: 1 }))
  }, [])

  return userType == ROLE_TYPE.USER ? (
    <div className='content-wrapper background-pink' style={{ height: 'auto' }}>
      <PageTitle
        icon={isTest ? 'mini-test-icon' : 'videos-icon'}
        title={isTest ? '小テスト' : '動画で学ぶ'}
      />
      <div className='list-wrapper videos-list'>
        <InfiniteScroll
          dataLength={videosList.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            loading ? (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Spin />
              </div>
            ) : (
              <></>
            )
          }
          endMessage={<p style={{ textAlign: 'center' }}>{/* <b>You have seen it all</b> */}</p>}
          scrollThreshold={0.99}
          className='videos-scroll'
          height={height}
        >
          {!isMobile ? (
            <Form.Item className='chapter-title'>
              <Row className='chapter-header'>
                <Col span={6}>
                  {' '}
                  <img
                    className='chapter-icon'
                    src={`${videosList[0]?.chapter_icon_url}`}
                    alt={videosList[0]?.chapter_name}
                  />
                </Col>
                <Col span={6}>
                  {' '}
                  {/* <div className='title'>{videosList[0]?.chapter_name}</div> */}
                </Col>
              </Row>
              <Row className='text-selected-chapter'>{videosList[0]?.chapter_name}</Row>
            </Form.Item>
          ) : (
            <></>
          )}

          <div className='box'>
            {videosList.map((item, index) =>
              !item.is_public ? (
                <VideoItem
                  key={index}
                  itemIndex={index}
                  item={item}
                  handleClick={videoItemNoneClick}
                  handleLikeClick={likeVideoOnClick}
                  customClass={'inactive'}
                />
              ) : (
                <VideoItem
                  key={index}
                  itemIndex={index}
                  item={item}
                  handleClick={videoItemOnClick}
                  handleLikeClick={likeVideoOnClick}
                  customClass={index > 0 && !videosList[index - 1]?.is_viewed ? 'inactive' : ''}
                />
              )
            )}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  ) : (
    <div className='content-wrapper background-pink' style={{ height: 'auto' }}>
      <PageTitle
        icon={isTest ? 'mini-test-icon' : 'videos-icon'}
        title={isTest ? '小テスト' : '動画で学ぶ'}
      />
      <div className='list-wrapper videos-list'>
        <InfiniteScroll
          dataLength={videosList.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            loading ? (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Spin />
              </div>
            ) : (
              <></>
            )
          }
          endMessage={<p style={{ textAlign: 'center' }}>{/* <b>You have seen it all</b> */}</p>}
          scrollThreshold={0.99}
          className='videos-scroll'
          height={height}
        >
          {!isMobile ? (
            <Form.Item className='chapter-title'>
              <Row className='chapter-header'>
                <Col span={6}>
                  {' '}
                  <img
                    className='chapter-icon'
                    src={`${videosList[0]?.chapter_icon_url}`}
                    alt={videosList[0]?.chapter_name}
                  />
                </Col>
                <Col span={6}>
                  {' '}
                  {/* <div className='title'>{videosList[0]?.chapter_name}</div> */}
                </Col>
              </Row>
              <Row className='text-selected-chapter'>{videosList[0]?.chapter_name}</Row>
            </Form.Item>
          ) : (
            <></>
          )}
          <div className='box'>
            {videosList.map((item, index) => (
              <VideoItem
                key={index}
                itemIndex={index}
                item={item}
                handleClick={videoItemOnClick}
                handleLikeClick={likeVideoOnClick}
                customClass={''}
              />
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default VideosView
