import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import router from '@/router'
import { onMessageListener } from '.././firebase/firebase'
import Echo from 'laravel-echo'
import { API_BASE_URL } from '@/utils/constants'

declare global {
  interface Window {
    Echo: any
  }
}
// Chat
const initSocket = () => {
  try {
    window.Echo = new Echo({
      broadcaster: 'socket.io',
      host: API_BASE_URL
    })
  } catch (e) {
    // eslint-disable-next-line
    console.error(e)
  }
}

const App: React.FC = () => {
  // Chat
  useEffect(() => {
    initSocket()
  }, [])

  // Foreground
  useEffect(() => {
    onMessageListener().then(data => {
      console.log('Receive foreground: ', data)
    })
  })
  
  return (
    <div className='App'>
      <RouterProvider router={router} fallbackElement={<Fallback />} />
    </div>
  )
}

export default App

export function Fallback() {
  return <p>Performing initial data load</p>
}
