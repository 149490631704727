import React, { useState } from 'react'
import { Button, Form, Input, Alert, Space, notification } from 'antd'
import { userForgotPassword } from '@/stores/forgotPassword'
import { userUpdatePassword } from '@/stores/updatePassword'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { useNavigate } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import i18Next from '@/i18n'
import { REGEX } from '@/utils/regex'

const PasswordForgotView: React.FC = () => {
  const { loading } = useAppSelector(state => state.forgotPassword)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [mail, setMail] = useState('')
  const [err, setErr] = useState('')

  const onSend = (values: any) => {
    dispatch(userForgotPassword(values))
      .unwrap()
      .then(() => {
        setMail(values.mailAddress)
        navigate('/forgot-password')
        setErr('')
      })
      .catch(err => {
        setErr(err)
      })
  }

  const onFinish = (values: any) => {
    const formData = {
      mail_address: mail,
      code: values?.code,
      password: values?.password
    }
    // const data = await dispatch(userUpdatePassword(formData))
    dispatch(userUpdatePassword(formData))
      .unwrap()
      .then(() => {
        notification.success({
          message: '更新に成功しました。'
        })
        navigate('/login')
      })
      .catch(err => {
        setErr(err)
      })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <section className='full-page'>
      <div>
        {!mail && (
          <div className='forgot-password-wrapper'>
            <div className='title'>{i18Next.t('password_forgot.title')}</div>
            <h1 className='required-content'>
              {i18Next.t('password_forgot.requiredContent1')}
              <br></br>
              {i18Next.t('password_forgot.requiredContent2')}
              <br></br>
              {i18Next.t('password_forgot.requiredContent3')}
  
            </h1>
            {err && (
              <Space direction='vertical' style={{ width: '80%' }} className='mb2'>
                <Alert
                  message={err}
                  type='error'
                  action={<Button size='small' type='text' />}
                  closable
                />
              </Space>
            )}
            <Form
              name='basic'
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onSend}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
              layout={'vertical'}
              className={'forgot-password-form'}
            >
              <Form.Item
                name='mailAddress'
                rules={[
                  {
                    required: true,
                    message: `${i18Next.t('password_forgot.requiredEmail')}`
                  },
                  {
                    type: 'email',
                    message: `${i18Next.t('password_forgot.email')}`
                  }
                ]}
                className='forgot-password-input mb10'
              >
                <Input className='input' placeholder='メールアドレス' />
              </Form.Item>

              <Button className='forgot-password-btn' htmlType='submit'>
                {loading ? <LoadingOutlined /> : `${i18Next.t('password_forgot.submit')}`}
              </Button>
            </Form>
          </div>
        )}

        {mail && (
          <div className='password-verification-wrapper'>
            <div className='title'>{i18Next.t('password_verification.title')}</div>
            <h1 className='required-content'>
              {i18Next.t('password_verification.requiredContent1')}
              <br></br>
              {i18Next.t('password_verification.requiredContent2')}
              <br></br>
              {i18Next.t('password_verification.requiredContent3')}
              <br></br>
              {i18Next.t('password_verification.requiredContent4')}
            </h1>
            {err && (
              <Space direction='vertical' style={{ width: '80%' }} className='mb2'>
                <Alert
                  message={err}
                  type='error'
                  action={<Button size='small' type='text' />}
                  closable
                />
              </Space>
            )}
            <Form
              name='basic'
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
              layout={'vertical'}
              className={'password-verification-form'}
            >
              <Form.Item
                name='code'
                rules={[
                  () => ({
                    validator(rule, value) {
                      const regex = new RegExp(REGEX.REGEX_CODE)
                      if (!value) {
                        return Promise.reject(
                          i18Next.t('password_verification.requiredCode', {
                            0: i18Next.t('password_verification.code')
                          })
                        )
                      } else if (!regex.test(value)) {
                        return Promise.reject(i18Next.t('password_verification.validationCode'))
                      }
                      return Promise.resolve()
                    }
                  })
                ]}
                className='password-verification-input mb5'
              >
                <Input className='input' placeholder='確認コード' />
              </Form.Item>

              <Form.Item
                name='password'
                className='password-verification-input mb5'
                rules={[
                  () => ({
                    validator(rule, value) {
                      const regex = new RegExp(REGEX.PASSWORD)
                      const regex_space = new RegExp(REGEX.NOT_WHITESPACE)
                      if (!value) {
                        return Promise.reject(i18Next.t('password_change.enterNewPassword'))
                      } else if (!regex_space.test(value)) {
                        return Promise.reject(i18Next.t('password_change.reEnterpasswordNotSpace'))
                      } else if (!regex.test(value)) {
                        return Promise.reject(i18Next.t('password_change.passwordValidation'))
                      }
                      return Promise.resolve()
                    }
                  })
                ]}
              >
                <Input.Password className='input' placeholder='新しいパスワード' />
              </Form.Item>

              <Form.Item
                name='confirmPassword'
                className='password-verification-input mb10'
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      const regex = new RegExp(REGEX.PASSWORD)
                      const regex_space = new RegExp(REGEX.NOT_WHITESPACE)
                      if (value && getFieldValue('password') !== value) {
                        return Promise.reject(
                          i18Next.t('password_change.newPasswordConfirmNotSame')
                        )
                      }
                      if (!value) {
                        return Promise.reject(i18Next.t('password_change.reEnterPassword'))
                      } else if (!regex_space.test(value)) {
                        return Promise.reject(i18Next.t('password_change.reEnterpasswordNotSpace'))
                      } else if (!regex.test(value)) {
                        return Promise.reject(i18Next.t('password_change.passwordValidation'))
                      }
                      return Promise.resolve()
                    }
                  })
                ]}
              >
                <Input.Password className='input' placeholder='新しいパスワード（確認用）' />
              </Form.Item>

              <Button className='password-verification-btn' htmlType='submit'>
                {`${i18Next.t('password_verification.submit')}`}
              </Button>
            </Form>
          </div>
        )}
      </div>
    </section>
  )
}

export default PasswordForgotView
