import styled from 'styled-components'

export const MyPageWrapper = styled.div`
  background-color: #faf8e5;
  padding-bottom: 80px;
  min-height: 100vh;

  /* &::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;

    @include screen('tablet') {
      content: none;
    }
  } */

  &.background-grey {
    &::after {
      background-color: #f7f7f7;
    }
  }

  &.background-pink {
    &::after {
      background-color: #f9f2f5;
    }
  }

  &.background-green {
    background-color: #edf9f7;
  }

  @include screen('tablet') {
    &.background-grey {
      background-color: #f7f7f7;
    }

    &.background-pink {
      background-color: #f9f2f5;
    }

    &.background-green {
      background-color: #edf9f7;
    }

    border-radius: 39px 0px 0px 0px;
    box-shadow: 3px 3px 13px #00000029;
    box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.25) inset;
    -webkit-box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.25) inset;
    -moz-box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.25) inset;
  }

  .ant-collapse-content-box {
    padding: unset;
  }

  ul {
    margin: 0;
    /* padding: 0; */
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: flex;
    align-items: center;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    height: 60px;
    padding: 0;
    color: #231815;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  li {
    height: 40px;
    padding: 10px 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
    font-size: 20px;
  }

  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
    padding: 0 5px 0 0 !important;
  }

  .ant-progress .ant-progress-success-bg {
    background-color: #53d3d3;
  }

  ol {
    list-style-type: none;
    margin: 0;
    padding-left: 5px;
  }

  .mobile-header {
    .text {
      margin: 0;
    }
  }

  .text,
  .text-gray,
  .text-black,
  .test-done {
    margin-right: 40px;
  }

  .text-gray {
    font-size: 13px;
    color: #888888;
    font-weight: 500;
  }

  .text-black {
    font-size: 13px;
    color: #000000;
    font-weight: 500;
  }

  .text::before {
    content: '';
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    height: 4px;
    border-radius: 4px;
    display: inline-block;
    width: 30px;
    background-color: #53d3d3;
  }

  .text-gray::before {
    content: '';
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    height: 4px;
    border-radius: 4px;
    display: inline-block;
    width: 30px;
    background-color: #888888;
  }

  .text-black::before {
    content: '';
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    height: 4px;
    border-radius: 4px;
    display: inline-block;
    width: 30px;
    background-color: #000000;
  }

  .test-done {
    font-size: 13px;
    color: #b4b4b5;
    font-weight: 500;
  }

  .test-done::before {
    content: '';
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    height: 4px;
    border-radius: 4px;
    display: inline-block;
    width: 30px;
    background-color: #b4b4b5;
  }

  .ant-select {
    width: 200px;
  }

  .ant-select-selection-item {
    font-size: 25px;
  }

  .subjects {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      flex-direction: row-reverse;
    }
  }

  .anticon svg {
    height: 22px;
    width: 22px;

    path {
      fill: #f3e53e;
    }
  }

  @media screen and (max-width: 600px) {
    /* min-height: unset; */
    padding-bottom: unset;

    .process-item {
      /* margin-bottom: 10px !important; */
    }

    .number {
      top: -2px !important;
    }

    .subjects {
      padding: 15px 0 15px 5px !important;
    }

    .mypage-wrapper .statistic-wrapper .data {
      padding: 15px 5px;
    }

    .anticon {
      padding: 0 !important;
    }

    .anticon svg {
      height: 16px;
      width: 16px;
    }

    li {
      height: 30px;
      padding: 2px 0;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
      padding-right: 5px !important;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
      font-size: 10px;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      height: 36px;
      padding: 0px;
      color: rgb(35, 24, 21);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
      margin-bottom: 20px;
    }

    .text,
    .text-gray,
    .text-black {
      font-size: 6px;
      margin-right: 15px;
    }

    .text::before,
    .text-black::before,
    .text-gray::before {
      height: 2px;
      width: 15px;
    }
  }
`

export const StatisticTestWrapper = styled.div`
  .ant-progress-success-bg {
    border-radius: unset;
    height: 10px !important;
  }

  .correct-percent {
    font-size: 11px;
    min-width: 20px;
    text-align: center;
    position: absolute;
    bottom: -12px;
  }

  .row-total {
    margin-top: 10px;
    padding: 10px 30px;
  }

  .col-total {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    align-items: baseline;
    color: #231815;
    padding: 8px 12px;
    font-weight: 500;

    &-left {
      margin-right: 10px;
      border-radius: 20px 0 0 20px;
    }
    &-right {
      border-radius: 0 20px 20px 0;
    }
  }

  .total-number {
    font-size: 20px;
    font-weight: 500;
  }

  @media screen and (max-width: 600px) {
    .ant-progress-success-bg {
      border-radius: unset;
      height: 5px !important;
    }

    .row-total {
      margin-top: 10px;
    }

    .correct-percent {
      bottom: -6px;
      font-size: 8px;
    }

    .col-total {
      font-size: 9px;
    }

    .total-number {
      font-size: 14px;
      font-weight: 500;
    }
  }
`
