import React from 'react'

export const ICON_MESSAGE = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M14.8212 5.01789C13.995 3.88064 12.8037 3.06123 11.4462 2.69646V2.69825C11.1408 2.35896 10.7962 2.04646 10.4105 1.76789C7.48726 -0.357111 3.38369 0.291103 1.24976 3.21432C-0.469881 5.58932 -0.396667 8.78396 1.3569 11.0536L1.37119 13.4215C1.37119 13.4786 1.38012 13.5357 1.39798 13.5893C1.42044 13.6609 1.45679 13.7273 1.50494 13.7848C1.5531 13.8423 1.61212 13.8897 1.67863 13.9244C1.74514 13.9591 1.81783 13.9803 1.89254 13.9868C1.96726 13.9934 2.04253 13.9851 2.11405 13.9625L4.37476 13.25C4.97298 13.4625 5.59083 13.584 6.20512 13.6179L6.19619 13.625C7.78726 14.784 9.87298 15.1322 11.7855 14.5L14.0551 15.2393C14.1123 15.2572 14.1712 15.2679 14.2319 15.2679C14.548 15.2679 14.8033 15.0125 14.8033 14.6965V12.3036C16.3765 10.1679 16.4176 7.2161 14.8212 5.01789ZM4.62476 11.9822L4.41048 11.8929L2.64262 12.4465L2.62476 10.5893L2.4819 10.4286C0.97119 8.58575 0.87119 5.93039 2.28548 3.98217C4.0069 1.62146 7.3069 1.10003 9.66048 2.8036C12.0212 4.51967 12.5444 7.81432 10.839 10.1607C9.40869 12.1232 6.84798 12.8482 4.62476 11.9822ZM13.6426 11.6786L13.4998 11.8572L13.5176 13.7143L11.7676 13.125L11.5533 13.2143C10.5533 13.5857 9.48726 13.6161 8.49976 13.3393L8.49619 13.3375C9.85339 12.9213 11.0389 12.076 11.8748 10.9286C13.239 9.04825 13.4605 6.68575 12.6676 4.67146L12.6783 4.6786C13.089 4.97325 13.4658 5.3411 13.7855 5.78575C15.0819 7.56432 15.0087 9.98575 13.6426 11.6786Z'
        fill='currentColor'
      />
    </svg>
  )
}

export const ICON_ATACHMENT = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' id='paperclip'>
      <path d='M87.2 45.4 49.7 82.9c-4.3 4.3-9.9 6.4-15.6 6.4-5.6 0-11.3-2.1-15.6-6.4-8.6-8.6-8.6-22.5 0-31.1l36.1-36.1c3.2-3.2 7.5-5 12-5s8.8 1.8 12 5c6.6 6.6 6.6 17.4 0 24L42.7 75.8c-4.7 4.7-12.3 4.7-17 0-4.7-4.7-4.7-12.3 0-17L57.5 27c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8L28.5 61.6c-3.1 3.1-3.1 8.2 0 11.3 3.1 3.1 8.2 3.1 11.3 0l36.1-36.1c5.1-5.1 5.1-13.3 0-18.4-2.5-2.5-5.7-3.8-9.2-3.8-3.5 0-6.7 1.4-9.2 3.8l-36 36.2c-7 7-7 18.4 0 25.5 7 7 18.4 7 25.5 0l37.5-37.5c.8-.8 2-.8 2.8 0 .7.7.7 2-.1 2.8z'></path>
    </svg>
  )
}

export const ICON_SEND = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25.593'
      height='20.031'
      viewBox='1155 793.777 25.593 20.031'
    >
      <path
        d='m1155 798.452 5.87 6.145 14.682-7.174-13.11 9.316-.27 6.942 4.056-3.476 3.443 3.604 10.922-20.032-25.593 4.675Z'
        fill='#888'
        data-name='パス 966'
      />
    </svg>
  )
}

export const ICON_SENT_ATACHMENT = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='teenyicons:attachment-outline'>
        <path
          id='Vector'
          d='M0.666016 0V6C0.666016 6.70724 0.946967 7.38552 1.44706 7.88562C1.94716 8.38572 2.62544 8.66667 3.33268 8.66667C4.03993 8.66667 4.7182 8.38572 5.2183 7.88562C5.7184 7.38552 5.99935 6.70724 5.99935 6V2C5.99935 1.64638 5.85887 1.30724 5.60882 1.05719C5.35878 0.807143 5.01964 0.666667 4.66602 0.666667C4.31239 0.666667 3.97326 0.807143 3.72321 1.05719C3.47316 1.30724 3.33268 1.64638 3.33268 2V6.66667M7.99935 0.666667H16.666C17.0196 0.666667 17.3588 0.807143 17.6088 1.05719C17.8589 1.30724 17.9993 1.64638 17.9993 2V18C17.9993 18.3536 17.8589 18.6928 17.6088 18.9428C17.3588 19.1929 17.0196 19.3333 16.666 19.3333H3.33268C2.97906 19.3333 2.63992 19.1929 2.38987 18.9428C2.13982 18.6928 1.99935 18.3536 1.99935 18V10.6667M14.666 6H9.33268M14.666 10H9.33268M14.666 14H5.33268'
          stroke='#26B7BC'
        />
      </g>
      <defs>
        <rect width='20' height='20' fill='white' />
      </defs>
    </svg>
  )
}

export const ICON_FILE_BACKGROUND = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'>
      <path
        d='M1.16602 0.5V6.5C1.16602 7.20724 1.44697 7.88552 1.94706 8.38562C2.44716 8.88572 3.12544 9.16667 3.83268 9.16667C4.53993 9.16667 5.2182 8.88572 5.7183 8.38562C6.2184 7.88552 6.49935 7.20724 6.49935 6.5V2.5C6.49935 2.14638 6.35887 1.80724 6.10882 1.55719C5.85878 1.30714 5.51964 1.16667 5.16602 1.16667C4.81239 1.16667 4.47326 1.30714 4.22321 1.55719C3.97316 1.80724 3.83268 2.14638 3.83268 2.5V7.16667M8.49935 1.16667H17.166C17.5196 1.16667 17.8588 1.30714 18.1088 1.55719C18.3589 1.80724 18.4993 2.14638 18.4993 2.5V18.5C18.4993 18.8536 18.3589 19.1928 18.1088 19.4428C17.8588 19.6929 17.5196 19.8333 17.166 19.8333H3.83268C3.47906 19.8333 3.13992 19.6929 2.88987 19.4428C2.63982 19.1928 2.49935 18.8536 2.49935 18.5V11.1667M15.166 6.5H9.83268M15.166 10.5H9.83268M15.166 14.5H5.83268'
        stroke='white'
        strokeWidth='1.6'
      />
    </svg>
  )
}
